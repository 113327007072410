import {
  Badge,
  Box,
  Button,
  Card, Center,
  Grid,
  Group, Loader,
  NumberInput,
  Select,
  Stack,
  Table,
  Text,
  TextInput,
  UnstyledButton
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useDisclosure, useDocumentTitle, useMediaQuery } from '@mantine/hooks';
import { useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { IoArrowBack, IoCalendarOutline, IoChevronDown, IoClose, IoEyeOutline } from 'react-icons/io5';
import { MdCheck } from 'react-icons/md';
import { PiHandCoinsLight } from 'react-icons/pi';
import {Link, useParams} from 'react-router-dom';
import { ThemeModal } from '../../components/Modal';
import TableTop from '../../components/Table/top';
import HeaderTitle from '../../components/Title';
import {useGetCitizensQuery, useOneCitizensQuery} from "../../services/citizens.service";
import {useGetHouseDetailQuery} from "../../services/houses.service";


export const DistrictDetail = () => {

  useDocumentTitle('Balçova Belediyesi - Semtevi');
  const { id } = useParams<{ id: string }>();

  const {data,isSuccess, isLoading} = useGetHouseDetailQuery({id:String(id)});

  const [opened, { open, close }] = useDisclosure(false);

  const statusType = (status: string) => {
    let _status = { color: '', text: '', icon: <Box/> }

    switch (status) {
      case "waiting":
        _status = {
          color: '#FFA500',
          text: 'Bekliyor',
          icon: <AiOutlineLoading3Quarters color={"#fff"}/>
        }
        break;
      case "rejection":
        _status = {
          color: '#DC3545',
          text: 'RED',
          icon: <IoClose size={15} color={"#fff"}/>
        }
        break;
      case "approved":
        _status = {
          color: '#28A745',
          text: 'ONAYLANDI',
          icon: <MdCheck size={15} color={"#fff"}/>

        }
        break;
    }

    return _status;
  }

  const [dataxa, setData] = useState<any>([
    /*{
      id: 1,
      name: 'Sefa Zor',
      code: '#AA202423001',
      status: 'waiting',
      phone: '+90 538 273 8631',
      created_at: '24.06.2024 - 14:50',
      address: 'Melikgazi mah. Kızılırmak cad. Kervansaray apt. No97',
      tc: '34939434'
    },*/

  ]);

  const [value, setValue] = useState<Date | null>(null);

  const rowsPass = [].map((element:any, index) => {

    let icon = statusType(element.status);

    return (
        <Table.Tr key={`table-item-${index}`}>
          <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
            <UnstyledButton w={40}>
              <IoEyeOutline size={19} color={"#475467"}/>
            </UnstyledButton>
          </Table.Td>
          <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
            <Badge tt={element.status === 'waiting' ? 'initial' : 'uppercase'} leftSection={icon.icon} style={{border:'1px solid #ABEFC6'}} h={23} color={icon.color}>
              {icon.text}
            </Badge>
          </Table.Td>
          <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.code}</Table.Td>
          <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.created_at}</Table.Td>
        </Table.Tr>
    )
  });

  const matches = useMediaQuery('(max-width: 1200px)');

  const rowsDelivery = [].map((element:any, index) => {


    return (
        <Table.Tr key={`table-item-${index}`}>
          <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
            <UnstyledButton w={40}>
              <IoEyeOutline size={19} color={"#475467"}/>
            </UnstyledButton>
          </Table.Td>
          <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
            {element?.name || ''}
          </Table.Td>
          <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element?.code || ''}</Table.Td>
          <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.created_at}</Table.Td>
        </Table.Tr>
    )
  });

  return (<>

    <HeaderTitle
        title={`${data?.name || ''}`}
        description={`Semtevi detayları`}
        ml={-20}
        pr={matches ? 0 : 50}
        onClick={open}
        color={'green'}
        buttonColor={"#28A745"}
        extraComponent={<Button component={Link} to={'/district-home'} color={'#9F9F9F'} mr={10} h={40} leftSection={<IoArrowBack/>} fz={14} fw={600} radius={8}>Geri
          Git</Button>}
        className={"header-title"}
    />

    {isLoading &&
    <Center h={600}>
      <Loader size={"md"}/>
    </Center>
    }

    {isSuccess &&
    <>



      <Box mt={30} pr={matches ? 0 : 50} ml={matches ? -20 : -46}>
        <Card withBorder  p={25} style={{borderLeftWidth:0}}>
          <Text mb={20} fw={700} fz={18}>Bilgileri</Text>
          <Stack>
            <Group>
              <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Semtevi Adı:</Text>
              <Text c={'#000'} fw={500} fz={16} lh={'24px'}>{data?.name || ''} </Text>
            </Group>
            <Group>
              <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Telefon:</Text>
              <Text c={'#000'} fw={500} fz={16} lh={'24px'}>{data?.phoneNumber || ''}</Text>
            </Group>
            <Group>
              <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>İl:</Text>
              <Text c={'#000'} fw={500} fz={16} lh={'24px'}>{data?.address?.city?.name || ''}</Text>
            </Group>
            <Group>
              <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Mahalle:</Text>
              <Text c={'#000'} fw={500} fz={16} lh={'24px'}>{data?.address?.neighborhood?.name || ''}</Text>
            </Group>
            <Group>
              <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Sokak:</Text>
              <Text c={'#000'} fw={500} fz={16} lh={'24px'}>{data?.address?.street?.name || ''}</Text>
            </Group>
            <Group>
              <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Adres:</Text>
              <Text c={'#000'} fw={500} fz={16} lh={'24px'}>{data?.address?.city?.name || ''} {data?.address?.neighborhood?.name || ''} {data?.address?.street?.name || ''}</Text>
            </Group>
          </Stack>
        </Card>
      </Box>


    </>
    }

  </>)
}

export default DistrictDetail;
