import {createApi} from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../lib/axiosBaseQuery";
import {
    SocialCategoryAddProps,
    SocialCategoryProps,
    SocialDocumentAddProps,
    SocialDocumentProps,
    SocialHelpAddProps, SocialHelpProps
} from "./types/social";

export const socialApi = createApi({
    reducerPath: "socialApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["Category", 'Documents', 'Help',"Sub"],
    endpoints: (builder) => ({


        getSocialDocuments: builder.query<Array<SocialDocumentProps>, {}>({
            query: () => ({
                url: `/sosyal-yardimlar/documents`,
                method: "GET",
                includeToken: true,
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}: any) => ({
                            type: "Documents" as const,
                            id
                        })),
                        {type: "Documents", id: "DOCUMENT_LIST"},
                    ]
                    : [{type: "Documents", id: "DOCUMENT_LIST"}],
            transformResponse: (result: Array<SocialDocumentProps> ) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


        addSocialDocument: builder.mutation<any, { data: SocialDocumentAddProps }>({
            query: ({data}) => ({
                url: `/sosyal-yardimlar/documents`,
                method: "POST",
                data: data,
                includeToken: true
            }),
            invalidatesTags: [{type: 'Documents', id: 'DOCUMENT_LIST'}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


        deleteSocialDocument: builder.mutation<any, {
            documentId: string
        }>({
            query: ({documentId}) => ({
                url: `/sosyal-yardimlar/documents/${documentId}`,
                method: "DELETE",
                includeToken: true
            }),
            invalidatesTags: [{type: 'Documents', id: 'DOCUMENT_LIST'}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


        getSocialCategory: builder.query<Array<SocialCategoryProps>, {}>({
            query: () => ({
                url: `/sosyal-yardimlar/categories`,
                method: "GET",
                includeToken: true,
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}: any) => ({
                            type: "Category" as const,
                            id
                        })),
                        {type: "Category", id: "CATEGORY_LIST"},
                    ]
                    : [{type: "Category", id: "CATEGORY_LIST"}],
            transformResponse: (result: Array<SocialCategoryProps>) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


        addSocialCategory: builder.mutation<any, { data: SocialCategoryAddProps }>({
            query: ({data}) => ({
                url: `/sosyal-yardimlar/categories`,
                method: "POST",
                data: data,
                includeToken: true
            }),
            invalidatesTags: [{type: 'Category', id: 'CATEGORY_LIST'}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),

        postSocialCategoryById: builder.mutation<Array<SocialCategoryProps>, {id:string}>({
            query: ({id}) => ({
                url: `/sosyal-yardimlar/categories/${id}/yardimlar`,
                method: "GET",
                includeToken: true,
            }),
            transformResponse: (result: Array<SocialCategoryProps>) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


        getSocialCategoryById: builder.query<Array<SocialCategoryProps>, {id:string}>({
            query: ({id}) => ({
                url: `/sosyal-yardimlar/categories/${id}/yardimlar`,
                method: "GET",
                includeToken: true,
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}: any) => ({
                            type: "Sub" as const,
                            id
                        })),
                        {type: "Sub", id: "SUB_LIST"},
                    ]
                    : [{type: "Sub", id: "SUB_LIST"}],
            transformResponse: (result: Array<SocialCategoryProps>) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),

        deleteSocialById: builder.mutation<any, {
            categoryId: string
        }>({
            query: ({categoryId}) => ({
                url: `/sosyal-yardimlar/yardimlar/${categoryId}`,
                method: "DELETE",
                includeToken: true
            }),
            invalidatesTags: [{type: 'Sub', id: 'SUB_LIST'}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),

        updateSocialCategory: builder.mutation<any, {
            categoryId: string,
            name:string
        }>({
            query: ({categoryId,name}) => ({
                url: `/sosyal-yardimlar/categories/${categoryId}`,
                method: "PUT",
                data:{name},
                includeToken: true
            }),
            invalidatesTags: [{type: 'Category', id: 'CATEGORY_LIST'}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),

        deleteSocialCategory: builder.mutation<any, {
            categoryId: string
        }>({
            query: ({categoryId}) => ({
                url: `/sosyal-yardimlar/categories/${categoryId}`,
                method: "DELETE",
                includeToken: true
            }),
            invalidatesTags: [{type: 'Category', id: 'CATEGORY_LIST'}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


        getSocialHelp: builder.query<Array<SocialHelpProps>, {}>({
            query: () => ({
                url: `/sosyal-yardimlar/yardimlar`,
                method: "GET",
                includeToken: true,
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.map(({id}: any) => ({
                            type: "Help" as const,
                            id
                        })),
                        {type: "Help", id: "HELP_LIST"},
                    ]
                    : [{type: "Help", id: "HELP_LIST"}],
            transformResponse: (result: { success: boolean, payload: Array<SocialHelpProps> }) => result.payload,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


        addSocialHelp: builder.mutation<any, { data: SocialHelpAddProps }>({
            query: ({data}) => ({
                url: `/sosyal-yardimlar/yardimlar`,
                method: "POST",
                data: data,
                includeToken: true
            }),
            invalidatesTags: [{type: 'Help', id: 'HELP_LIST'},{type: 'Sub', id: 'SUB_LIST'}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


        updateSocialSub: builder.mutation<any, { name?:string,id:any,documents?:string[]  }>({
            query: ({name,documents,id}) => ({
                url: `/sosyal-yardimlar/yardimlar/${id}`,
                method: "PUT",
                data:{name,documents},
                includeToken: true
            }),
            invalidatesTags: [{type: 'Help', id: 'HELP_LIST'},{type: 'Sub', id: 'SUB_LIST'}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


        deleteSocialHelp: builder.mutation<any, {
            id: string
        }>({
            query: ({id}) => ({
                url: `/sosyal-yardimlar/yardimlar/${id}`,
                method: "DELETE",
                includeToken: true
            }),
            invalidatesTags: [{type: 'Help', id: 'HELP_LIST'}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),
    })
});

export const {
    useAddSocialCategoryMutation,
    useAddSocialDocumentMutation,
    useAddSocialHelpMutation,
    useGetSocialHelpQuery,
    useGetSocialCategoryQuery,
    useGetSocialDocumentsQuery,
    useDeleteSocialCategoryMutation,
    useDeleteSocialDocumentMutation,
    useDeleteSocialHelpMutation,
    useGetSocialCategoryByIdQuery,
    useDeleteSocialByIdMutation,
    useUpdateSocialSubMutation,
    useUpdateSocialCategoryMutation,
    usePostSocialCategoryByIdMutation
} = socialApi;
