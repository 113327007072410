import {
    Box,
    Button,
    Checkbox,
    Divider,
    Grid,
    Group,
    LoadingOverlay,
    NumberInput,
    Radio,
    RenderTreeNodePayload,
    Table,
    Text,
    TextInput,
    UnstyledButton,
    useTree
} from '@mantine/core';
import {useDisclosure, useDocumentTitle} from '@mantine/hooks';
import React, {useEffect, useState} from 'react';
import {IoDocumentTextOutline, IoPencilOutline, IoTrashOutline} from 'react-icons/io5';
import {SlSupport} from 'react-icons/sl';
import {useLocation, useParams} from 'react-router-dom';
import {ThemeModal} from '../../components/Modal';
import TableTop from '../../components/Table/top';
import HeaderTitle from '../../components/Title';
import {
    useAddSocialHelpMutation,
    useDeleteSocialByIdMutation,
    useGetSocialCategoryByIdQuery,
    useGetSocialDocumentsQuery,
    useUpdateSocialSubMutation
} from "../../services/social.service";
import {notifications} from "@mantine/notifications";
import {modals} from "@mantine/modals";
import {useForm} from "@mantine/form";


export const HelpSub = () => {

    useDocumentTitle('Balçova Belediyesi - Yardım Tipi Kategorileri');
    const {id} = useParams<{ id: any }>();

    const {data, isSuccess, isError, isLoading, isFetching} = useGetSocialCategoryByIdQuery({id});
    const [deleteCategory, deleteCategoryProps] = useDeleteSocialByIdMutation({});
    const [categoryTitle, setCategoryTitle] = useState<string>('');
    const [addHelp, addHelpProps] = useAddSocialHelpMutation({});
    const [updateHelp, updateHelpProps] = useUpdateSocialSubMutation({});
    const [isEditId, setIsEditId] = useState<string | null>(null);
    const [openDocument, setOpenDocument] = useState(false);
    const documents = useGetSocialDocumentsQuery({});

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const title = queryParams.get('title');
    const [editType,setEditType] = useState<boolean>(false);

    const [opened, {open, close}] = useDisclosure(false);

    const [checkedItems, setCheckedItems] = useState<string[]>([]);
    const [selectedItem,setSelectedItem] = useState<any>({});
    const handleCheckboxChange = (value: string) => {
        setCheckedItems((prevCheckedItems) =>
            prevCheckedItems.includes(value)
                ? prevCheckedItems.filter((item) => item !== value)
                : [...prevCheckedItems, value]
        );
    };


    const form = useForm({
        initialValues: {
            categoryId: '',
            name: '',
            period: "7",
            documentsIds: []
        },

        validate: {
            //   categoryId: (value) => (value !== '' ? null : 'Kategori seçmelisiniz'),
            name: (value) => (value !== '' ? null : 'Başlık girmelisiniz'),
        },
    });




    useEffect(() => {
        if (id) {
            form.setFieldValue('categoryId', id);
        }
    }, [id]);

    useEffect(() => {
        if (deleteCategoryProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: deleteCategoryProps?.error?.data?.message ?? '',
                autoClose: 2000,
            })
        }
    }, [deleteCategoryProps.isError]);

    useEffect(() => {
        if (deleteCategoryProps.isSuccess) {
            setEditType(false);
            setCategoryTitle('');
            notifications.show({
                color: 'teal',
                title: 'Başarılı',
                message: 'Kategori Silindi',
                autoClose: 2000,
            });
        }
    }, [deleteCategoryProps.isSuccess]);

    useEffect(() => {
        if (addHelpProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: addHelpProps?.error?.data?.message ?? '',
                autoClose: 2000,
            })
        }
    }, [addHelpProps.isError]);

    useEffect(() => {
        if (addHelpProps.isSuccess) {
            close();
            form.setValues({
                categoryId: '',
                name: '',
                period: "7",
                documentsIds: []
            });
            notifications.show({
                color: 'teal',
                title: 'Başarılı',
                message: 'Kategori Konusu Eklendi',
                autoClose: 2000,
            });
        }
    }, [addHelpProps.isSuccess]);

    useEffect(() => {
        if (updateHelpProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: updateHelpProps?.error?.data?.message ?? '',
                autoClose: 2000,
            })
        }
    }, [updateHelpProps.isError]);

    useEffect(() => {
        if (updateHelpProps.isSuccess) {
            close();
            setOpenDocument(false);
            setSelectedItem({});
            setCheckedItems([]);

            form.setValues({
                categoryId: '',
                name: '',
                period: "7",
                documentsIds: []
            });
            setIsEditId(null);
            notifications.show({
                color: 'teal',
                title: 'Başarılı',
                message: 'Düzenleme Kaydedildi',
                autoClose: 2000,
            });
        }
    }, [updateHelpProps.isSuccess]);


    function Leaf({node, expanded, hasChildren, elementProps}: RenderTreeNodePayload) {
        return (
            <Group gap={5} {...elementProps}>
                <Checkbox
                    value={node.value}
                    checked={checkedItems.includes(node.value)}
                    onChange={() => handleCheckboxChange(node.value)}
                    label={node.label}
                />
            </Group>
        );
    }

    const rows = isSuccess ? data.map((element, index) => (
        <Table.Tr key={`table-item-${index}`}>
            <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>{element?.name || ''}</Table.Td>
            <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>
                <UnstyledButton fz={14} fw={400} c={'#475467'} onClick={() => {
                    setSelectedItem(element);
                    let checkIds:any = [];
                    //@ts-ignore
                    element?.documents?.map((item:any) => {
                        checkIds.push(item.id);
                    });
                    setCheckedItems(checkIds);
                    setOpenDocument(true);
                }}>Düzenle</UnstyledButton>
            </Table.Td>
            <Table.Td py={24} px={24}>
                <Group gap={4} justify={'end'}>
                    <UnstyledButton w={40} onClick={() => {
                        modals.openConfirmModal({
                            centered: true,
                            title: 'Kategoriyi Sil',
                            children: (
                                <Text size="sm">
                                    Kategoriyi silmek istediğinize emin misiniz?
                                </Text>
                            ),
                            labels: {confirm: 'Kategoriyi Sil', cancel: "Geri Dön"},
                            confirmProps: {color: 'red'},
                            onCancel: () => console.log('Cancel'),
                            onConfirm: () => deleteCategory({categoryId: String(element.id)}),
                        });
                    }}>
                        <IoTrashOutline size={19} color={"#475467"}/>
                    </UnstyledButton>
                    <UnstyledButton w={40} onClick={() => {
                        setIsEditId(element.id);
                        form.setValues({
                            categoryId: element.id,
                            name: element.name,
                            //@ts-ignore
                            period: String(element?.period || 7),
                            documentsIds: []
                        });
                        open();
                    }}>
                        <IoPencilOutline size={19} color={"#475467"}/>
                    </UnstyledButton>
                </Group>
            </Table.Td>
        </Table.Tr>
    )) : [];

    return (<>
        <HeaderTitle
            title={`"${title}" Alt Kategorileri`}
            description={"Alt kategori listesi"}
            buttonText={"Yeni Alt Kategori Ekle"}
            ml={-20}
            onClick={() => open()}
            className={"header-title"}
        />

        <ThemeModal
            opened={openDocument}
            onClose={() => setOpenDocument(false)}
            icon={<IoDocumentTextOutline size={25} color={"#7F56D9"}/>}
            title={"Evrak Türü - Yardım Tipi Eşleştirme"}
            description={"Yardım tipine göre evrak türü eşleştirin."}>
            <Checkbox.Group
                defaultValue={checkedItems}
                label="Evraklar"
                description="Seçtiğiniz evrakları eşleştirebilirsiniz"
                onChange={setCheckedItems}
            >
                <Group mt="md">
                    {documents.isSuccess &&
                        documents.data.map((item, index) => (
                            <Checkbox value={item.id} label={item?.name || ''}/>
                        ))
                    }
                </Group>
            </Checkbox.Group>
            <Group justify={'end'}>
                <Button onClick={() => {
                    updateHelp({
                        id:String(selectedItem?.id || ''),
                        name:selectedItem?.name || '',
                        documents:checkedItems
                    })
                }} mt={15} loading={updateHelpProps.isLoading} disabled={checkedItems.length === 0}>Kaydet</Button>
            </Group>
        </ThemeModal>

        <ThemeModal
            opened={opened}
            onClose={() => {
                close();
                setIsEditId(null);
                form.setValues({
                    categoryId: id,
                    documentsIds: [],
                    name: '',
                    period: '7',
                })
            }}
            icon={<SlSupport size={22} color={"#7F56D9"}/>}
            title={isEditId ? 'Yardım Tipi Konusu Düzenle' : "Yeni Yardım Tipi Konusu Ekle"}
            description={isEditId ? 'Yardım tipi konusunu düzenleyin' : 'Başvuruya açmak istediğiniz yardım tipinin alt konularını belirleyin.'}>
            <form onSubmit={form.onSubmit((values) => {

                if (isEditId === null) {
                    let _values = values;
                    //@ts-ignore
                    _values.period = Number(values.period);
                    addHelp({data: values});
                } else {
                    updateHelp({name: values.name, id: isEditId});
                }

            })}>

                {isEditId === null &&
                  <>
                    <Group className={"group-mb-disabled"} mt={22} mb={16} align={'start'}>
                      <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Yardım Tipi*</Text>
                      <Box flex={1}>
                        <TextInput
                          radius={8}
                          disabled
                          value={String(title)}
                          styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}

                        />
                        <Text fz={13} mt={8} fw={400} c={'#475467'} lh={'20px'}>Eşleştirmek istediğiniz yardım tipini
                          seçin.</Text>
                      </Box>
                    </Group>
                    <Divider mb={16} color={'#EAECF0'}/>
                  </>
                }

                <Group className={"group-mb-disabled"} mb={16} align={'start'}>
                    <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Yardım Tipi Konu Adı*</Text>
                    <Box flex={1}>
                        <TextInput radius={8} styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                                   key={form.key('name')}
                                   {...form.getInputProps('name')}

                        />

                    </Box>
                </Group>
                <Divider mb={20} color={'#EAECF0'}/>
                {isEditId === null &&
                  <Group className={"group-mb-disabled"} align={'start'}>
                    <Text mb={4} mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Başvuru Periyodu:</Text>
                    <Box flex={1}>
                      <Radio.Group
                        name="period"
                        key={form.key('period')}
                        {...form.getInputProps('period')}
                      >

                        <Grid columns={8} pr={40}>
                          <Grid.Col span={{lg: 2, base: 4}}>
                            <Radio size={"sm"} value={"7"} label={"7 Gün"} fz={14} fw={500} c={'#101828'}
                                   radius={6}

                            />
                          </Grid.Col>
                          <Grid.Col span={{lg: 2, base: 4}}>
                            <Radio size={"sm"} value={"30"} label={"30 gün"} fz={14} fw={500} c={'#101828'}
                                   radius={6}

                            />
                          </Grid.Col>
                          <Grid.Col span={{lg: 2, base: 4}}>
                            <Radio size={"sm"} value={"60"} label={"60 Gün"} fz={14} fw={500} c={'#101828'}
                                   radius={6}

                            />
                          </Grid.Col>
                          <Grid.Col span={{lg: 2, base: 4}}>
                            <Radio size={"sm"} value={"180"} label={"180 Gün"} fz={14} fw={500} c={'#101828'}
                                   radius={6}
                            />
                          </Grid.Col>
                          <Grid.Col span={{lg: 12, base: 12}}>
                            <Group>
                              <Radio size={"sm"} value={"0"} label={"Özel"} fz={14} fw={500} c={'#101828'}
                                     radius={6}/>
                                {(form.values.period !== '7' && form.values.period !== '30'
                                        && form.values.period !== '60' && form.values.period !== '180') &&
                                  <NumberInput
                                    w={150}
                                    hideControls
                                    key={form.key('period')}
                                    rightSection={<Text mr={20} fz={12}>Gün</Text>}
                                    {...form.getInputProps('period')}
                                  />
                                }
                            </Group>
                          </Grid.Col>

                        </Grid>
                      </Radio.Group>
                    </Box>
                  </Group>
                }
                <Divider mx={-23} mt={22} color={'#EAECF0'}/>
                <Group grow gap={12} p={24} pb={0}>
                    <Button onClick={() => {
                        close();
                        setIsEditId(null);
                        form.setValues({
                            categoryId: id,
                            documentsIds: [],
                            name: '',
                            period: '7',
                        })
                    }} style={{borderColor: '#D0D5DD'}} variant={"outline"}
                            h={44}
                            fz={16} c={'#344054'} fw={600} radius={8}>İptal Et</Button>
                    <Button loading={addHelpProps.isLoading || updateHelpProps.isLoading} h={44} fz={16} c={'#fff'}
                            fw={600} radius={8}
                            type={'submit'}>{isEditId ? 'Düzenle' : 'Ekle'}</Button>
                </Group>

            </form>
        </ThemeModal>

        <Table pos={'relative'} className={"responsive-table"} mt={20} ml={-46} w={'calc(100% + 62px)'} striped>
            <LoadingOverlay visible={isFetching}/>
            <TableTop
                titles={["Kategori", "Evrak Türü Eşlelştirme"]}
            />
            <Table.Tbody>{rows}</Table.Tbody>

        </Table>


    </>)
}

export default HelpSub;
