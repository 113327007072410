import type { BaseQueryFn } from "@reduxjs/toolkit/query";
import { TOKEN } from '../token';

import * as Service from "../services/system";
import { Mutex } from "async-mutex";
import axios, { AxiosError, AxiosRequestConfig } from "axios";

const AuthService = Service.Auth.init();
const jwt = AuthService.jwt();


interface QueryParams {
    url: string;
    method: AxiosRequestConfig["method"];
    data?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
    includeToken?: boolean;
    headers?: object;
}

const mutex = new Mutex();

export const axiosFetch = async ({
                                     url,
                                     method,
                                     data,
                                     params,
                                     includeToken,
                                     headers = {}
                                 }: QueryParams) => {
    let res: { data?: any, error?: { status: any; data: any; } } = {};

    try {

        let token = null;

      if (includeToken) {
            token = jwt.token().get();
        }



        const result = await axios({
            url: process.env.REACT_APP_API_URL+url, //process.env.REACT_APP_API_URL + url,
            method,
            data,
            params,
            withCredentials: false,
           ...(includeToken ? {headers: {...headers, Authorization: `${token}`}} : {...headers})

        });

        const res_data = result.data;

        if (res_data?.error) {
            res = {
                error: {
                    status: false,
                    data: res_data?.error
                }
            };
        } else {
            res = {data: res_data};
        }
    } catch (axiosError) {
        let err = axiosError as AxiosError;

        console.log('error:', {
            status: err.response?.status,
            data: err.response?.data || err.message
        });

        res = {
            error: {
                status: err.response?.status,
                data: err.response?.data || err.message
            }
        };
    }


    return res;
};


export const refreshTokenRequest = (): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {

        try {

            const refreshResult = await axiosFetch({
                url: "/auth/refresh", method: "POST", data: {
                    refresh_token: jwt.refresh_token().get()
                }
            });

            if (refreshResult?.data) {

                if (refreshResult.data?.payload?.access?.token && refreshResult.data?.payload?.refresh?.token) {

                    console.log('Refresh Token Successful!');


                    jwt.token().set(refreshResult.data.payload.access.token);
                    jwt.refresh_token().set(refreshResult.data.payload.refresh.token);

                    resolve(true);

                } else {
                    resolve(false);
                }


            } else {

                resolve(false);
            }
        } catch (e) {
            reject(e);

        }

    });
}

// base query
const axiosBaseQuery =
    (
        // { baseUrl }: { baseUrl: string } = { baseUrl: API_URL }
    ): BaseQueryFn<
        QueryParams,
        unknown,
        unknown
    > =>
        async ({url, method, data, params, includeToken = false}) => {

          // console.log('logged jwt token', jwt.token().get());
          //   console.log('logged refresh_token token', jwt.refresh_token().get());


            // Mutex sayesinde, eğer token süresi bittiyse, diğer bağlantıları bekletmiş oluyoruz, bug'a sebep olmuyor.
            await mutex.waitForUnlock();

            let result = await axiosFetch({url, method, data, params, includeToken});


            if ((result?.error?.data?.error?.code === "TOKEN_EXPIRED") || (result?.error?.data?.error?.code === "INVALID_TOKEN")) {

                if (!mutex.isLocked()) {
                    const release = await mutex.acquire();

                    try {

                        //   Alert.alert('Burasi calisti',String(jwt.refresh_token().get()));

                        const refreshResult = await axiosFetch({
                            url: "/auth/refresh", method: "POST", data: {
                                refresh_token: jwt.refresh_token().get()
                            }
                        });

                        if (refreshResult?.data) {

                            if (refreshResult.data?.payload?.access?.token && refreshResult.data?.payload?.refresh?.token) {

                                console.log('Refresh Token Successful!');

                                jwt.token().set(refreshResult.data.payload.access.token);
                                jwt.refresh_token().set(refreshResult.data.payload.refresh.token);

                                result = await axiosFetch({url, method, data, params, includeToken});
                            } else {


                                jwt.token().remove();
                                jwt.refresh_token().remove();

                            }


                        } else {

                            // goLogin ve api.dispatch(logout());
                            jwt.token().remove();
                            jwt.refresh_token().remove();

                            // go login page

                        }

                    } finally {
                        release();
                    }

                } else {
                    await mutex.waitForUnlock();
                    result = await axiosFetch({url, method, data, params, includeToken});
                }

            } else {
                if (result?.error) {
                    //     const release = await mutex.acquire();
                    console.log('Service not connected', url, method, data, params, ' --> |Error:|| -->', result?.error);
                    /*
                    Alert.alert('Servise Bağlanılamadı',`Hata Kodu: ${result?.error?.status}`,[
                        {
                            text: 'Tekrar Dene',
                            onPress: () => {
                               // release();

                            }
                        }
                    ]);
                     */
                }

            }


            return result;
        };

export default axiosBaseQuery;
