import {
    Badge,
    Box, Button,
    Center, Divider,
    Group, LoadingOverlay, MultiSelect,
    NumberInput,
    Pagination,
    Select,
    Table,
    Text,
    TextInput,
    UnstyledButton
} from '@mantine/core';
import {useDisclosure, useDocumentTitle} from '@mantine/hooks';
import React, {useEffect, useState} from 'react';
import {IoEyeOutline, IoPencilOutline, IoTrashOutline} from 'react-icons/io5';
import {LiaStreetViewSolid} from 'react-icons/lia';
import {ThemeModal} from '../../components/Modal';
import TableTop from '../../components/Table/top';
import HeaderTitle from '../../components/Title';
import {useAddHousesMutation, useDeleteHousesMutation, useGetHousesQuery} from "../../services/houses.service";
import {citiesMap, neighbourhoodMap, streetMap} from "../../lib/helper";
import {useForm} from "@mantine/form";
import {useAddStreetMutation, useDeleteStreetMutation} from "../../services/street.service";
import {
    useGetAddressCitiesQuery,
    useGetAddressCityNeighborhoodsQuery,
    useGetCityNeighborhoodIdQuery
} from "../../services/address.service";
import {notifications} from "@mantine/notifications";
import {modals} from "@mantine/modals";
import {Link} from "react-router-dom";


export const District = () => {

    useDocumentTitle('Balçova Belediyesi - Semtevleri');
    const [opened, {open, close}] = useDisclosure(false);
    const [activePage, setActivePage] = useState(1);
    const {data,isSuccess,isLoading,isError,isFetching} = useGetHousesQuery({page:activePage});
    const [addHouses, addHousesProps] = useAddHousesMutation({});
    const [deleteHouses, deleteHousesProps] = useDeleteHousesMutation({});


    const form = useForm({
        initialValues: {
            name: '',
            phoneNumber: '',
            cityId: '',
            address: {
                neighborhoodsId: '',
                streetId: '',
                daskCode: '',
                buildingNo: '',
                doorNo: '',
            },
            neighborhoodsId: '',
            streetId:'',
        },

        validate: {
            name: (value) => (value !== '' ? null : 'Başlık girmelisiniz'),
            cityId: (value) => (value !== '' ? null : 'Şehir seçmelisiniz'),
            phoneNumber: (value) => (value !== '' ? null : 'Telefon No girmelisiniz'),
            neighborhoodsId: (value) => (value !== '' ? null : 'Mahalle seçmelisiniz'),
            streetId: (value) => (value !== ''  ? null : 'Sokak seçmelisiniz'),
        },
    });

    const cities = useGetAddressCitiesQuery({});
    const neighbourhoods = useGetAddressCityNeighborhoodsQuery({cityId: form.values.cityId});
    const streets = useGetCityNeighborhoodIdQuery({
        neighborhoodsId: form.values.neighborhoodsId,
        cityId: form.values.cityId
    });



    useEffect(() => {
        if (addHousesProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: addHousesProps?.error?.data?.message ?? 'Bilnmeyen hata',
                autoClose: 2000,
            });
        }
    }, [addHousesProps.isError]);

    useEffect(() => {
        if (addHousesProps.isSuccess) {
            close();
            notifications.show({
                color: 'teal',
                title: 'Başarılı',
                message: 'Semmt Evi Eklendi',
                autoClose: 2000,
            });
            setTimeout(()=>{
                form.setValues({
                    name: '',
                    phoneNumber: '',
                    cityId: '',
                    address: {
                        neighborhoodsId: '',
                        streetId: '',
                        daskCode: '',
                        buildingNo: '',
                        doorNo: '',
                    },
                    neighborhoodsId: '',
                    streetId:'',
                });
            },250);
        }
    }, [addHousesProps.isSuccess]);

    useEffect(() => {
        if (deleteHousesProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: deleteHousesProps?.error?.data?.message ?? '',
                autoClose: 2000,
            })
        }
    }, [deleteHousesProps.isError]);

    useEffect(() => {
        if (deleteHousesProps.isSuccess) {
            notifications.show({
                color: 'teal',
                title: 'Başarılı',
                message: 'Semt Evi Silindi',
                autoClose: 2000,
            })
        }
    }, [deleteHousesProps.isSuccess]);


    const rows = isSuccess ? data.items.map((element, index) => (
        <Table.Tr key={`table-item-${index}`}>
            <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>{element.name}</Table.Td>
            <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.address.city?.name || ''} {element.address.neighborhood?.name || ''} {element.address.street?.name || ''}</Table.Td>
            <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.phoneNumber}</Table.Td>
            <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>
                <Group gap={10}>
                    {/*/@ts-ignore/*/}
                    {element?.workers?.length > 0 &&
                      <Badge variant={"light"} fz={12} fw={500} key={`tag-${element.id}-${index}`}
                             radius={16}>{element?.workers?.length} Çalışan</Badge>
                    }
                    <UnstyledButton fz={14} fw={400} c={'#475467'} ml={2}>Düzenle</UnstyledButton>
                </Group>
            </Table.Td>
            <Table.Td py={24} px={24}>
                <Group gap={4} justify={'end'}>
                    <UnstyledButton w={40} component={Link} to={`/district-home/${element.id}`}>
                        <IoEyeOutline size={19} color={"#475467"}/>
                    </UnstyledButton>
                    <UnstyledButton w={40} onClick={() =>{
                        modals.openConfirmModal({
                            centered: true,
                            title: 'Semt Evini Sil',
                            children: (
                                <Text size="sm">
                                    Semt Evini silmek istediğinize emin misiniz?
                                </Text>
                            ),
                            labels: {confirm: 'Semt Evini Sil', cancel: "Geri Dön"},
                            confirmProps: {color: 'red'},
                            onCancel: () => console.log('Cancel'),
                            onConfirm: () => deleteHouses({id: String(element.id)}),
                        });
                    }}>
                        <IoTrashOutline size={19} color={"#475467"}/>
                    </UnstyledButton>
                    <UnstyledButton w={40} >
                        <IoPencilOutline size={19} color={"#475467"}/>
                    </UnstyledButton>
                </Group>
            </Table.Td>
        </Table.Tr>
    )) :[];

    return (<>
        <HeaderTitle
            title={"Semtevleri"}
            count={`${data?.totalItems || 0} Semtevi`}
            description={"Semtevi ekleyin ya da düzenleyin."}
            buttonText={"Yeni Semtevi Ekle"}
            ml={-20}
            onClick={open}
            className={"header-title"}
        />


        <ThemeModal
            opened={opened}
            onClose={close}
            icon={<LiaStreetViewSolid size={25} color={"#7F56D9"}/>}
            title={"Yeni Semt Evi Ekle"}
            description={"Yeni bir semt evi eklemek için tüm alanları doldurun."}>
            <form onSubmit={form.onSubmit((values) => {
                addHouses({
                    name:values.name,
                    phoneNumber:values.phoneNumber,
                    address:{
                        neighborhoodsId: form.values.neighborhoodsId,
                        streetId: form.values.streetId,
                        daskCode: "1",
                        buildingNo: "1",
                        doorNo: "1"
                    }
                })
            })}>

                <Group className={"group-mb-disabled"} mb={16} align={'start'}>
                    <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Başlık:*</Text>
                    <Box flex={1}>
                        <TextInput
                            radius={8}
                            styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                            key={form.key('name')}
                            {...form.getInputProps('name')}
                        />
                    </Box>
                </Group>
                <Group className={"group-mb-disabled"} mb={16} align={'start'}>
                    <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Telefon:</Text>
                    <Box flex={1}>
                        <TextInput  radius={8}
                                    styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                                    key={form.key('phoneNumber')}
                                    {...form.getInputProps('phoneNumber')}
                        />
                    </Box>
                </Group>
                <Group className={"group-mb-disabled"} mb={16} align={'start'}>
                    <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Memleket(İl)*</Text>
                    <Box flex={1}>
                        <Select
                            data={cities.isSuccess ? citiesMap(cities?.data || []) : []}
                            allowDeselect={false}
                            defaultValue={''}
                            radius={8}
                            searchable
                            styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                            key={form.key('cityId')}
                            {...form.getInputProps('cityId')}
                        />
                    </Box>
                </Group>
                <Group className={"group-mb-disabled"} mb={16} align={'start'}>
                    <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Adres:</Text>
                    <Box flex={1}>
                        <TextInput radius={8} styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                                   key={form.key('address.id')}
                                   {...form.getInputProps('address.id')}
                        />
                    </Box>
                </Group>
                <Group className={"group-mb-disabled"} mb={30} align={'start'}>
                    <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Mahalle*:</Text>
                    <Box flex={1}>
                        <Select data={cities.isSuccess ? neighbourhoodMap(neighbourhoods?.data || []) : []}
                                allowDeselect={false} defaultValue={''} radius={8}
                                styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                                key={form.key('neighborhoodsId')}
                                {...form.getInputProps('neighborhoodsId')}
                                searchable
                        />
                        <Text fz={13} mt={8} fw={400} c={'#475467'} lh={'20px'}>Temsilciyi mahalle ile
                            ilişkilendirin.</Text>
                    </Box>
                </Group>
                <Group className={"group-mb-disabled"} align={'start'}>
                    <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Sokak*:</Text>
                    <Box flex={1}>
                        <Select
                            data={streets.isSuccess ? streetMap(streets?.data || []) : []}
                            defaultValue={''} radius={8}
                            styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                            key={form.key('streetId')}
                            {...form.getInputProps('streetId')}
                        />
                        <Text mt={8} fz={13} fw={400} c={'#475467'} lh={'20px'}>Temsilci için birden fazla sokak seçimi
                            yapın.</Text>
                    </Box>
                </Group>
                <Box mx={-20}>
                    <Divider mt={20} color={'#EAECF0'}/>
                    <Group grow gap={12} p={24} pb={0}>
                        <Button onClick={() => close()} style={{borderColor: '#D0D5DD'}} variant={"outline"} h={44}
                                fz={16} c={'#344054'} fw={600} radius={8}>İptal Et</Button>
                        <Button loading={addHousesProps.isLoading} type={'submit'} h={44} fz={16} c={'#fff'} fw={600} radius={8}>Ekle</Button>
                    </Group>
                </Box>
            </form>
        </ThemeModal>


        <Table className={"responsive-table"} mt={20} ml={-46} w={'calc(100% + 62px)'} striped>
            <LoadingOverlay visible={isFetching}/>
            <TableTop
                titles={["Semtevi Adı", "Adres", "Telefon", "Çalışanlar", ""]}
            />
            <Table.Tbody>{rows}</Table.Tbody>

        </Table>



        <Center h={68} bg={'#fff'} pos={'fixed'} left={0} bottom={0} w={'100%'}
                style={{borderTop: '1px solid #EAECF0'}}>
            <Pagination
                color={"#f9fafb"}
                total={Number(data?.totalPages || 0)}
                withControls={false}
                onChange={setActivePage}
                styles={{
                    control: {
                        color: '#475467',
                        fontSize: 14,
                        border: 'none'
                    },
                    dots: {
                        fontSize: 14,
                        color: '#475467',

                    }
                }}
            />
        </Center>

    </>)
}

export default District;
