import { Box, Divider, Group, Stack, Table,Text, ThemeIcon, UnstyledButton } from '@mantine/core';
import { useDisclosure, useDocumentTitle, useMediaQuery } from '@mantine/hooks';
import { useState } from 'react';
import { IoEyeOutline, IoPencilOutline, IoTrashOutline } from 'react-icons/io5';
import { PiCheckCircleFill } from 'react-icons/pi';
import { TiCancel } from 'react-icons/ti';
import { VscGitStashPop } from 'react-icons/vsc';
import HeaderTitle from '../../components/Title';


export const Logs = () => {

  useDocumentTitle('Balçova Belediyesi - Log kaydı');
  const [opened, { open, close }] = useDisclosure(false);
  const matches = useMediaQuery('(max-width: 1200px)');

  const [data, setData] = useState([
    {
      id: 1,
      name: 'Sefa Z.',
      phone: '+90 538 273 8631',
      neighbourhood: 'Fikirtepe Mh.',
      street: 'Yıldırım Sk., Hızırbey Sk., Selimiye Sk.',
      tc: '34939434'
    },
    {
      id: 2,
      name: 'Alperen C..',
      phone: '+90 538 273 8631',
      neighbourhood: 'Fikirtepe Mh.',
      street: 'Yıldırım Sk., Hızırbey Sk., Selimiye Sk.',
      tc: '34939434'
    },
    {
      id: 3,
      name: 'Erdal B.',
      phone: '+90 538 273 8631',
      neighbourhood: 'Fikirtepe Mh.',
      street: 'Yıldırım Sk., Hızırbey Sk., Selimiye Sk.',
      tc: '34939434'
    },
    {
      id: 4,
      name: 'Ersin Y.',
      phone: '+90 538 273 8631',
      neighbourhood: 'Fikirtepe Mh.',
      street: 'Yıldırım Sk., Hızırbey Sk., Selimiye Sk.',
      tc: '34939434'
    },
    {
      id: 5,
      name: 'Yiğit C.',
      phone: '+90 538 273 8631',
      neighbourhood: 'Fikirtepe Mh.',
      street: 'Yıldırım Sk., Hızırbey Sk., Selimiye Sk.',
      tc: '34939434'
    },
  ]);

  const rows = data.map((element, index) => (
    <Table.Tr key={`table-item-${index}`}>
      <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>{element.name}</Table.Td>
      <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.phone}</Table.Td>
      <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.neighbourhood}</Table.Td>
      <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.street}</Table.Td>
      <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.tc}</Table.Td>
      <Table.Td py={24} px={24}>
        <Group gap={4} justify={'end'}>
          <UnstyledButton w={40}>
            <IoEyeOutline size={19} color={"#475467"}/>
          </UnstyledButton>
          <UnstyledButton w={40}>
            <IoTrashOutline size={19} color={"#475467"}/>
          </UnstyledButton>
          <UnstyledButton w={40}>
            <IoPencilOutline size={19} color={"#475467"}/>
          </UnstyledButton>
        </Group>
      </Table.Td>
    </Table.Tr>
  ));

  return (<>
    <HeaderTitle
      title={"Log kaydı"}
      description={"Sistem üzerinde yapılan tüm işlemler"}
      buttonText={"Excel olarak dışarı aktar"}
      ml={-20}
      className={"header-title"}
      onClick={() => console.log('')}
    />

    <Divider ml={-45} mr={-15} mt={20} color={'#EAECF0'}/>
    <Box ml={matches ? 0 : -20} mt={18}>
       <Stack gap={1}>
         <Group>
           <ThemeIcon color={"#20C933"} radius={36} size={40}>
             <VscGitStashPop size={19.5} style={{marginLeft:1,marginTop:-1}}/>
           </ThemeIcon>
           <Box flex={1}>
             <Group gap={6}>
               <Text fz={13} fw={500} c={'#344054'}>Sefa Zor</Text>
               <Text fz={10} fw={400} c={'#344054'}>24.04.2024 - 14:24</Text>
             </Group>
             <Group gap={6}>
               <Text fz={13} fw={500} c={'#344054'}>Yeni başvuru: </Text>
               <Text fz={13} fw={500} c={'#6941C6'}>Nakdi Yardım - İlaç</Text>
             </Group>
           </Box>
         </Group>
         <Box w={36} ta={'center'}>
           <Box display={'inline-block'} h={12.13} w={1.49} style={{borderRadius:40}} bg={"#E4E7EC"}/>
         </Box>
         <Group>
           <ThemeIcon color={"#28A745"} radius={36} size={40}>
             <PiCheckCircleFill size={22} />
           </ThemeIcon>
           <Box flex={1}>
             <Group gap={6}>
               <Text fz={13} fw={500} c={'#344054'}>Sefa Z.</Text>
               <Text fz={10} fw={400} c={'#344054'}>24.04.2024 - 14:24</Text>
             </Group>
             <Group gap={6}>
               <Text fz={13} fw={500} c={'#344054'}>Başvuru işlemi: </Text>
               <Text fz={13} fw={500} c={'#6941C6'}>Onay - Başvuru Sahibi: Yiğit H., Başvuru: Nakdi Yardım-İlaç</Text>
             </Group>
           </Box>
         </Group>
         <Box w={36} ta={'center'}>
           <Box display={'inline-block'} h={12.13} w={1.49} style={{borderRadius:40}} bg={"#E4E7EC"}/>
         </Box>
         <Group>
           <ThemeIcon color={"#DC3545"} radius={36} size={40}>
             <TiCancel size={25} />
           </ThemeIcon>
           <Box flex={1}>
             <Group gap={6}>
               <Text fz={13} fw={500} c={'#344054'}>Sefa Z.</Text>
               <Text fz={10} fw={400} c={'#344054'}>24.04.2024 - 14:24</Text>
             </Group>
             <Group gap={6}>
               <Text fz={13} fw={500} c={'#344054'}>Başvuru işlemi: </Text>
               <Text fz={13} fw={500} c={'#6941C6'}>RED - Başvuru Sahibi: Yiğit H., Başvuru: Nakdi Yardım-İlaç</Text>
             </Group>
           </Box>
         </Group>
         <Box w={36} ta={'center'}>
           <Box display={'inline-block'} h={12.13} w={1.49} style={{borderRadius:40}} bg={"#E4E7EC"}/>
         </Box>
         <Group>
           <ThemeIcon color={"#28A745"} radius={36} size={40}>
             <PiCheckCircleFill size={22} />
           </ThemeIcon>
           <Box flex={1}>
             <Group gap={6}>
               <Text fz={13} fw={500} c={'#344054'}>Sefa Z.</Text>
               <Text fz={10} fw={400} c={'#344054'}>24.04.2024 - 14:24</Text>
             </Group>
             <Group gap={6}>
               <Text fz={13} fw={500} c={'#344054'}>Başvuru işlemi: </Text>
               <Text fz={13} fw={500} c={'#6941C6'}>Onay - Başvuru Sahibi: Yiğit H., Başvuru: Nakdi Yardım-İlaç</Text>
             </Group>
           </Box>
         </Group>
         <Box w={36} ta={'center'}>
           <Box display={'inline-block'} h={12.13} w={1.49} style={{borderRadius:40}} bg={"#E4E7EC"}/>
         </Box>
         <Group>
           <ThemeIcon color={"#28A745"} radius={36} size={40}>
             <PiCheckCircleFill size={22} />
           </ThemeIcon>
           <Box flex={1}>
             <Group gap={6}>
               <Text fz={13} fw={500} c={'#344054'}>Sefa Z.</Text>
               <Text fz={10} fw={400} c={'#344054'}>24.04.2024 - 14:24</Text>
             </Group>
             <Group gap={6}>
               <Text fz={13} fw={500} c={'#344054'}>Başvuru işlemi: </Text>
               <Text fz={13} fw={500} c={'#6941C6'}>Onay - Başvuru Sahibi: Yiğit H., Başvuru: Nakdi Yardım-İlaç</Text>
             </Group>
           </Box>
         </Group>
         <Box w={36} ta={'center'}>
           <Box display={'inline-block'} h={12.13} w={1.49} style={{borderRadius:40}} bg={"#E4E7EC"}/>
         </Box>
         <Group>
           <ThemeIcon color={"#28A745"} radius={36} size={40}>
             <PiCheckCircleFill size={22} />
           </ThemeIcon>
           <Box flex={1}>
             <Group gap={6}>
               <Text fz={13} fw={500} c={'#344054'}>Sefa Z.</Text>
               <Text fz={10} fw={400} c={'#344054'}>24.04.2024 - 14:24</Text>
             </Group>
             <Group gap={6}>
               <Text fz={13} fw={500} c={'#344054'}>Başvuru işlemi: </Text>
               <Text fz={13} fw={500} c={'#6941C6'}>Onay - Başvuru Sahibi: Yiğit H., Başvuru: Nakdi Yardım-İlaç</Text>
             </Group>
           </Box>
         </Group>
         <Box w={36} ta={'center'}>
           <Box display={'inline-block'} h={12.13} w={1.49} style={{borderRadius:40}} bg={"#E4E7EC"}/>
         </Box>
         <Group>
           <ThemeIcon color={"#28A745"} radius={36} size={40}>
             <PiCheckCircleFill size={22} />
           </ThemeIcon>
           <Box flex={1}>
             <Group gap={6}>
               <Text fz={13} fw={500} c={'#344054'}>Sefa Z.</Text>
               <Text fz={10} fw={400} c={'#344054'}>24.04.2024 - 14:24</Text>
             </Group>
             <Group gap={6}>
               <Text fz={13} fw={500} c={'#344054'}>Başvuru işlemi: </Text>
               <Text fz={13} fw={500} c={'#6941C6'}>Onay - Başvuru Sahibi: Yiğit H., Başvuru: Nakdi Yardım-İlaç</Text>
             </Group>
           </Box>
         </Group>
         <Box w={36} ta={'center'}>
           <Box display={'inline-block'} h={12.13} w={1.49} style={{borderRadius:40}} bg={"#E4E7EC"}/>
         </Box>
         <Group>
           <ThemeIcon color={"#28A745"} radius={36} size={40}>
             <PiCheckCircleFill size={22} />
           </ThemeIcon>
           <Box flex={1}>
             <Group gap={6}>
               <Text fz={13} fw={500} c={'#344054'}>Sefa Z.</Text>
               <Text fz={10} fw={400} c={'#344054'}>24.04.2024 - 14:24</Text>
             </Group>
             <Group gap={6}>
               <Text fz={13} fw={500} c={'#344054'}>Başvuru işlemi: </Text>
               <Text fz={13} fw={500} c={'#6941C6'}>Onay - Başvuru Sahibi: Yiğit H., Başvuru: Nakdi Yardım-İlaç</Text>
             </Group>
           </Box>
         </Group>

       </Stack>
    </Box>

  </>)
}

export default Logs;
