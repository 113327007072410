import {
    Badge,
    Box, Button,
    Center, Checkbox, Divider,
    Group, LoadingOverlay,
    NumberInput,
    Pagination, RenderTreeNodePayload,
    Select,
    Table,
    Text,
    TextInput, Tree,
    UnstyledButton, useTree
} from '@mantine/core';
import {TreeNode} from '@mantine/core/lib/components/Tree/TreeNode';
import {useDisclosure, useDocumentTitle} from '@mantine/hooks';
import React, {useEffect, useState} from 'react';
import {IoDocumentTextOutline, IoEyeOutline, IoPencilOutline, IoTrashOutline} from 'react-icons/io5';
import {LiaStreetViewSolid} from 'react-icons/lia';
import {ThemeModal} from '../../components/Modal';
import TableTop from '../../components/Table/top';
import HeaderTitle from '../../components/Title';
import {
    useAddSocialDocumentMutation,
    useDeleteSocialDocumentMutation,
    useGetSocialDocumentsQuery
} from "../../services/social.service";
import {modals} from "@mantine/modals";
import {notifications} from "@mantine/notifications";
import {useForm} from "@mantine/form";


export const Documents = () => {

    useDocumentTitle('Balçova Belediyesi - Yüklenecek Evraklar');
    const [opened, {open, close}] = useDisclosure(false);
    const [editType, setEditType] = useState(false);

    const [checkedItems, setCheckedItems] = useState<string[]>([]);
    const tree = useTree();


    const {data, isSuccess, isError, isLoading, isFetching} = useGetSocialDocumentsQuery({});
    const [addDocument, addDocumentProps] = useAddSocialDocumentMutation({});
    const [deleteDocument, deleteDocumentProps] = useDeleteSocialDocumentMutation({});


    const form = useForm({
        initialValues: {
            name: '',
        },

        validate: {
            name: (value) => (value !== '' ? null : 'Başlık girmelisiniz'),
        },
    });

    const handleCheckboxChange = (value: string) => {
        setCheckedItems((prevCheckedItems) =>
            prevCheckedItems.includes(value)
                ? prevCheckedItems.filter((item) => item !== value)
                : [...prevCheckedItems, value]
        );
    };

    function Leaf({node, expanded, hasChildren, elementProps}: RenderTreeNodePayload) {
        return (
            <Group gap={5} {...elementProps}>
                <Checkbox
                    value={node.value}
                    checked={checkedItems.includes(node.value)}
                    onChange={() => handleCheckboxChange(node.value)}
                    label={node.label}
                />
            </Group>
        );
    }


    const rows = isSuccess ? data?.map((element, index) => (
        <Table.Tr key={`table-item-${index}`}>
            <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>{element.name}</Table.Td>
            <Table.Td py={24} px={24}>
                <Group gap={4} justify={'end'}>
                    <UnstyledButton w={40} onClick={() => {
                        modals.openConfirmModal({
                            centered: true,
                            title: 'Evrakı Sil',
                            children: (
                                <Text size="sm">
                                    Evrakı silmek istediğinize emin misiniz?
                                </Text>
                            ),
                            labels: {confirm: 'Evrakı Sil', cancel: "Geri Dön"},
                            confirmProps: {color: 'red'},
                            onCancel: () => console.log('Cancel'),
                            onConfirm: () => deleteDocument({documentId: String(element.id)}),
                        });
                    }}>
                        <IoTrashOutline size={19} color={"#475467"}/>
                    </UnstyledButton>
                    <UnstyledButton w={40}>
                        <IoPencilOutline size={19} color={"#475467"}/>
                    </UnstyledButton>
                </Group>
            </Table.Td>
        </Table.Tr>
    )) : [];


    useEffect(() => {
        if (deleteDocumentProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: deleteDocumentProps?.error?.data?.message ?? '',
                autoClose: 2000,
            })
        }
    }, [deleteDocumentProps.isError]);

    useEffect(() => {
        if (deleteDocumentProps.isSuccess) {
            setEditType(false);
            notifications.show({
                color: 'teal',
                title: 'Başarılı',
                message: 'Evrak Silindi',
                autoClose: 2000,
            });
        }
    }, [deleteDocumentProps.isSuccess]);


    useEffect(() => {
        if (addDocumentProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: addDocumentProps?.error?.data?.message ?? '',
                autoClose: 2000,
            })
        }
    }, [addDocumentProps.isError]);

    useEffect(() => {
        if (addDocumentProps.isSuccess) {
            close();
            form.setValues({
                name: '',
            });
            notifications.show({
                color: 'teal',
                title: 'Başarılı',
                message: 'Evrak Eklendi',
                autoClose: 2000,
            });
        }
    }, [addDocumentProps.isSuccess]);


    return (<>
        <HeaderTitle
            title={"Yüklenecek Evraklar"}
            count={`${data?.length || 0} Evrak Tipi`}
            description={"Sosyal Yardım Formunda yüklenmesi zorunlu evrakları listeleyin."}
            buttonText={"Yeni Evrak Ekle"}
            ml={-20}
            onClick={open}
            className={"header-title"}
        />



        <ThemeModal
            opened={opened}
            onClose={close}
            icon={<IoDocumentTextOutline size={25} color={"#7F56D9"}/>}
            title={"Yeni Evrak Türü Ekle"}
            description={"Başvuru sırasında yüklenmesini istediğiniz belge adını ve türünü girin."}>
            <form onSubmit={form.onSubmit((values) => {
                addDocument({data: values});
            })}>

                <Group className={"group-mb-disabled"} mt={22} align={'start'}>
                    <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Evrak Adı*</Text>
                    <Box flex={1}>
                        <TextInput
                            radius={8}
                            styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                            key={form.key('name')}
                            {...form.getInputProps('name')}
                        />
                    </Box>
                </Group>

                <Divider mx={-23} mt={22} color={'#EAECF0'}/>
                <Group grow gap={12} p={24} pb={0}>
                    <Button onClick={close} style={{borderColor: '#D0D5DD'}} variant={"outline"} h={44}
                            fz={16} c={'#344054'} fw={600} radius={8}>İptal Et</Button>
                    <Button type={'submit'} loading={addDocumentProps.isLoading} h={44} fz={16} c={'#fff'} fw={600}
                            radius={8}
                    >Ekle</Button>
                </Group>
            </form>
        </ThemeModal>

        <Table className={"responsive-table"} mt={20} ml={-46} w={'calc(100% + 62px)'} striped>
            <LoadingOverlay visible={isFetching}/>
            <TableTop
                titles={["Evrak Adı", ""]}
            />
            <Table.Tbody>{rows}</Table.Tbody>

        </Table>



    </>)
}

export default Documents;
