import {createApi} from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../lib/axiosBaseQuery";

export const addressApi = createApi({
    reducerPath: "addressApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: [],
    endpoints: (builder) => ({
        getAddressCities: builder.query<Array<{ id: number, name: string }>, {}>({
            query: () => ({
                url: `/address/cities`,
                method: "GET",
                includeToken: true,
            }),
            transformResponse: (result: Array<{ id: number, name: string }>) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),

        getAddressCityNeighborhoods: builder.query<Array<{ id: number, cityId: number, name: string }>, {
            cityId: any
        }>({
            query: ({cityId}) => ({
                url: `/address/cities/${cityId}/neighborhoods`,
                method: "GET",
                includeToken: true,
            }),

            transformResponse: (result: Array<{ id: number, cityId: number, name: string }>) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),

        getCityNeighborhoodId: builder.query<Array<{ id: number, neighborhoodsId: string, name: string }>, {
            cityId: string,
            neighborhoodsId: string
        }>({
            query: ({cityId, neighborhoodsId}) => ({
                url: `/address/cities/${cityId}/neighborhoods/${neighborhoodsId}`,
                method: "GET",
                includeToken: true,
            }),
            //@ts-ignore
            transformResponse: (result: Array<{ id: number, neighborhoodsId: number, name: string }>) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),

    })
});

export const {
    useGetAddressCitiesQuery,
    useGetAddressCityNeighborhoodsQuery,
    useGetCityNeighborhoodIdQuery

} = addressApi;
