import {
    Badge,
    Box,
    Button,
    Center,
    Divider,
    Group,
    Image,
    LoadingOverlay,
    NumberInput,
    Pagination,
    PasswordInput,
    Select,
    Table,
    Text,
    TextInput,
    UnstyledButton
} from '@mantine/core';
import {useDisclosure, useDocumentTitle} from '@mantine/hooks';
import React, {useEffect, useState} from 'react';
import {IoPencilOutline, IoTrashOutline} from 'react-icons/io5';
import {LiaUser} from 'react-icons/lia';
import {ThemeModal} from '../../components/Modal';
import TableTop from '../../components/Table/top';
import HeaderTitle from '../../components/Title';
import {
    useCreateAccountMutation,
    useDeleteAccountMutation,
    useGetAccountsQuery,
    useUpdateAccountMutation
} from "../../services/auth.service";
import {useForm} from "@mantine/form";
import {notifications} from "@mantine/notifications";
import {modals} from "@mantine/modals";
import {useGetHousesQuery} from "../../services/houses.service";
import * as Service from "../../services/system";

const AuthService = Service.Auth.init();

export const Users = () => {

    useDocumentTitle('Balçova Belediyesi - Kullanıcılar');
    const [opened, {open, close}] = useDisclosure(false);
    const [pageHouse,setPageHouse] = useState(1);
    const [activePage, setActivePage] = useState(1);
    const houses = useGetHousesQuery({page:pageHouse});
    const {data, isSuccess, isLoading,isFetching} = useGetAccountsQuery({page: activePage});
    const [createAccount, createAccountProps] = useCreateAccountMutation({});
    const [updateAccount, updateAccountProps] = useUpdateAccountMutation({});
    const [deleteAccount, deleteAccountProps] = useDeleteAccountMutation({});
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState<any>('');
    const [housesList, setHousesList] = useState<any>([]);
    //@ts-ignore
    const user = JSON.parse(AuthService.user().get());


    const form = useForm({
        initialValues: {
            email: '',
            phoneNumber: '',
            password: '',
            fullName: '',
            semtEviId: ''
        },
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Geçersiz mail adresi'),
            phoneNumber: (value) => (value !== '' ? null : 'Telefon No girmelisiniz'),
            password: (value) => ((value !== '' || isEdit) ? null : 'Şifre girmelisiniz'),
            fullName: (value) => (value !== '' ? null : 'Ad Soyad girmelisiniz'),
            semtEviId: (value) => (value !== '' ? null : 'Semtevi Seçmelisiniz'),
        },
    });

    useEffect(() => {
        let _data = [{label: 'Seçiniz', value: ''}];
        if (houses.isSuccess) {
            houses.data.items.map((item, index) => {
                _data.push({label: item.name, value: String(item.id)})
            });
        }
        setHousesList(_data);
    }, [houses.isSuccess]);

    useEffect(() => {
        if (createAccountProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: createAccountProps?.error?.data?.title ?? 'Bilnmeyen hata',
                autoClose: 2000,
            });
        }
    }, [createAccountProps.isError]);

    useEffect(() => {
        if (createAccountProps.isSuccess) {
            close();
            notifications.show({
                color: 'teal',
                title: 'Başarılı',
                message: 'Kullanıcı Eklendi',
                autoClose: 2000,
            });
            setTimeout(() => {
                form.setValues({
                    email: '',
                    phoneNumber: '',
                    password: '',
                    fullName: '',
                    semtEviId: ''
                });
            }, 250);
        }
    }, [createAccountProps.isSuccess]);


    useEffect(() => {
        if (updateAccountProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: updateAccountProps?.error?.data?.title ?? 'Bilnmeyen hata',
                autoClose: 2000,
            });
        }
    }, [updateAccountProps.isError]);

    useEffect(() => {
        if (updateAccountProps.isSuccess) {
            close();
            notifications.show({
                color: 'teal',
                title: 'Başarılı',
                message: 'Düzenleme kaydedildi',
                autoClose: 2000,
            });
            setTimeout(() => {
                form.setValues({
                    email: '',
                    phoneNumber: '',
                    password: '',
                    fullName: '',
                    semtEviId: ''
                });
            }, 250);
        }
    }, [updateAccountProps.isSuccess]);

    useEffect(() => {
        if (deleteAccountProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: deleteAccountProps?.error?.data?.message ?? '',
                autoClose: 2000,
            })
        }
    }, [deleteAccountProps.isError]);

    useEffect(() => {
        if (deleteAccountProps.isSuccess) {
            notifications.show({
                color: 'teal',
                title: 'Başarılı',
                message: 'Kullanıcı Silindi',
                autoClose: 2000,
            });
        }
    }, [deleteAccountProps.isSuccess]);


    const rows = isSuccess ? data.items.map((element, index) => (
        <Table.Tr key={`table-item-${index}`}>
            <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>{element.fullName}</Table.Td>
            <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
                <Badge fz={13} fw={500} h={24} display={"flex"}
                       color={element.role === 3 ? '#FFA500' : '#F9FAFB'}
                       style={{
                           border: '1px solid #ABEFC6'
                       }}
                       leftSection={<Image w={element.role === 3 ? 13 : 16}
                                           src={`/images/${element.role === 3 ? 'guard' : 'supervisor'}.svg`}/>}
                       c={element.role === 3 ? '#fff' : '#000'} radius={40} px={10} tt={'initial'} variant={'filled'}>
                    {element.role === 3 ? 'Süper Admin' : 'Yönetici'}</Badge>
            </Table.Td>
            <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.email}</Table.Td>
            <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.phoneNumber}</Table.Td>
            <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>
                {element?.semtEvi?.name || ''}
            </Table.Td>
            <Table.Td py={24} px={24}>
                <Group gap={4} justify={'end'}>

                    {(user?.role === 3 && element.id !== user?.id) &&
                      <UnstyledButton w={40} onClick={() => {
                          modals.openConfirmModal({
                              centered: true,
                              title: 'Kullanıcıyı Sil',
                              children: (
                                  <Text size="sm">
                                      kullanıcıyı silmek istediğinize emin misiniz?
                                  </Text>
                              ),
                              labels: {confirm: 'Kullanıcıyı Sil', cancel: "Geri Dön"},
                              confirmProps: {color: 'red'},
                              onCancel: () => console.log('Cancel'),
                              onConfirm: () => deleteAccount({id: String(element.id)}),
                          });
                      }}>
                        <IoTrashOutline size={19} color={"#475467"}/>
                      </UnstyledButton>
                    }
                    {user?.role === 3 &&
                      <UnstyledButton w={40} onClick={() => {
                          form.setValues({
                              email: element.email,
                              phoneNumber: element.phoneNumber,
                              password: '',
                              fullName: element.fullName,
                              semtEviId: element?.semtEvi?.id || ''
                          })
                          setIsEdit(true);
                          setEditId(element?.id || '');
                          open();
                      }}>
                        <IoPencilOutline size={19} color={"#475467"}/>
                      </UnstyledButton>

                    }
                </Group>
            </Table.Td>
        </Table.Tr>
    )) : [];

    return (<>
        <HeaderTitle
            title={"Kullanıcılar"}
            count={`${data?.totalItems || 0} kullanıcı`}
            description={"Sistem kullanıcılarını yönetin"}
            buttonText={user?.role === 3 ? "Yeni Kullanıcı Ekle" : ""}
            ml={-20}
            className={"header-title"}
            onClick={open}
        />

        <ThemeModal
            opened={opened}
            onClose={() => {
                close();
                setIsEdit(false);
                setEditId('');
            }}
            submitBtnText={`${isEdit ? 'Düzenlemeyi Kaydet' : 'Ekle'}`}
            icon={<LiaUser size={25} color={"#7F56D9"}/>}
            title={`${isEdit ? 'Kullanıcı Düzenle' : 'Yeni Kullanıcı Ekle'}`}
            description={`${isEdit ? 'Kullanıcı bilgilerini düzenleyin.' : 'Sistem erişimi için yeni bir kullanıcı ekleyin.'}`}>
            <form onSubmit={form.onSubmit((values) => {
                values.phoneNumber = String(values.phoneNumber);
                if (isEdit) {
                    updateAccount({id: editId, values});
                } else {
                    createAccount(values);
                }
            })}>
                <Divider mb={16} color={'#EAECF0'}/>

                <Group className={"group-mb-disabled"} mb={16} align={'start'}>
                    <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Ad Soyad*</Text>
                    <Box flex={1}>
                        <TextInput placeholder={"Ad soyad yazın."} radius={8}
                                   styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                                   key={form.key('fullName')}
                                   {...form.getInputProps('fullName')}
                        />
                    </Box>
                </Group>
                <Group className={"group-mb-disabled"} mb={16} align={'start'}>
                    <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>E-Posta adresini yazın.</Text>
                    <Box flex={1}>
                        <TextInput placeholder={"E-Posta Adresi*"} radius={8}
                                   styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                                   key={form.key('email')}
                                   {...form.getInputProps('email')}
                        />
                    </Box>
                </Group>
                <Group className={"group-mb-disabled"} mb={16} align={'start'}>
                    <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Telefon Numarası*</Text>
                    <Box flex={1}>
                        <NumberInput placeholder={"Telefon numarasını yazın."} hideControls radius={8}
                                     styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                                     key={form.key('phoneNumber')}
                                     {...form.getInputProps('phoneNumber')}
                        />
                    </Box>
                </Group>
                <Divider mb={16} color={'#EAECF0'}/>
                <Group className={"group-mb-disabled"} mb={16} align={'start'}>
                    <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Semtevi*</Text>
                    <Box flex={1}>
                        <Select data={housesList} allowDeselect={false} defaultValue={''} radius={8}
                                styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                                key={form.key('semtEviId')}
                                {...form.getInputProps('semtEviId')}
                        />
                        <Text fz={13} mt={8} fw={400} c={'#475467'} lh={'20px'}>Kullanıcıyı atamak istediğiniz semtevini
                            seçin.</Text>
                    </Box>
                </Group>
                <Divider mb={16} color={'#EAECF0'}/>

                <Group className={"group-mb-disabled"} align={'start'}>
                    <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Şifre*</Text>
                    <Box flex={1}>
                        <PasswordInput placeholder={"*********"} radius={8}
                                       styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                                       key={form.key('password')}
                                       {...form.getInputProps('password')}
                        />

                        <Text mt={8} fz={13} fw={400} c={'#475467'} lh={'20px'}>Değiştirmek için yeni bir şifre
                            yazın.</Text>
                    </Box>
                </Group>

                <Divider mx={-23} mt={22} color={'#EAECF0'}/>
                <Group grow gap={12} p={24} pb={0}>
                    <Button onClick={close} style={{borderColor: '#D0D5DD'}} variant={"outline"} h={44}
                            fz={16} c={'#344054'} fw={600} radius={8}>İptal Et</Button>
                    <Button type={'submit'} loading={createAccountProps.isLoading || updateAccountProps.isLoading}
                            h={44} fz={16} c={'#fff'} fw={600}
                            radius={8}
                    >Ekle</Button>
                </Group>
            </form>
        </ThemeModal>

        <Table pos={'relative'} className={"responsive-table"} mt={20} mb={60} ml={-46} w={'calc(100% + 62px)'} striped>
            <TableTop
                titles={["Kullanıcı Adı Soyadı", "Rol", "E-Posta", "Telefon", "Semtevi", ""]}
            />
            <LoadingOverlay visible={isFetching}/>
            <Table.Tbody>{rows}</Table.Tbody>

        </Table>


        <Center h={68} bg={'#fff'} pos={'fixed'} left={0} bottom={0} w={'100%'}
                style={{borderTop: '1px solid #EAECF0'}}>
            <Pagination
                color={"#f9fafb"}
                total={Number(data?.totalPages || 0)}
                withControls={false}
                onChange={setActivePage}
                styles={{
                    control: {
                        color: '#475467',
                        fontSize: 14,
                        border: 'none'
                    },
                    dots: {
                        fontSize: 14,
                        color: '#475467',

                    }
                }}
            />
        </Center>

    </>)
}

export default Users;
