import {
    Badge,
    Box, Button,
    Center, Checkbox, Divider, Grid,
    Group, LoadingOverlay,
    NumberInput,
    Pagination, Radio, RenderTreeNodePayload,
    Select,
    Table,
    Text,
    TextInput, Tooltip, Tree,
    UnstyledButton, useTree
} from '@mantine/core';
import {TreeNode} from '@mantine/core/lib/components/Tree/TreeNode';
import {useDisclosure, useDocumentTitle} from '@mantine/hooks';
import React, {useEffect, useState} from 'react';
import {IoDocumentTextOutline, IoEye, IoEyeOutline, IoPencilOutline, IoTrashOutline} from 'react-icons/io5';
import {LiaStreetViewSolid} from 'react-icons/lia';
import {SlSupport} from 'react-icons/sl';
import {Link} from 'react-router-dom';
import {ThemeModal} from '../../components/Modal';
import TableTop from '../../components/Table/top';
import HeaderTitle from '../../components/Title';
import {useAddCitizensMutation} from "../../services/citizens.service";
import {notifications} from "@mantine/notifications";
import {
    useAddSocialCategoryMutation,
    useAddSocialHelpMutation, useDeleteSocialCategoryMutation, useDeleteSocialHelpMutation,
    useGetSocialCategoryQuery, useUpdateSocialCategoryMutation
} from "../../services/social.service";
import {useForm} from "@mantine/form";
import {modals} from "@mantine/modals";


export const Help = () => {

    useDocumentTitle('Balçova Belediyesi - Yardım Tipi Kategorileri');
    const [opened, {open, close}] = useDisclosure(false);
    const [editType, setEditType] = useState(false);
    const [isEditId,setIsEditId] = useState<string|null>(null);
    const [openDocument,setOpenDocument] = useState(false);
    const {data, isSuccess, isError, isLoading, isFetching} = useGetSocialCategoryQuery({});
    const [categoryTitle, setCategoryTitle] = useState<string>('');

    const [addCategory, addCategoryProps] = useAddSocialCategoryMutation({});
    const [updateCategory, updateCategoryProps] = useUpdateSocialCategoryMutation({});
    const [deleteCategory, deleteCategoryProps] = useDeleteSocialCategoryMutation({});
    const [addHelp, addHelpProps] = useAddSocialHelpMutation({});
    const tree = useTree();
    const [checkedItems, setCheckedItems] = useState<string[]>([]);

    const handleCheckboxChange = (value: string) => {
        setCheckedItems((prevCheckedItems) =>
            prevCheckedItems.includes(value)
                ? prevCheckedItems.filter((item) => item !== value)
                : [...prevCheckedItems, value]
        );
    };

    const form = useForm({
        initialValues: {
            categoryId: '',
            name: '',
            period: "7",
            documentsIds: []
        },

        validate: {
            categoryId: (value) => (value !== '' ? null : 'Kategori seçmelisiniz'),
            name: (value) => (value !== '' ? null : 'Başlık girmelisiniz'),
        },
    });

    useEffect(() => {
        if (deleteCategoryProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: deleteCategoryProps?.error?.data?.message ?? '',
                autoClose: 2000,
            })
        }
    }, [deleteCategoryProps.isError]);

    useEffect(() => {
        if (deleteCategoryProps.isSuccess) {
            setEditType(false);
            setCategoryTitle('');
            notifications.show({
                color: 'teal',
                title: 'Başarılı',
                message: 'Kategori Silindi',
                autoClose: 2000,
            });
        }
    }, [deleteCategoryProps.isSuccess]);


    useEffect(() => {
        if (addHelpProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: addHelpProps?.error?.data?.message ?? '',
                autoClose: 2000,
            })
        }
    }, [addHelpProps.isError]);

    useEffect(() => {
        if (addHelpProps.isSuccess) {
          close();
          form.setValues({
              categoryId: '',
              name: '',
              period: "7",
              documentsIds: []
          });
            notifications.show({
                color: 'teal',
                title: 'Başarılı',
                message: 'Kategori Konusu Eklendi',
                autoClose: 2000,
            });
        }
    }, [addHelpProps.isSuccess]);

    useEffect(() => {
        if (addCategoryProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: addCategoryProps?.error?.data?.message ?? '',
                autoClose: 2000,
            })
        }
    }, [addCategoryProps.isError]);

    useEffect(() => {
        if (addCategoryProps.isSuccess) {
            setEditType(false);
            setCategoryTitle('');
            notifications.show({
                color: 'teal',
                title: 'Başarılı',
                message: 'Kategori Eklendi',
                autoClose: 2000,
            });
        }
    }, [addCategoryProps.isSuccess]);


    useEffect(() => {
        if (updateCategoryProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: updateCategoryProps?.error?.data?.message ?? '',
                autoClose: 2000,
            })
        }
    }, [updateCategoryProps.isError]);

    useEffect(() => {
        if (updateCategoryProps.isSuccess) {
            setEditType(false);
            setCategoryTitle('');
            setIsEditId(null);
            notifications.show({
                color: 'teal',
                title: 'Başarılı',
                message: 'Güncelleme Tamamlandı',
                autoClose: 2000,
            });
        }
    }, [updateCategoryProps.isSuccess]);


    const _addCategory = () => {
        if (categoryTitle !== '') {
            addCategory({data: {name: categoryTitle}});
        } else {
            notifications.show({
                color: 'red',
                title: 'Hata',
                message: 'Kategori başlığı girmelisiniz',
                autoClose: 2000,
            });
        }
    }

    const _updateCategory = () => {
        if (categoryTitle !== '') {
            updateCategory({name: categoryTitle,categoryId:String(isEditId)});
        } else {
            notifications.show({
                color: 'red',
                title: 'Hata',
                message: 'Kategori başlığı girmelisiniz',
                autoClose: 2000,
            });
        }
    }

    const getHelpCat = () => {
        let _data: any = [{label: 'Seçiniz', value: ''}];
        if (isSuccess) {
            data.map((item, index) => _data.push({label: item?.name || '', value: String(item?.id || '')}));
        }

        return _data;
    }

    function Leaf({node, expanded, hasChildren, elementProps}: RenderTreeNodePayload) {
        return (
            <Group gap={5} {...elementProps}>
                <Checkbox
                    value={node.value}
                    checked={checkedItems.includes(node.value)}
                    onChange={() => handleCheckboxChange(node.value)}
                    label={node.label}
                />
            </Group>
        );
    }


    const rows = isSuccess ? data.map((element: any, index) => (
        <Table.Tr key={`table-item-${index}`}>
            <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>{element.name}</Table.Td>
            <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>
                <UnstyledButton fz={14} fw={400} c={'#475467'} mr={2} component={Link}
                                to={`/help/sub/${element.id}?title=${element.name}`}>Düzenle</UnstyledButton>
            </Table.Td>
            <Table.Td py={24} px={24}>
                <Group gap={4} justify={'end'}>
                    <UnstyledButton w={40} onClick={() => {
                        modals.openConfirmModal({
                            centered: true,
                            title: 'Kategoriyi Sil',
                            children: (
                                <Text size="sm">
                                    Kategoriyi silmek istediğinize emin misiniz?
                                </Text>
                            ),
                            labels: {confirm: 'Kategoriyi Sil', cancel: "Geri Dön"},
                            confirmProps: {color: 'red'},
                            onCancel: () => console.log('Cancel'),
                            onConfirm: () => deleteCategory({categoryId: String(element.id)}),
                        });
                    }}>
                        <IoTrashOutline size={19} color={"#475467"}/>
                    </UnstyledButton>

                    <UnstyledButton w={40} onClick={()=>{
                        setIsEditId(element.id);
                        setCategoryTitle(element?.name || '')
                        setEditType(true);
                    }}>
                        <IoPencilOutline size={19} color={"#475467"}/>
                    </UnstyledButton>
                </Group>
            </Table.Td>
        </Table.Tr>
    )) : [];

    const treeData: any[] = [
        {
            label: 'Node 1',
            value: 'node1',
            children: [
                {label: 'Child 1.1', value: 'child1.1'},
                {label: 'Child 1.2', value: 'child1.2'},
            ],
        },
        {
            label: 'Node 2',
            value: 'node2',
            children: [
                {label: 'Child 2.1', value: 'child2.1'},
                {label: 'Child 2.2', value: 'child2.2'},
            ],
        },
    ];



    return (<>
        <HeaderTitle
            title={"Yardım Tipi Kategorileri"}
            count={`${data?.length || 0} Yardım Tipi`}
            description={"Başvuru kabul edilen yardım tiplerini ve yardım konularını belirleyin."}
            buttonText={"Yeni Yardım Tipi Ekle"}
            ml={-20}
            onClick={open}
            className={"header-title"}
            extraComponent={<Button mr={16} variant={"outline"}
                                    style={{border: 'none', boxShadow: '2px 2px 2px 0px rgba(0,0,0,0.05)'}} h={40}
                                    radius={8} fz={14} fw={600} c={'#000'}
                                    onClick={() => setEditType(true)}
            >Yeni Kategori Ekle</Button>}
        />


        <ThemeModal
            opened={editType}
            onClose={() => {
                setEditType(false);
                setIsEditId(null);
                setCategoryTitle('');
            }}
            icon={<SlSupport size={22} color={"#7F56D9"}/>}
            title={isEditId ? "Kategoriyi Güncelle":  "Yeni Kategori Ekle"}
            description={isEditId ? "Yardım tipi kategorisini güncelleyin": "Başvuruya açmak istediğiniz yardım tipinin detaylarını girin."}>
            <Group className={"group-mb-disabled"} mt={22} align={'start'}>
                <Text fz={14} fw={500} lh={'20px'} c={'#344054'} w={180} pr={30}>Yardım Tipi Kategori Adı*</Text>
                <Box flex={1}>
                    <TextInput onChange={(e) => setCategoryTitle(e.currentTarget.value)} value={categoryTitle}
                               radius={8} styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}/>
                </Box>
            </Group>
            <Divider mx={-23} mt={22} color={'#EAECF0'}/>
            <Group grow gap={12} p={24} pb={0}>
                <Button onClick={() => {
                    setEditType(false);
                    setIsEditId(null);
                    setCategoryTitle('');
                }} style={{borderColor: '#D0D5DD'}} variant={"outline"} h={44}
                        fz={16} c={'#344054'} fw={600} radius={8}>İptal Et</Button>
                <Button loading={addCategoryProps.isLoading || updateCategoryProps.isLoading} h={44} fz={16} c={'#fff'} fw={600} radius={8}
                        onClick={() => {
                            isEditId ? _updateCategory() : _addCategory()
                        }}>{isEditId ? 'Düzenle' : 'Ekle'}</Button>
            </Group>

        </ThemeModal>

        <ThemeModal
            opened={opened}
            onClose={close}
            icon={<SlSupport size={22} color={"#7F56D9"}/>}
            title={"Yeni Yardım Tipi Konusu Ekle"}
            description={"Başvuruya açmak istediğiniz yardım tipinin alt konularını belirleyin."}>
            <form onSubmit={form.onSubmit((values) => {
                let _values = values;
                //@ts-ignore
                _values.period = Number(values.period);
                addHelp({data:values});
            })}>

                <Group className={"group-mb-disabled"} mt={22} mb={16} align={'start'}>
                    <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Yardım Tipi*</Text>
                    <Box flex={1}>
                        <Select data={getHelpCat()} allowDeselect={false} defaultValue={''} radius={8}
                                styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                                key={form.key('categoryId')}
                                {...form.getInputProps('categoryId')}

                        />
                        <Text fz={13} mt={8} fw={400} c={'#475467'} lh={'20px'}>Eşleştirmek istediğiniz yardım tipini
                            seçin.</Text>
                    </Box>
                </Group>
                <Divider mb={16} color={'#EAECF0'}/>

                <Group className={"group-mb-disabled"} mb={16} align={'start'}>
                    <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Yardım Tipi Konu Adı*</Text>
                    <Box flex={1}>
                        <TextInput radius={8} styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                                   key={form.key('name')}
                                   {...form.getInputProps('name')}

                        />

                    </Box>
                </Group>
                <Divider mb={20} color={'#EAECF0'}/>
                <Group className={"group-mb-disabled"} align={'start'}>
                    <Text mb={4} mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Başvuru Periyodu:</Text>
                    <Box flex={1}>
                        <Radio.Group
                            name="period"
                            key={form.key('period')}
                            {...form.getInputProps('period')}
                        >

                            <Grid columns={8} pr={40}>
                                <Grid.Col span={{lg: 2, base: 4}}>
                                    <Radio size={"sm"} value={"7"} label={"7 Gün"} fz={14} fw={500} c={'#101828'}
                                           radius={6}

                                    />
                                </Grid.Col>
                                <Grid.Col span={{lg: 2, base: 4}}>
                                    <Radio size={"sm"} value={"30"} label={"30 gün"} fz={14} fw={500} c={'#101828'}
                                           radius={6}

                                    />
                                </Grid.Col>
                                <Grid.Col span={{lg: 2, base: 4}}>
                                    <Radio size={"sm"} value={"60"} label={"60 Gün"} fz={14} fw={500} c={'#101828'}
                                           radius={6}

                                    />
                                </Grid.Col>
                                <Grid.Col span={{lg: 2, base: 4}}>
                                    <Radio size={"sm"} value={"180"} label={"180 Gün"} fz={14} fw={500} c={'#101828'}
                                           radius={6}
                                    />
                                </Grid.Col>
                                <Grid.Col span={{lg: 12, base: 12}}>
                                    <Group>
                                        <Radio size={"sm"} value={"0"} label={"Özel"} fz={14} fw={500} c={'#101828'}
                                               radius={6}/>
                                        {(form.values.period !== '7' && form.values.period !== '30'
                                            && form.values.period !== '60' && form.values.period !== '180') &&
                                          <NumberInput
                                            w={150}
                                            hideControls
                                            key={form.key('period')}
                                            rightSection={<Text mr={20} fz={12}>Gün</Text>}
                                            {...form.getInputProps('period')}
                                          />
                                        }
                                    </Group>
                                </Grid.Col>

                            </Grid>
                        </Radio.Group>
                    </Box>
                </Group>
                <Divider mx={-23} mt={22} color={'#EAECF0'}/>
                <Group grow gap={12} p={24} pb={0}>
                    <Button onClick={() => close()} style={{borderColor: '#D0D5DD'}} variant={"outline"}
                            h={44}
                            fz={16} c={'#344054'} fw={600} radius={8}>İptal Et</Button>
                    <Button loading={addHelpProps.isLoading} h={44} fz={16} c={'#fff'} fw={600} radius={8}
                            type={'submit'}>Ekle</Button>
                </Group>

            </form>
        </ThemeModal>

        <Table pos={'relative'} className={"responsive-table"} mt={20} ml={-46} w={'calc(100% + 62px)'} striped>
            <LoadingOverlay visible={isFetching}/>
            <TableTop
                titles={["Kategori", "Yardım Tipi Konuları", ""]}
            />
            <Table.Tbody>{rows}</Table.Tbody>

        </Table>




    </>)
}

export default Help;
