import {
  AppShell,
  Avatar,
  Box,
  Burger,
  Button,
  Center,
  Divider,
  Group,
  Image,
  Menu,
  NavLink, ScrollArea,
  Slider,
  Stack,
  Text,
  TextInput,
  ThemeIcon
} from '@mantine/core';
import {useDisclosure, useMediaQuery} from '@mantine/hooks';
import React from 'react';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { IoMdSettings } from 'react-icons/io';
import { IoChevronForward, IoLogOutOutline, IoSearch } from 'react-icons/io5';
import { PiUploadFill } from 'react-icons/pi';
import { RiApps2Fill, RiHomeFill } from 'react-icons/ri';
import { TiUser } from 'react-icons/ti';
import {NavLink as Link, useLocation, useNavigate} from 'react-router-dom';
import classes from './Content.module.css';
import {RootState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {setUserInfo} from "../../store/slices/user.slice";
import * as Service from "../../services/system";



const AuthService = Service.Auth.init();

interface Props {
  children: React.ReactNode
}

export const Content = ({ children }: Props) => {
  const [opened, { toggle }] = useDisclosure();
  const location = useLocation();
  const pathname = location.pathname;
  //@ts-ignore
  const user =   JSON.parse(AuthService.user().get());
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery('(min-width: 1200px)');


  // Check if the path includes "/basic"
  const isBasic = user?.role !== 3;



  if (pathname === '/login' || pathname === '/forgot' || pathname === '/register') {
    return (<>
      {children}
    </>)
  }

  return (<AppShell
    header={{ height: 60 }}
    navbar={{ width: 300, breakpoint: 'lg', collapsed: { mobile: !opened } }}
    padding="md"
  >
    {!matches &&
        <AppShell.Header pos={!matches ? 'static' : 'relative'} zIndex={44} style={{borderColor:'#fff'}}>
          <Group h="100%">
            <Group className={"mb-logo"} h={61} bg={'#fff'} w={270} left={0} pos={'absolute'}
                   style={{ borderRight: '1px solid #dee2e6', zIndex: 3 }}>
              <Box flex={1} hiddenFrom={"lg"}>
                <Image src={'/images/logo.png'}  w={136} height={55}/>
              </Box>
              <Burger opened={opened} onClick={toggle} hiddenFrom="lg" size="sm"/>
            </Group>
          </Group>
        </AppShell.Header>
    }
    <AppShell.Navbar p="md" w={270}>


        <Center  className={"hide-mb-center"} w={'100%'} mt={-55}>
          <Image src={'/images/logo.png'} width={136} height={55} styles={{
            root:{
              width:136,
              height:55
            }
          }}/>
        </Center>

      <Text mt={23} fz={11} fw={400} c={'#666666'}>İnceleme bekleyen başvuru sayısı</Text>
      <Text c={'#000'} fw={500} lts={'-0.5px'} lh={'18.15px'} fz={15} mt={4}>23</Text>
      <Slider mb={18} mt={4} defaultValue={23} disabled className={classes.slider} styles={{
        bar: {
          backgroundColor: 'rgba(32, 201, 51, 1)'
        },
      }}/>
      <Button component={Link} to={`/applications`} mb={27} variant={"outline"}
              color={"#EBEBEB"} h={32} radius={3} fz={13} fw={500} c={'#000'}>Başvuruları
        Görüntüle</Button>

      <ScrollArea pb={10} flex={1} scrollbarSize={0}>
        <Stack flex={1} gap={5}>
          <NavLink
              component={Link}
              variant={"filled"}
              to={`${isBasic ? '/dashboard' : '/'}`}
              label={"Anasayfa"}
              leftSection={<RiApps2Fill size={17}/>}
              h={40}
              style={{ borderRadius: 3 }}
              color={isBasic ? '#000000' : 'indigo'}
          />
          <NavLink
              component={Link}
              variant={"filled"}
              to={`${isBasic ? '/applications' : '/applications'}`}
              label={"Başvurular"}
              leftSection={<PiUploadFill size={17}/>}
              h={40}
              style={{ borderRadius: 3 }}
              color={isBasic ? '#000000' : 'indigo'}
          />
          <NavLink
              component={Link}
              variant={"filled"}
              to={`/citizens`}
              label={"Vatandaşlar"}
              leftSection={<TiUser size={17}/>}
              h={40}
              style={{ borderRadius: 3 }}
              color={isBasic ? '#000000' : 'indigo'}
          />

          {isBasic &&
              <NavLink
                  component={Link}
                  variant={"filled"}
                  to={`/representatives`}
                  label={"Sokak Temsilciler"}
                  leftSection={<TiUser size={17}/>}
                  h={40}
                  style={{ borderRadius: 3 }}
                  color={isBasic ? '#000000' : 'indigo'}
              />
          }

          {!isBasic &&
              <>
                <NavLink
                    mt={45}
                    variant={"filled"}
                    label={"Raporlar"}
                    leftSection={<RiHomeFill size={17}/>}
                    h={40}
                    style={{ borderRadius: 3 }}
                    rightSection={<IoChevronForward size={13} color={"#B2B2B2"}/>}
                    childrenOffset={28}
                    color={isBasic ? '#000000' : 'indigo'}
                >
                  <NavLink className={classes.subLink} variant={"filled"} component={Link} label={"Tümü"}
                           to={`${isBasic ? '/basic' : ''}/reports?type=all`}/>
                  <NavLink className={classes.subLink} variant={"filled"} component={Link} label={"Semtevine göre"}
                           to={`${isBasic ? '/basic' : ''}/reports?type=district`}/>
                  <Divider mt={20}/>

                </NavLink>
                <NavLink
                    variant={"filled"}
                    label={"Ayarlar"}
                    leftSection={<IoMdSettings size={17}/>}
                    h={40}
                    style={{ borderRadius: 3 }}
                    rightSection={<IoChevronForward size={13} color={"#B2B2B2"}/>}
                    childrenOffset={28}


                >
                  <NavLink className={classes.subLink} variant={"filled"} component={Link} label={"Semtevleri"}
                           to={`${isBasic ? '/basic' : ''}/district-home`}/>
                  <NavLink className={classes.subLink} variant={"filled"} component={Link} label={"Yüklenecek Evraklar"}
                           to={`${isBasic ? '/basic' : ''}/documents`}/>
                  <NavLink className={classes.subLink} variant={"filled"} component={Link} label={"Yardım Tipi/Kategorileri"}
                           to={`${isBasic ? '/basic' : ''}/help`}/>
                  <NavLink className={classes.subLink} variant={"filled"} component={Link} label={"Kullanıcılar"} to={`${isBasic ? '/basic' : ''}/users`}/>
                  <NavLink className={classes.subLink} variant={"filled"} component={Link} label={"Log kayıtları"} to={`${isBasic ? '/basic' : ''}/logs`}/>
                  <NavLink className={classes.subLink} variant={"filled"} component={Link} label={"Genel Ayarlar"}
                           to={`${isBasic ? '/basic' : ''}/settings`}/>
                  <Divider mt={20}/>

                </NavLink>
              </>
          }
        </Stack>
      </ScrollArea>
      <Box mx={-15} bg={'#fff'}  style={{zIndex:44}} pos={'relative'}>
        <Divider mb={12}/>
        <Box px={15} className={classes.user}>
          <Group>
            <Avatar
                src="https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-9.png"
                radius="xl"
            />

            <div style={{ flex: 1 }}>
              <Text size="sm" fw={500}>
                {user?.fullName || ''}
              </Text>
              <Text size="xs" fw={500}>
                {user?.email || ''}
              </Text>
            </div>

            <Menu shadow="md" width={200}>
              <Menu.Target>
                <ThemeIcon style={{ cursor: 'pointer' }} color={'#EBEBEB'} variant={"outline"}><HiOutlineDotsVertical
                    color={'#000000'} size={16}/></ThemeIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Menü</Menu.Label>
                <Menu.Item color={"red"} leftSection={<IoLogOutOutline/>} onClick={()=>{
                  AuthService.user().remove();
                  AuthService.jwt().token().remove();
                  dispatch(setUserInfo(null));
                  navigate('/login');
                }}>
                  Çıkış Yap
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Box>
      </Box>

    </AppShell.Navbar>
    <AppShell.Main  style={{zIndex:matches ? 0 :3,position:'relative'}} className={"content"} pt={matches ? 30 : 25}>
      {children}
    </AppShell.Main>
  </AppShell>)
}

export default Content;
