import {
  Box,
  Center, Divider,
  Group,
  NumberInput,
  Pagination,
  Select, Switch,
  Table,
  Text, Textarea,
  TextInput,
  UnstyledButton
} from '@mantine/core';
import { useDisclosure, useDocumentTitle, useMediaQuery } from '@mantine/hooks';
import { useState } from 'react';
import { IoEyeOutline, IoPencilOutline, IoTrashOutline } from 'react-icons/io5';
import { LiaStreetViewSolid } from 'react-icons/lia';
import { ThemeModal } from '../../components/Modal';
import TableTop from '../../components/Table/top';
import HeaderTitle from '../../components/Title';


export const Settings = () => {

  useDocumentTitle('Balçova Belediyesi - Anasayfa');
  const [opened, { open, close }] = useDisclosure(false);
  const matches = useMediaQuery('(max-width: 1200px)');

  const [data,setData] = useState([
    {id:1,name:'Sefa Z.',phone:'+90 538 273 8631',neighbourhood:'Fikirtepe Mh.',street:'Yıldırım Sk., Hızırbey Sk., Selimiye Sk.',tc:'34939434'},
    {id:2,name:'Alperen C..',phone:'+90 538 273 8631',neighbourhood:'Fikirtepe Mh.',street:'Yıldırım Sk., Hızırbey Sk., Selimiye Sk.',tc:'34939434'},
    {id:3,name:'Erdal B.',phone:'+90 538 273 8631',neighbourhood:'Fikirtepe Mh.',street:'Yıldırım Sk., Hızırbey Sk., Selimiye Sk.',tc:'34939434'},
    {id:4,name:'Ersin Y.',phone:'+90 538 273 8631',neighbourhood:'Fikirtepe Mh.',street:'Yıldırım Sk., Hızırbey Sk., Selimiye Sk.',tc:'34939434'},
    {id:5,name:'Yiğit C.',phone:'+90 538 273 8631',neighbourhood:'Fikirtepe Mh.',street:'Yıldırım Sk., Hızırbey Sk., Selimiye Sk.',tc:'34939434'},
  ]);

  const rows = data.map((element,index) => (
    <Table.Tr  key={`table-item-${index}`}>
      <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>{element.name}</Table.Td>
      <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.phone}</Table.Td>
      <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.neighbourhood}</Table.Td>
      <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.street}</Table.Td>
      <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.tc}</Table.Td>
      <Table.Td py={24} px={24}>
        <Group gap={4} justify={'end'}>
            <UnstyledButton w={40}>
              <IoEyeOutline size={19} color={"#475467"}/>
            </UnstyledButton>
          <UnstyledButton w={40}>
            <IoTrashOutline size={19} color={"#475467"}/>
          </UnstyledButton>
          <UnstyledButton w={40}>
            <IoPencilOutline size={19} color={"#475467"}/>
          </UnstyledButton>
        </Group>
      </Table.Td>
    </Table.Tr>
  ));

  return(<>
    <HeaderTitle
      title={"Genel Ayarlar"}
      description={"Site ayarlarını yönetin"}
      buttonText={"Kaydet"}
      ml={-20}
      className={"header-title"}
      onClick={() => console.log('')}
    />

    <Divider ml={-45} mr={-15} mt={20} color={'#EAECF0'}/>
    <Box ml={matches ? 0 : -20}>
      <Group className={"group-mb-disabled"} mt={22} align={'start'}>
        <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={350}>Site adı</Text>
        <Box flex={1}>
          <TextInput defaultValue={"Balçova belediyesi sosyal yardım başvuru ekranı"} maw={'100%'} w={530}  radius={8} styles={{ input:{ height:44, color:'#101828', fontWeight:400 } }}/>
        </Box>
      </Group>
      <Divider mb={16} color={"#EAECF0"} my={20}/>
      <Group className={"group-mb-disabled"} mt={22} align={'start'}>
        <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={350}>Header Etiketleri</Text>
        <Box flex={1}>
          <Textarea maw={'100%'}  defaultValue={'<script async src="https://www.googletagmanager.com/gtag/js?id=GA_MEASUREMENT_ID"></script><script>   window.dataLayer = window.dataLayer || [];   function gtag(){dataLayer.push(arguments);}   gtag(\'js\', new Date());    gtag(\'config\', \'GA_MEASUREMENT_ID\'); </script>'}
                     w={530}  radius={8} styles={{ input:{ height:153, color:'#101828', fontWeight:400 } }}/>
        </Box>
      </Group>
      <Divider mb={16} color={"#EAECF0"} my={20}/>
      <Group className={"group-mb-disabled"} mt={22} align={'start'}>
        <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={350}>Body Etiketleri</Text>
        <Box flex={1}>
          <Textarea maw={'100%'}  defaultValue={'<script async src="https://www.googletagmanager.com/gtag/js?id=GA_MEASUREMENT_ID"></script><script>   window.dataLayer = window.dataLayer || [];   function gtag(){dataLayer.push(arguments);}   gtag(\'js\', new Date());    gtag(\'config\', \'GA_MEASUREMENT_ID\'); </script>'}
                     w={530}  radius={8} styles={{ input:{ height:153, color:'#101828', fontWeight:400 } }}/>
        </Box>
      </Group>
      <Group className={"group-mb-disabled"} mt={22} align={'start'}>
        <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={350}>Meslek</Text>
        <Box flex={1}>
          <TextInput maw={'100%'} defaultValue={"Product Designer"} w={530}  radius={8} styles={{ input:{ height:44, color:'#101828', fontWeight:400 } }}/>
        </Box>
      </Group>
      <Group className={"group-mb-disabled"} mt={22} align={'start'}>
        <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={400}>Bakım modu</Text>
        <Box flex={1}>
          <Switch size={"xl"} defaultChecked color={"green"}/>
        </Box>
      </Group>
    </Box>

  </>)
}

export default Settings;
