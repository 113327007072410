import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { streetApi } from '../services/street.service';
import { socialApi } from '../services/social.service';
import { citizensApi } from '../services/citizens.service';
import { housesApi } from '../services/houses.service';
import {addressApi} from "../services/address.service";
import { authApi } from "../services/auth.service";
import {applicationsApi} from "../services/applications.service";



// Reducers
import userReducer from "./slices/user.slice";



export const store = configureStore({
    reducer: {
        user: userReducer,
        [authApi.reducerPath]: authApi.reducer,
        [streetApi.reducerPath]: streetApi.reducer,
        [socialApi.reducerPath]: socialApi.reducer,
        [citizensApi.reducerPath]: citizensApi.reducer,
        [housesApi.reducerPath]: housesApi.reducer,
        [addressApi.reducerPath]: addressApi.reducer,
        [applicationsApi.reducerPath]: applicationsApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat([authApi.middleware,streetApi.middleware,socialApi.middleware,citizensApi.middleware,housesApi.middleware,addressApi.middleware,applicationsApi.middleware])
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
