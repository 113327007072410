import {
  Box, Button,
  Center, Divider,
  Group, LoadingOverlay, MultiSelect,
  NumberInput,
  Pagination,
  Select,
  Table,
  Text,
  TextInput,
  UnstyledButton
} from '@mantine/core';
import {useDisclosure, useDocumentTitle} from '@mantine/hooks';
import React, {useEffect, useState} from 'react';
import {IoEyeOutline, IoPencilOutline, IoTrashOutline} from 'react-icons/io5';
import {LiaStreetViewSolid} from 'react-icons/lia';
import {ThemeModal} from '../../components/Modal';
import TableTop from '../../components/Table/top';
import HeaderTitle from '../../components/Title';
import {notifications} from "@mantine/notifications";
import {useAddStreetMutation, useDeleteStreetMutation, useGetStreetQuery} from "../../services/street.service";
import {modals} from "@mantine/modals";
import {useForm} from "@mantine/form";
import {
  useGetAddressCitiesQuery,
  useGetAddressCityNeighborhoodsQuery,
  useGetCityNeighborhoodIdQuery
} from "../../services/address.service";
import {citiesMap, neighbourhoodMap, streetMap} from "../../lib/helper";
import {Link} from "react-router-dom";


export const Representatives = () => {

  useDocumentTitle('Balçova Belediyesi - Başvurular');
  const [opened, {open, close}] = useDisclosure(false);
  const [activePage,setActivePage] = useState(1);

  const {data, isSuccess, isLoading} = useGetStreetQuery({page:activePage});
  const [deleteStreet, deleteStreetProps] = useDeleteStreetMutation({});
  const [addStreet, addStreetProps] = useAddStreetMutation({});

  const form = useForm({
    initialValues: {
      fullName: '',
      nationalityNumber: '',
      phoneNumber: '',
      cityId: '',
      address: {
        neighborhoodsId: '1',
        streetId: '1',
        daskCode: '1',
        buildingNo: '1',
        doorNo: '1',
      },
      note: '',
      neighborhoodsId: '',
      streetIds: [],
    },

    validate: {
      fullName: (value) => (value !== '' ? null : 'Ad Soyad girmelisiniz'),
      cityId: (value) => (value !== '' ? null : 'Şehir seçmelisiniz'),
      nationalityNumber: (value) => (value !== '' ? null : 'Tc No girmelisiniz'),
      phoneNumber: (value) => (value !== '' ? null : 'Telefon No girmelisiniz'),
      neighborhoodsId: (value) => (value !== '' ? null : 'Mahalle seçmelisiniz'),
      streetIds: (value) => (value.length > 0 ? null : 'En az 1 tane Sokak seçmelisiniz'),
    },
  });

  const cities = useGetAddressCitiesQuery({});
  const neighbourhoods = useGetAddressCityNeighborhoodsQuery({cityId: form.values.cityId});
  const streets = useGetCityNeighborhoodIdQuery({
    neighborhoodsId: form.values.neighborhoodsId,
    cityId: form.values.cityId
  });


  useEffect(() => {
    if (deleteStreetProps.isError) {
      notifications.show({
        color: 'red',
        title: 'Hata',
        //@ts-ignore
        message: deleteStreetProps?.error?.data?.message ?? '',
        autoClose: 2000,
      })
    }
  }, [deleteStreetProps.isError]);
  useEffect(() => {
    if (deleteStreetProps.isSuccess) {
      notifications.show({
        color: 'teal',
        title: 'Başarılı',
        message: 'Sokak Temsilcisi Silindi',
        autoClose: 2000,
      })
    }
  }, [deleteStreetProps.isSuccess]);




  useEffect(() => {
    if (addStreetProps.isError) {
      notifications.show({
        color: 'red',
        title: 'Hata',
        //@ts-ignore
        message: addStreetProps?.error?.data?.message ?? 'Bilnmeyen hata',
        autoClose: 2000,
      });
    }
  }, [addStreetProps.isError]);

  useEffect(() => {
    if (addStreetProps.isSuccess) {
      close();
      notifications.show({
        color: 'teal',
        title: 'Başarılı',
        message: 'Sokak Temsilcisi Eklendi',
        autoClose: 2000,
      });
      setTimeout(()=>{
        form.setValues({
          fullName: '',
          nationalityNumber: '',
          phoneNumber: '',
          cityId: '',
          address: {
            neighborhoodsId: '1',
            streetId: '1',
            daskCode: '1',
            buildingNo: '1',
            doorNo: '1',
          },
          note: '',
          neighborhoodsId: '',
          streetIds: [],
        })
      },250);
    }
  }, [addStreetProps.isSuccess]);


  const rows = isSuccess ? data?.items.map((element, index) => (
      <Table.Tr key={`table-item-${index}`}>
        <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>{element.fullName}</Table.Td>
        <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.phoneNumber}</Table.Td>
        <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element?.address?.neighborhood?.name || ''}</Table.Td>
        <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element?.address?.street?.name || ''}</Table.Td>
        <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.nationalityNumber}</Table.Td>
        <Table.Td py={24} px={24}>
          <Group gap={4} justify={'end'}>
            <UnstyledButton w={40} component={Link} to={`/street/${element.id}`}>
              <IoEyeOutline size={19} color={"#475467"}/>
            </UnstyledButton>
            <UnstyledButton w={40} onClick={() => {
              modals.openConfirmModal({
                centered: true,
                title: 'Temsilciyi Sil',
                children: (
                    <Text size="sm">
                      Temsilciyi silmek istediğinize emin misiniz?
                    </Text>
                ),
                labels: {confirm: 'Temsilciyi Sil', cancel: "Geri Dön"},
                confirmProps: {color: 'red'},
                onCancel: () => console.log('Cancel'),
                onConfirm: () => deleteStreet({id: String(element.id)}),
              });
            }}>
              <IoTrashOutline size={19} color={"#475467"}/>
            </UnstyledButton>
            <UnstyledButton w={40}>
              <IoPencilOutline size={19} color={"#475467"}/>
            </UnstyledButton>
          </Group>
        </Table.Td>
      </Table.Tr>
  )) : [];

  return (<>
    <HeaderTitle
        title={"Sokak Temsilcileri"}
        count={`${isSuccess ? data?.totalItems || 0 : 0} temsilci`}
        description={"Sokak temsilcilerini yönetin, yeni bir sokak temsilcisi ekleyin."}
        buttonText={"Yeni Sokak Temsilcisi Ekle"}
        ml={-20}
        onClick={open}
        className={"header-title"}
    />

    <ThemeModal
        opened={opened}
        onClose={close}
        icon={<LiaStreetViewSolid size={25} color={"#7F56D9"}/>}
        title={"Yeni Sokak Temsilcisi Ekle"}
        description={"Yeni bir sokak temsilcisi eklemek için tüm alanları doldurun."}>
      <form onSubmit={form.onSubmit((values) => {
        let _values = values;
        //@ts-ignore
        addStreet({data: values})
      })}>

        <Group className={"group-mb-disabled"} mb={16} align={'start'}>
          <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Ad Soyad:*</Text>
          <Box flex={1}>
            <TextInput
                radius={8}
                styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                key={form.key('fullName')}
                {...form.getInputProps('fullName')}
            />
          </Box>
        </Group>
        <Group className={"group-mb-disabled"} mb={16} align={'start'}>
          <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>TC Kimlik Numarası:</Text>
          <Box flex={1}>
            <TextInput radius={8} styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                       key={form.key('nationalityNumber')}
                       {...form.getInputProps('nationalityNumber')}
            />
          </Box>
        </Group>
        <Group className={"group-mb-disabled"} mb={16} align={'start'}>
          <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Telefon:</Text>
          <Box flex={1}>
            <TextInput  radius={8}
                        styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                        key={form.key('phoneNumber')}
                        {...form.getInputProps('phoneNumber')}
            />
          </Box>
        </Group>
        <Group className={"group-mb-disabled"} mb={16} align={'start'}>
          <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Memleket(İl)*</Text>
          <Box flex={1}>
            <Select
                data={cities.isSuccess ? citiesMap(cities?.data || []) : []}
                allowDeselect={false}
                defaultValue={''}
                radius={8}
                searchable
                styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                key={form.key('cityId')}
                {...form.getInputProps('cityId')}
            />
          </Box>
        </Group>
        <Group className={"group-mb-disabled"} mb={16} align={'start'}>
          <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Adres:</Text>
          <Box flex={1}>
            <TextInput radius={8} styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}

            />
          </Box>
        </Group>
        <Group className={"group-mb-disabled"} mb={16} align={'start'}>
          <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Not:</Text>
          <Box flex={1}>
            <TextInput radius={8} styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                       key={form.key('note')}
                       {...form.getInputProps('note')}
            />
          </Box>
        </Group>
        <Group className={"group-mb-disabled"} mb={30} align={'start'}>
          <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Mahalle*:</Text>
          <Box flex={1}>
            <Select data={cities.isSuccess ? neighbourhoodMap(neighbourhoods?.data || []) : []}
                    allowDeselect={false} defaultValue={''} radius={8}
                    styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                    key={form.key('neighborhoodsId')}
                    {...form.getInputProps('neighborhoodsId')}
                    searchable
            />
            <Text fz={13} mt={8} fw={400} c={'#475467'} lh={'20px'}>Temsilciyi mahalle ile
              ilişkilendirin.</Text>
          </Box>
        </Group>
        <Group className={"group-mb-disabled"} align={'start'}>
          <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Sokak*:</Text>
          <Box flex={1}>
            <MultiSelect
                data={streets.isSuccess ? streetMap(streets?.data || []) : []}
                defaultValue={''} radius={8}
                styles={{input: {height: 44, color: '#667085', fontWeight: 400}}}
                key={form.key('streetIds')}
                {...form.getInputProps('streetIds')}
            />
            <Text mt={8} fz={13} fw={400} c={'#475467'} lh={'20px'}>Temsilci için birden fazla sokak seçimi
              yapın.</Text>
          </Box>
        </Group>
        <Box mx={-20}>
          <Divider mt={20} color={'#EAECF0'}/>
          <Group grow gap={12} p={24} pb={0}>
            <Button onClick={() => close()} style={{borderColor: '#D0D5DD'}} variant={"outline"} h={44}
                    fz={16} c={'#344054'} fw={600} radius={8}>İptal Et</Button>
            <Button loading={addStreetProps.isLoading} type={'submit'} h={44} fz={16} c={'#fff'} fw={600} radius={8}>Ekle</Button>
          </Group>
        </Box>
      </form>
    </ThemeModal>

    <Table className={"responsive-table"} mt={20} ml={-46} w={'calc(100% + 62px)'} striped>
      <TableTop
          titles={["Temsilci Adı Soyadı", "Telefon", "Mahalle", "Sokak", "TC", ""]}
      />
      <LoadingOverlay visible={isLoading}/>
      <Table.Tbody>{rows}</Table.Tbody>

    </Table>



    <Center h={68} bg={'#fff'} pos={'fixed'} left={0} bottom={0} w={'100%'}
            style={{borderTop: '1px solid #EAECF0'}}>
      <Pagination
          color={"#f9fafb"}
          total={Number(data?.totalPages || 0)}
          withControls={false}
          onChange={setActivePage}
          styles={{
            control: {
              color: '#475467',
              fontSize: 14,
              border: 'none'
            },
            dots: {
              fontSize: 14,
              color: '#475467',

            }
          }}
      />
    </Center>

  </>)
}

export default Representatives;
