import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../lib/axiosBaseQuery";
import { setUserInfo } from "../store/slices/user.slice";
import {AccountBody, LoginRequestBody, Token, User, UserAccounts, UserProps} from "./types/auth";
import * as Service from "../services/system";
import {ApiResponse} from "./types/types";

const AuthService = Service.Auth.init();
const jwt = AuthService.jwt();

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Me","Accounts"],
  endpoints: (builder) => ({
    login: builder.mutation<{code:number;user:UserProps;token:string}, LoginRequestBody>({
      query: ({ email, password }) => ({
        url: `/accounts/login`,
        method: "POST",
        data: { email, password }
      }),
      transformResponse: (result: { code: number; user:UserProps;token:string }) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;


          jwt.token().set(data.token);
          jwt.refresh_token().set(data.token);

          // AuthService.user().set()

          AuthService.user().set(JSON.stringify(data.user));
          await dispatch(setUserInfo(data.user));


        //  await dispatch(authApi.endpoints.me.initiate(null));

        } catch (error) {
        }
      }
    }),

    me: builder.query<User, any>({
      query: () => ({ url: `/auth/me`, method: "GET", includeToken: true }),
      transformResponse: (result: { success: boolean; payload: User }) => result.payload,
      providesTags: (result) =>
        result
          ? [{ type: "Me", id: "ME_PROFILE" }
          ]
          : [{ type: "Me", id: "ME_PROFILE" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {

        try {
          const { data } = await queryFulfilled;

          await dispatch(setUserInfo(data.user));

        } catch (error) {
        }
      }
    }),

    getAccounts: builder.query<ApiResponse<UserAccounts>, {page:any}>({
      query: ({page}) => ({ url: `/accounts?page=${page}`, method: "GET", includeToken: true }),
      transformResponse: (result:ApiResponse<UserAccounts>) => result,
      providesTags: (result) =>
          result
              ? [{ type: "Accounts", id: "ACCOUNTS" }
              ]
              : [{ type: "Accounts", id: "ACCOUNTS" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {

        try {
          const { data } = await queryFulfilled;


        } catch (error) {
        }
      }
    }),

    createAccount: builder.mutation<any, AccountBody>({
      query: (values) => ({
        url: `/accounts`,
        method: "POST",
        data: values,
        includeToken: true
      }),
      invalidatesTags: [{type: 'Accounts', id: 'ACCOUNTS'}],
      transformResponse: (result: any) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {
        }
      }
    }),

    updateAccount: builder.mutation<any, {id:string,values:AccountBody}>({
      query: ({id,values}) => ({
        url: `/accounts/${id}`,
        method: "PUT",
        data: values,
        includeToken: true
      }),
      invalidatesTags: [{type: 'Accounts', id: 'ACCOUNTS'}],
      transformResponse: (result: any) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {
        }
      }
    }),

    deleteAccount: builder.mutation<any, {id:string}>({
      query: ({id}) => ({
        url: `/accounts/${id}`,
        method: "DELETE",
        includeToken: true
      }),
      invalidatesTags: [{type: 'Accounts', id: 'ACCOUNTS'}],
      transformResponse: (result: any) => result,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
        } catch (error) {
        }
      }
    }),


  })
});

export const {
  useMeQuery,
    useLoginMutation,
    useGetAccountsQuery,
    useCreateAccountMutation,
    useDeleteAccountMutation,
    useUpdateAccountMutation
} = authApi;
