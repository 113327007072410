import {createApi} from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../lib/axiosBaseQuery";
import {CitizenPassProps, CitizensAddProps, CitizensProps} from "./types/citizens";
import {ApiResponse} from "./types/types";

export const citizensApi = createApi({
    reducerPath: "citizensApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["Citizens"],
    endpoints: (builder) => ({


        getCitizens: builder.query<ApiResponse<CitizensProps>, {page:any}>({
            query: ({page}) => ({
                url: `/citizens?page=${page}`,
                method: "GET",
                includeToken: true,
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.items.map(({id}: any) => ({
                            type: "Citizens" as const,
                            id
                        })),
                        {type: "Citizens", id: "CITIZENS_LIST"},
                    ]
                    : [{type: "Citizens", id: "CITIZENS_LIST"}],
            transformResponse: (result: ApiResponse<CitizensProps> ) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


        oneCitizens: builder.query<CitizensProps, {id:string}>({
            query: ({id}) => ({
                url: `/citizens/${id}`,
                method: "GET",
                includeToken: true,
            }),
            transformResponse: (result: CitizensProps ) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


        addCitizens: builder.mutation<any, { data: CitizensAddProps }>({
            query: ({data}) => ({
                url: `/citizens`,
                method: "POST",
                data: data,
                includeToken: true
            }),
            invalidatesTags: [{type: 'Citizens', id: 'CITIZENS_LIST'}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),



        getCitizensSearch: builder.mutation<any, {search:string}>({
            query: ({search}) => ({
                url: `/citizens/search?q=${search}`,
                method: "GET",
                includeToken: true,
            }),
            transformResponse: (result: CitizensProps) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


        getCitizensPass: builder.query<ApiResponse<CitizenPassProps>, {id:string,page:any}>({
            query: ({id,page}) => ({
                url: `/applications/citizen/${id}?page=${page}`,
                method: "GET",
                includeToken: true,
            }),
            transformResponse: (result: ApiResponse<CitizenPassProps>) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),




    })
});

export const {
        useGetCitizensQuery,
    useOneCitizensQuery,
    useAddCitizensMutation,
    useGetCitizensPassQuery,
    useGetCitizensSearchMutation
} = citizensApi;
