import { Badge, Box, Button, ButtonProps, Group, GroupProps, Text } from '@mantine/core';
import React from 'react';

interface Props extends GroupProps{
  count?: string;
  title: string;
  description?: string;
  buttonText?:string;
  buttonColor?:string;
  onClick?:() => void;
  extraComponent?:React.ReactNode
}

export const HeaderTitle = ({ count, title, description,buttonText,onClick,extraComponent,buttonColor,...props }: Props) => {
  return (<Group justify={'center'} gap={0} {...props}>
   <Box flex={1}>
     <Group gap={10}>
       <Text fz={18} c={'#101828'} fw={600} lh={'28px'}>{title}</Text>
       {count &&
         <Badge tt={'initial'} fw={500} variant={"light"} radius={"xl"}>{count}</Badge>
       }
     </Group>
     {description &&
       <Text mt={3} fz={14} fw={400} lh={"20px"} c={'#475467'}>{description}</Text>
     }
   </Box>
    {extraComponent}
    {buttonText &&
      <Button color={buttonColor} onClick={() => onClick && onClick()} h={40} radius={8} fz={14} c={'#fff'} fw={600}>{buttonText}</Button>
    }
  </Group>)
}

export default HeaderTitle;
