import { createTheme, MantineProvider } from "@mantine/core";
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from "@mantine/notifications";
import React from 'react';
import { Provider } from 'react-redux';
import Content from './components/Content';
import Router from "./routes";
import '@mantine/core/styles.css';
import '@mantine/charts/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import { store } from './store';
import "moment/locale/tr";

const theme = createTheme({
  fontFamily: "'Inter', sans-serif",

  headings: {
    fontFamily: "'Inter', sans-serif",
  },
  black:'#34393F',
  primaryColor: 'indigo',
  colors:{
    'indigo': ['#7F56D9', '#7F56D9', '#7F56D9', '#7F56D9', '#7F56D9', '#7F56D9', '#7F56D9', '#7F56D9', '#7F56D9', '#7F56D9']
  },

});


function App() {

  return (
    <Provider store={store}>
      <MantineProvider theme={theme}>
        <Notifications/>
        <ModalsProvider>
          <Content>
            <Router />
          </Content>
        </ModalsProvider>
      </MantineProvider>
    </Provider>
  );
}

export default App;
