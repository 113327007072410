import {
    Box, Button,
    Center,
    Group,
    NumberInput,
    Pagination,
    Select,
    Table, LoadingOverlay,
    Text,
    TextInput,
    UnstyledButton
} from '@mantine/core';
import {useDisclosure, useDocumentTitle, useMediaQuery} from '@mantine/hooks';
import React, {useState} from 'react';
import {IoArrowForward, IoEyeOutline, IoPencilOutline, IoTrashOutline} from 'react-icons/io5';
import {LiaStreetViewSolid} from 'react-icons/lia';
import {Link} from 'react-router-dom';
import TableTop from '../../components/Table/top';
import HeaderTitle from '../../components/Title';
import {useGetCitizensQuery} from "../../services/citizens.service";
import moment from "moment";


export const Citizens = () => {

    useDocumentTitle('Balçova Belediyesi - Vatandaşlar');
    const [activePage, setActivePage] = useState(1);
    const {data, isSuccess, isLoading,isFetching} = useGetCitizensQuery({page:activePage});


    const rows = isSuccess ? data?.items.map((element, index) => (
        <Table.Tr key={`table-item-${index}`}>
            <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>{element.fullName}</Table.Td>
            <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.nationalityNumber}</Table.Td>
            <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.phoneNumber}</Table.Td>
            <Table.Td py={24} fz={14} fw={400} c={'#475467'}
                      px={24}>{element?.address?.city?.name || ''}  {element?.address?.neighborhood?.name || ''} {element?.address?.street?.name || ''}</Table.Td>
            <Table.Td py={24} fz={14} fw={400} c={'#475467'}
                      px={24}>{moment(element.createdOn).format('DD.MM.YYYY - HH:mm')}</Table.Td>
            <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
                <Button rightSection={<IoArrowForward size={14} color={"#fff"}/>} size={"xs"} component={Link}
                        to={`/citizen/${element.id}`}>Detayı Gör</Button>
            </Table.Td>
        </Table.Tr>
    )) : [];

    return (<>


        <HeaderTitle
            title={"Vatandaşlar"}
            count={`${isSuccess ? data?.totalItems || 0 : 0} vatandaş kaydı`}
            description={"Tüm vatandaşları görüntüleyin"}
            ml={-20}
            mb={16}
            className={"header-title"}
        />

        <Table className={"responsive-table"} ml={-46} w={'calc(100% + 62px)'} striped style={{
            borderTop: '12px solid #F7F7F7'
        }}>
            <LoadingOverlay visible={isFetching}/>
            <TableTop
                styles={{
                    thead: {
                        backgroundColor: '#F9FAFB',
                    }
                }}
                titles={["Başvuran", "TC Kimlik No", "Telefon", "Adres", "Son Başvuru Tarihi", ""]}
            />
            <Table.Tbody>{rows}</Table.Tbody>

        </Table>

        <Center h={68} bg={'#fff'} pos={'fixed'} left={0} bottom={0} w={'100%'}
                style={{borderTop: '1px solid #EAECF0'}}>
            <Pagination
                color={"#f9fafb"}
                total={Number(data?.totalPages || 0)}
                withControls={false}
                onChange={setActivePage}
                styles={{
                    control: {
                        color: '#475467',
                        fontSize: 14,
                        border: 'none'
                    },
                    dots: {
                        fontSize: 14,
                        color: '#475467',

                    }
                }}
            />
        </Center>

    </>)
}

export default Citizens;
