import {createApi} from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../lib/axiosBaseQuery";
import {
    StreetProps
} from '../services/types/street';
import {ApiResponse} from "./types/types";

export const streetApi = createApi({
    reducerPath: "streetApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["Street"],
    endpoints: (builder) => ({


        getStreet: builder.query<ApiResponse<StreetProps>, {page:any}>({
            query: ({page}) => ({
                url: `/sokak-temsilcileri`,
                method: "GET",
                includeToken: true,
                params:{page}
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.items.map(({id}: any) => ({
                            type: "Street" as const,
                            id
                        })),
                        {type: "Street", id: "STREET_LIST"},
                    ]
                    : [{type: "Street", id: "STREET_LIST"}],
            transformResponse: (result: ApiResponse<StreetProps>) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


        getStreetDetail: builder.query<any, {id:string}>({
            query: ({id}) => ({
                url: `/sokak-temsilcileri/${id}`,
                method: "GET",
                includeToken: true,
            }),
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


        addStreet: builder.mutation<any, { data: StreetProps }>({
            query: ({data}) => ({
                url: `/sokak-temsilcileri`,
                method: "POST",
                data: data,
                includeToken: true
            }),
            invalidatesTags: [{type: 'Street', id: 'STREET_LIST'}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


        deleteStreet: builder.mutation<any, {
            id: string
        }>({
            query: ({id}) => ({
                url: `/sokak-temsilcileri/${id}`,
                method: "DELETE",
                includeToken: true
            }),
            invalidatesTags: [{type: 'Street', id: 'STREET_LIST'}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


    })
});

export const {
    useAddStreetMutation,
    useDeleteStreetMutation,
    useGetStreetQuery,
    useGetStreetDetailQuery

} = streetApi;
