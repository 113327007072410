import {createApi} from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../lib/axiosBaseQuery";
import {HousesProps} from "./types/houses";
import {ApiResponse} from "./types/types";

export const housesApi = createApi({
    reducerPath: "housesApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["Houses"],
    endpoints: (builder) => ({


        getHouses: builder.query<ApiResponse<HousesProps>, {page:any}>({
            query: ({page}) => ({
                url: `/semt-evleri?page=${page}`,
                method: "GET",
                includeToken: true,
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.items.map(({id}: any) => ({
                            type: "Houses" as const,
                            id
                        })),
                        {type: "Houses", id: "HOUSES_LIST"},
                    ]
                    : [{type: "Houses", id: "HOUSES_LIST"}],
            transformResponse: (result: ApiResponse<HousesProps> ) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),



        getHouseDetail: builder.query<any, {id:string}>({
            query: ({id}) => ({
                url: `/semt-evleri/${id}`,
                method: "GET",
                includeToken: true,
            }),
            transformResponse: (result: any ) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),

        addHouses: builder.mutation<any, HousesProps>({
            query: (values) => ({
                url: `/semt-evleri`,
                method: "POST",
                data: values,
                includeToken: true
            }),
            invalidatesTags: [{type: 'Houses', id: 'HOUSES_LIST'}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),



        deleteHouses: builder.mutation<any, {
            id: string
        }>({
            query: ({id}) => ({
                url: `/semt-evleri/${id}`,
                method: "DELETE",
                includeToken: true
            }),
            invalidatesTags: [{type: 'Houses', id: 'HOUSES_LIST'}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),



    })
});

export const {
useAddHousesMutation,
    useGetHousesQuery,
    useDeleteHousesMutation,
    useGetHouseDetailQuery
} = housesApi;
