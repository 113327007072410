import {
    Anchor,
    Box,
    Button,
    Center,
    Checkbox,
    Image,
    Group,
    Paper,
    PasswordInput,
    Text,
    TextInput,
    Title
} from '@mantine/core';
import {useForm} from '@mantine/form';
import {useDocumentTitle} from '@mantine/hooks';
import {notifications} from '@mantine/notifications';
import {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useLoginMutation} from '../../../services/auth.service';

interface LoginProps {
    email: string,
    password: string,
    isRemember: boolean,
}

export const Login = () => {

    useDocumentTitle('Balçova Admin | Login');


    const [login, loginProps] = useLoginMutation();

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();


    const form = useForm({
        initialValues: {
            email: 'ersinayaz@gmail.com',
            password: '123456',
            isRemember: false,
        },
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Email adresi geçersiz'),
            password: (value) => (value !== '' ? null : 'Şifre girmelisiniz'),
        },
    });


    useEffect(() => {
        if (loginProps.isError) {
            setLoading(false);
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: loginProps?.error?.data?.message ?? '',
                autoClose: 2000,
            })
        }
    }, [loginProps.isError]);

    useEffect(() => {
        if (loginProps.isSuccess) {

            notifications.show({
                color: 'teal',
                title: 'Başarılı',
                message: 'Giriş Yapıldı',
                autoClose: 2000,
            });
            navigate(loginProps.data.user.role === 3 ? '/' : '/dashboard', {replace: true});

        }
    }, [loginProps.isSuccess]);


    return (<Box h={'100svh'} w={'100%'} bg={'#fcfcfc'} display={'flex'}
                 style={{justifyContent: 'center', alignItems: 'center'}}>

            <div>
                <Center>
                    <Image src={'/images/logo.png'} w={150}/>
                </Center>
                <Text mb={20} mt={5} ta={'center'} fz={"sm"} c={'dimmed'}>Bilgilerinizi girerek giriş yapabilirsiniz</Text>

                <Paper w={420} maw={'100%'} withBorder shadow="md" p={30} radius="md">
                    <form onSubmit={form.onSubmit((values) => {
                        setLoading(true);
                        login({
                            email: values.email,
                            password: values.password,
                        });
                    })}>

                        <TextInput label="Email Adresi" placeholder="placeholder@mail.com"
                                   {...form.getInputProps('email')}
                        />
                        <PasswordInput label="Şifre" placeholder="Şifreniz" mt="md"
                                       {...form.getInputProps('password')}
                        />
                        <Group justify="space-between" mt="lg">
                            <Checkbox label="Beni Hatırla"/>
                            <Anchor component={Link} to={'/forgot'} c={"indigo"} size="sm">
                                Şifremi Unuttum?
                            </Anchor>
                        </Group>
                        <Button
                            type={"submit"}
                            fullWidth
                            mt="xl"
                            loading={loading}
                        >
                            Giriş Yap
                        </Button>
                    </form>
                </Paper>
            </div>
        </Box>
    )
}

export default Login;
