import type {PayloadAction} from "@reduxjs/toolkit";
import {createSlice} from "@reduxjs/toolkit";
import {User, UserProps} from "../../services/types/auth";



export interface UserState {
    info: UserProps | null;
    page:number;
}

const initialState: UserState = {
    info: null,
    page:1
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserInfo: (state, action: PayloadAction<UserProps|null>) => {
            state.info = action.payload;
        },
        setPage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        resetAuth: state => {
            return initialState;
        }
    }
});

export const {setUserInfo,setPage, resetAuth} = userSlice.actions;

export default userSlice.reducer;
