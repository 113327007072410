import {
    Box, Button,
    Center, Divider, Grid,
    Group, List, LoadingOverlay, MultiSelect,
    NumberInput,
    Pagination,
    Select, Stack,
    Table,
    Text, Textarea,
    TextInput,
    UnstyledButton
} from '@mantine/core';
import {useDisclosure, useDocumentTitle} from '@mantine/hooks';
import React, {useEffect, useState} from 'react';
import {IoArrowBack, IoEyeOutline, IoPencilOutline, IoTrashOutline} from 'react-icons/io5';
import {LiaStreetViewSolid} from 'react-icons/lia';
import {ThemeModal} from '../../components/Modal';
import TableTop from '../../components/Table/top';
import HeaderTitle from '../../components/Title';
import {notifications} from "@mantine/notifications";
import {
    useAddStreetMutation,
    useDeleteStreetMutation,
    useGetStreetDetailQuery,
    useGetStreetQuery
} from "../../services/street.service";
import {modals} from "@mantine/modals";
import {useForm} from "@mantine/form";
import {
    useGetAddressCitiesQuery,
    useGetAddressCityNeighborhoodsQuery,
    useGetCityNeighborhoodIdQuery
} from "../../services/address.service";
import {citiesMap, neighbourhoodMap, streetMap} from "../../lib/helper";
import {Link, useParams} from "react-router-dom";
import moment from "moment";


export const StreetDetail = () => {

    useDocumentTitle('Balçova Belediyesi - Sokak Temsilci Detay');

    const { id } = useParams<{ id: string }>();
    const [opened, {open, close}] = useDisclosure(false);
    const {data, isSuccess, isLoading} = useGetStreetDetailQuery({id:String(id)});
    const [note,setNote] = useState('');

    useEffect(() => {

        if (isSuccess){
            setNote(data.note);
        }

    }, [isSuccess]);

    return (<>
        <HeaderTitle
            title={isSuccess ? data.fullName : ''}
            description={"Sokak Temsilcisi"}
            ml={-20}
            onClick={open}
            className={"header-title"}
            extraComponent={<Button component={Link} to={'/'} color={'#9F9F9F'} mr={10} h={40} leftSection={<IoArrowBack/>} fz={14} fw={600} radius={8}>Geri
                Git</Button>}
        />

        <LoadingOverlay visible={isLoading}/>

        <Divider ml={-47} mr={-15} mt={20}/>


        {isSuccess &&
          <Grid>
            <Grid.Col span={{lg:5}}>
              <Text fz={18} c={'#000000'} lh={'24px'} fw={700} mt={35} mb={30}>Bilgileri</Text>
              <List listStyleType={'none'}>
                <List.Item>
                  <Group mb={13}>
                    <Text fz={18} fw={500} c={'#5A5C65'} w={180}>Ad Soyad:</Text>
                    <Text fz={18} c={'#000000'} fw={500}>{data.fullName}</Text>
                  </Group>
                  <Group mb={13}>
                    <Text fz={18} fw={500} c={'#5A5C65'} w={180}>Telefon:</Text>
                    <Text fz={18} c={'#000000'} fw={500}>{data.phoneNumber}</Text>
                  </Group>
                  <Group mb={13}>
                    <Text fz={18} fw={500} c={'#5A5C65'} w={180}>TC Kimlik Numarası:</Text>
                    <Text fz={18} c={'#000000'} fw={500}>{data.nationalityNumber}</Text>
                  </Group>
                  <Group mb={13}>
                    <Text fz={18} fw={500} c={'#5A5C65'} w={180}>Memleket:</Text>
                    <Text fz={18} c={'#000000'} fw={500}>{data?.city?.name || ''}</Text>
                  </Group>
                  <Group mb={13}>
                    <Text fz={18} fw={500} c={'#5A5C65'} w={180}>Mahalle:</Text>
                    <Text fz={18} c={'#000000'} fw={500}>{data?.address?.neighborhood?.name || 'sd'}</Text>
                  </Group>
                  <Group mb={13}>
                    <Text fz={18} fw={500} c={'#5A5C65'} w={180}>Sokak:</Text>
                    <Text fz={18} c={'#000000'} fw={500}>{data?.address?.street?.name || 'sd'}</Text>
                  </Group>
                  <Group>
                    <Text fz={18} fw={500} c={'#5A5C65'} w={180}>Adres</Text>
                    <Text fz={18} c={'#000000'} fw={500}></Text>
                  </Group>
                </List.Item>
              </List>
            </Grid.Col>
            <Grid.Col pos={'relative'} span={{lg:7}}>
              <Divider pos={'absolute'} orientation={'vertical'} h={'calc(100vh - 160px)'}/>

              <Box  p={80}>
                <Text fw={700} fz={18} c={'#000'} lh={'24px'} mb={27}>Not:</Text>
                <Textarea placeholder={'Not...'} styles={{
                    input:{
                        minHeight:92,
                        fontSize:16,
                        color:'#101828',
                        paddingTop:15,
                        paddingLeft:15,
                        borderRadius:0
                    }
                }} defaultValue={note} onChange={(e) => {
                    setNote(e.currentTarget.value);
                }} />
                <Group mt={16} justify={'right'}>
                  <Button radius={8} h={40} fz={14} fw={600} c={'#fff'} >Notu Kaydet</Button>
                </Group>
              </Box>
              <Divider/>

              <Box flex={1} py={50} px={80}>
                <Text fw={700} fz={18} c={'#000'} lh={'24px'} mb={27}>Önceki notlar:</Text>
                <Stack>
                  <Group>
                    <Box flex={1} px={18} py={21}  bg={'rgba(159, 159, 159, 0.1)'}>
                      <Text fz={16} fw={400}>İletişime geçildi ancak bir dönüş yok.</Text>
                    </Box>
                    <Text ml={10}>{moment(data.created_at).format('DD.MM.YYYY')}</Text>
                  </Group>
                  <Group>
                    <Box flex={1} px={18} py={21}  bg={'rgba(159, 159, 159, 0.1)'}>
                      <Text fz={16} fw={400}>İletişime geçildi ancak bir dönüş yok.</Text>
                    </Box>
                    <Text ml={10}>{moment(data.created_at).format('DD.MM.YYYY')}</Text>
                  </Group>
                  <Group>
                    <Box flex={1} px={18} py={21}  bg={'rgba(159, 159, 159, 0.1)'}>
                      <Text fz={16} fw={400}>İletişime geçildi ancak bir dönüş yok.</Text>
                    </Box>
                    <Text ml={10}>{moment(data.created_at).format('DD.MM.YYYY')}</Text>
                  </Group>

                </Stack>
              </Box>


            </Grid.Col>
          </Grid>
        }


    </>)
}

export default StreetDetail;
