import {
    Anchor,
    Badge,
    Box, Button,
    Center,
    Group, LoadingOverlay,
    NumberInput,
    Pagination,
    Select,
    Table, Tabs,
    Text,
    TextInput,
    UnstyledButton
} from '@mantine/core';
import {useDisclosure, useDocumentTitle, useMediaQuery} from '@mantine/hooks';
import React, {useEffect, useState} from 'react';
import {AiOutlineLoading3Quarters} from 'react-icons/ai';
import {BsFileEarmarkExcelFill} from 'react-icons/bs';
import {FaFileExcel} from 'react-icons/fa6';
import {IoArrowForward, IoClose, IoEyeOutline, IoPencilOutline, IoSearch, IoTrashOutline} from 'react-icons/io5';
import {LiaStreetViewSolid} from 'react-icons/lia';
import {MdCheck} from 'react-icons/md';
import {Link, useNavigate} from 'react-router-dom';
import HeaderTitle from '../../components/Title';
import TableTop from '../../components/Table/top';
import {
    useAddApplicationMutation,
    useGetApplicationExportMutation,
    useGetApplicationsQuery
} from "../../services/applications.service";
import {notifications} from "@mantine/notifications";
import moment from "moment";

import * as Service from "../../services/system";
import { saveAs } from "file-saver";

const AuthService = Service.Auth.init();
const jwt = AuthService.jwt();


export const Applications = () => {

    useDocumentTitle('Balçova Belediyesi - Başvurular');

    const [search,setSearch]=useState('');
    const [status,setStatus] = useState<any>(0);
    const [activePage, setActivePage] = useState(1);
    const {data, isSuccess, isError, isLoading, isFetching} =
        useGetApplicationsQuery({page:activePage,status,q:search});
    const [addApplication, addApplicationProps] = useAddApplicationMutation({});
    const matches = useMediaQuery('(max-width: 1200px)');

    const [applicationExport,applicationExportProps] = useGetApplicationExportMutation({});

    //@ts-ignore
    const user =   JSON.parse(AuthService.user().get());

    const navigate = useNavigate();

    const statusType = (status: number) => {
        let _status = {color: '', text: '', icon: <Box/>}

        switch (status) {
            case 1:
                _status = {
                    color: '#FFA500',
                    text: 'Bekliyor',
                    icon: <AiOutlineLoading3Quarters color={"#fff"}/>
                }
                break;
            case 5:
                _status = {
                    color: '#DC3545',
                    text: 'RED',
                    icon: <IoClose size={15} color={"#fff"}/>
                }
                break;
            case 4:
                _status = {
                    color: '#DC3545',
                    text: 'ONAYLANMADI',
                    icon: <IoClose size={15} color={"#fff"}/>
                }
                break;

            case 2:
                _status = {
                    color: '#28A745',
                    text: 'ONAYLANDI',
                    icon: <MdCheck size={15} color={"#fff"}/>

                }
                break;

            case 3:
                _status = {
                    color: '#28A745',
                    text: 'TAMAMLANDI',
                    icon: <MdCheck size={15} color={"#fff"}/>

                }
                break;
        }

        return _status;
    }

    const rows = isSuccess ? data.items.map((element, index) => {

        let icon = statusType(element.status);

        return (
            <Table.Tr key={`table-item-${index}`}>
                <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>{element.refNo}</Table.Td>
                <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
                    <Badge tt={element.status === 'waiting' ? 'initial' : 'uppercase'} leftSection={icon.icon}
                           style={{border: '1px solid #ABEFC6'}} h={23} color={icon.color}>
                        {icon.text}
                    </Badge>
                </Table.Td>
                <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element?.semtEvi?.phoneNumber || ''}</Table.Td>
                <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{moment(element.createdOn).format('DD.MM.YYYY HH:mm')}</Table.Td>
                <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
                    <Button rightSection={<IoArrowForward size={14} color={"#fff"}/>} size={"xs"} component={Link}
                            to={`/application/detail/${element.id}`}>Detayı Gör</Button>
                </Table.Td>
            </Table.Tr>
        )
    }) : [];

    useEffect(() => {
        if (addApplicationProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: addApplicationProps?.error?.data?.message ?? '',
                autoClose: 2000,
            })
        }
    }, [addApplicationProps.isError]);




    return (<>

        <HeaderTitle
            title={"Başvurular"}
            count={`${data?.totalItems || 0} başvurular`}
            description={"Tüm başvuruları görüntüleyin ve yönetin."}
            buttonText={ "Yeni Başvuru Oluştur"}
            ml={-20}
            mb={20}
            className={"header-title"}
            onClick={() => {
                navigate(`/application/add`)
            }}
            extraComponent={
                <Group>
                    <TextInput w={280} onChange={(e) =>{
                        setSearch(e.currentTarget.value);
                    }} leftSection={<IoSearch/>} styles={{input: {height: 40}}}
                               placeholder={"Arama:Başvuru No"}/>

                    <Anchor
                        target={'_blank'}
                        href={`http://37.148.211.53/applications/export?token=${jwt.token().get()}`}
                    >
                        <Button
                            loading={applicationExportProps.isLoading}
                            color={"orange"}
                            h={40} radius={8} fz={14}
                            rightSection={<FaFileExcel/>}
                            fw={600} mr={10}
                        >
                            Excele Aktar
                        </Button>
                    </Anchor>

                </Group>
            }
        />




        <Tabs onChange={setStatus} mb={60} defaultValue="0" color={"green"} styles={{
            tabLabel: {
                fontSize: 13,
                fontWeight: 500,
            },
            tab: {
                borderBottomWidth: 3
            }
        }}>
            <Tabs.List ml={matches ? -20 : -46} w={'calc(100% + 62px)'}>
                <Tabs.Tab value="0">
                    Tümü
                </Tabs.Tab>
                <Tabs.Tab value="1">
                    Bekleyenler
                </Tabs.Tab>
                <Tabs.Tab value="2">
                    Onaylananlar
                </Tabs.Tab>
                <Tabs.Tab value="4">
                    Reddedilenler
                </Tabs.Tab>
                <Tabs.Tab value="3">
                    Teslim Edilenler
                </Tabs.Tab>
            </Tabs.List>
        </Tabs>

        <Box ml={-46} mr={-10} mt={-56} mb={70} pos={'relative'}>
            <LoadingOverlay visible={isFetching} />
            <Table striped style={{
                borderTop: '12px solid #F7F7F7'
            }}>
                <TableTop
                    styles={{
                        thead: {
                            backgroundColor: '#F9FAFB',


                        }
                    }}
                    titles={[ "Başvuru Numarası","Durum",  "Telefon", "Kayıt Tarihi", ""]}
                />
                <Table.Tbody>{rows}</Table.Tbody>

            </Table>
        </Box>



        <Center h={68} bg={'#fff'} pos={'fixed'} left={0} bottom={0} w={'100%'}
                style={{borderTop: '1px solid #EAECF0'}}>
            <Pagination
                color={"#f9fafb"}
                total={Number(data?.totalPages || 0)}
                withControls={false}
                onChange={setActivePage}
                styles={{
                    control: {
                        color: '#475467',
                        fontSize: 14,
                        border: 'none'
                    },
                    dots: {
                        fontSize: 14,
                        color: '#475467',

                    }
                }}
            />
        </Center>

    </>)
}

export default Applications;
