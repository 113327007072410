//pages
import HelpSub from '../app/help/sub';
import CitizensDetail from '../app/citizens/detail';
import BasisApplicationsAdd, {ApplicationsAdd} from '../app/applications/add';
import BasicApplicationDetail, {ApplicationDetail} from '../app/applications/detail';
import BasicApplications from '../app/applications';
import Reports from '../app/reports';
import Citizens from '../app/citizens';
import Logs from '../app/logs';
import Login from '../app/auth/login';
import District from '../app/district';
import Help from '../app/help';
import Users from '../app/users';
import Documents from '../app/documents';
import Dashboard from '../app/dashboard';
import Settings from '../app/settings';
import * as Service from '../services/system';

//router
import {Navigate, useRoutes} from 'react-router-dom';
import StreetDetail from "../app/dashboard/detail";
import DistrictDetail from "../app/district/detail";
import Representatives from "../app/representatives";
import BasicDashboard from "../app/dashboard/dashboard";

const AuthService = Service.Auth.init();

const isAuthenticated = (): boolean => {
    return AuthService.jwt().token().get() !== null;
};

const requireAuth = (element: JSX.Element): JSX.Element => {
    return isAuthenticated() ? element : <Navigate to="/login" state={{from: window.location.pathname}}/>;
};


const Router = () => useRoutes([
    {
        path: "/",
        element: requireAuth(<Dashboard/>)
    },
    {
        path: "/dashboard",
        element: requireAuth(<BasicDashboard/>)
    },
    {
        path: "/representatives",
        element: requireAuth(<Representatives/>)
    },
    {
        path: "/street/:id",
        element: requireAuth(<StreetDetail/>)
    },
    {
        path: 'applications',
        element: requireAuth(<BasicApplications/>),
    },
    {
        path: 'application/detail/:id',
        element: requireAuth(<BasicApplicationDetail/>),
    },
    {
        path: 'application/add',
        element: requireAuth(<BasisApplicationsAdd/>),
    },
    {
        path: "/documents",
        element: requireAuth(<Documents/>)
    },
    {
        path: "/help",
        element: requireAuth(<Help/>)
    },
    {
        path: "help/sub/:id",
        element: requireAuth(<HelpSub/>)
    },
    {
        path: "/users",
        element: requireAuth(<Users/>)
    },
    {
        path: "/district-home",
        element:requireAuth(<District/>)
    },
    {
        path: "/district-home/:id",
        element:requireAuth(<DistrictDetail/>)
    },
    {
        path: "/settings",
        element: requireAuth(<Settings/>)
    },
    {
        path: "/logs",
        element: requireAuth(<Logs/>)
    },
    {
        path: 'citizens',
        element: requireAuth(<Citizens/>),
    },
    {
        path: 'citizen/:id',
        element: requireAuth(<CitizensDetail/>),
    },
    {
        path: "/reports",
        element: requireAuth(<Reports/>)
    },
    {
        path: "/login",
        element: <Login/>
    },



]);

export default Router;


