export const generateRandomString = (length: number) => {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        randomString += charset.charAt(randomIndex);
    }

    return randomString;
};

export const raw_avatar_url = (id: string | null): string => {
    return `${process.env.REACT_APP_URL}/avatar/${id}`;
}

export const raw_file_url = (id: string | null): string => {
    return `${process.env.REACT_APP_URL}/file/${id}`;
}


export const createSlug = (text: string) => {
    const turkishMap: { [key: string]: string } = {
        'ç': 'c', 'Ç': 'C',
        'ğ': 'g', 'Ğ': 'G',
        'ı': 'i', 'I': 'I',
        'İ': 'i', 'i̇': 'i',
        'ö': 'o', 'Ö': 'O',
        'ş': 's', 'Ş': 'S',
        'ü': 'u', 'Ü': 'U'
    };

    return text
        .split('')
        .map(char => turkishMap[char] || char) // Türkçe karakterleri düzelt
        .join('')
        .toLowerCase() // Küçük harfe çevir
        .replace(/[^a-z0-9\s-]/g, '') // Geçersiz karakterleri kaldır
        .trim() // Baş ve sondaki boşlukları kaldır
        .replace(/\s+/g, '-') // Boşlukları tire ile değiştir
        .replace(/-+/g, '-'); // Birden fazla tireyi tek tireye düşür
}


export const citiesMap = (data: Array<{ id: number, name: string }>) => {

    let _data = [{label: 'İl Seç', value: ''}];

    data.map((item) => _data.push({label: item.name, value: String(item.id)}));
    return _data;
}


export const neighbourhoodMap = (data: Array<{ id: number, cityId:number, name: string }>) => {

    let _data = [{label: 'Mahalle Seç', value: ''}];

    data.map((item) => _data.push({label: item.name, value: String(item.id)}));
    return _data;
}

export const streetMap = (data: Array<{ id: number, neighborhoodsId:string, name: string }>) => {

    let _data = [{label: 'Sokak Seç', value: ''}];

    data.map((item) => _data.push({label: item.name, value: String(item.id)}));
    return _data;
}
