import {createApi} from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../lib/axiosBaseQuery";
import {HousesProps} from "./types/houses";
import {AddApplication} from "./types/applications";
import {ApiResponse} from "./types/types";

export const applicationsApi = createApi({
    reducerPath: "applicationsApi",
    baseQuery: axiosBaseQuery(),
    tagTypes: ["Applications","AppDetail"],
    endpoints: (builder) => ({


        getApplications: builder.query<ApiResponse, {page:number,status:any,q:any}>({
            query: ({page,status,q}) => ({
                url: `/applications/list-status/${status}/v2`,
                method: "GET",
                params:{page,q},
                includeToken: true,
            }),
            providesTags: (result) =>
                result
                    ? [
                        ...result.items.map(({id}: any) => ({
                            type: "Applications" as const,
                            id
                        })),
                        {type: "Applications", id: "APPLICATION_LIST"},
                    ]
                    : [{type: "Applications", id: "APPLICATION_LIST"}],
            transformResponse: (result: ApiResponse) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


        getApplicationDetail: builder.query<any, { id: string }>({
            query: ({id}) => ({
                url: `/applications/${id}`,
                method: "GET",
                includeToken: true,
            }),
            providesTags: (result) =>
                result
                    ? [{type: "AppDetail", id: "APP_DETAIL"},
                    ]
                    : [{type: "AppDetail", id: "APP_DETAIL"}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),

        getApplicationExport: builder.mutation<any, {}>({
            query: (values) => ({
                url: `/applications/export`,
                method: "GET",
                includeToken: true
            }),
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),

        addApplication: builder.mutation<any, AddApplication>({
            query: (values) => ({
                url: `/applications`,
                method: "POST",
                data: values,
                includeToken: true
            }),
            invalidatesTags: [{type: 'Applications', id: 'APPLICATION_LIST'}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),
        updateApplication: builder.mutation<any, {id:string,note:string,status:number}>({
            query: ({id,note,status}) => ({
                url: `/applications/${id}`,
                method: "PUT",
                data: {note,status},
                includeToken: true
            }),
            invalidatesTags: [{type: 'Applications', id: 'APPLICATION_LIST'},{type: "AppDetail", id: "APP_DETAIL"}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),

        updateRepresentativeNote: builder.mutation<any, {id:string,note:string}>({
            query: ({id,note}) => ({
                url: `/applications/${id}/temsilci-notu`,
                method: "PUT",
                data: {note},
                includeToken: true
            }),
            invalidatesTags: [{type: 'Applications', id: 'APPLICATION_LIST'}],
            transformResponse: (result: any) => result,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    const {data} = await queryFulfilled;
                } catch (error) {
                }
            }
        }),


    })
});

export const {
    useAddApplicationMutation,
    useGetApplicationDetailQuery,
    useGetApplicationsQuery,
    useUpdateApplicationMutation,
    useGetApplicationExportMutation
} = applicationsApi;
