import {
  Badge,
  Box,
  Button,
  Card, Center,
  Grid,
  Group, Loader, LoadingOverlay,
  NumberInput,
  Select,
  Stack,
  Table,
  Text,
  TextInput,
  UnstyledButton
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useDisclosure, useDocumentTitle, useMediaQuery } from '@mantine/hooks';
import React, { useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { IoArrowBack, IoCalendarOutline, IoChevronDown, IoClose, IoEyeOutline } from 'react-icons/io5';
import { MdCheck } from 'react-icons/md';
import { PiHandCoinsLight } from 'react-icons/pi';
import {Link, useParams} from 'react-router-dom';
import { ThemeModal } from '../../components/Modal';
import TableTop from '../../components/Table/top';
import HeaderTitle from '../../components/Title';
import {useGetCitizensPassQuery, useGetCitizensQuery, useOneCitizensQuery} from "../../services/citizens.service";
import moment from "moment";


export const CitizensDetail = () => {

  useDocumentTitle('Balçova Belediyesi - Vatandaş');
  const { id } = useParams<{ id: string }>();

  const {data,isSuccess, isLoading} = useOneCitizensQuery({id:String(id)});

  const passCitizens = useGetCitizensPassQuery({id:String(id),page:1});

  const [opened, { open, close }] = useDisclosure(false);

  const statusType = (status: number) => {
    let _status = { color: '', text: '', icon: <Box/> }

    switch (status) {
      case 1:
        _status = {
          color: '#FFA500',
          text: 'Bekliyor',
          icon: <AiOutlineLoading3Quarters color={"#fff"}/>
        }
        break;
      case 5:
        _status = {
          color: '#DC3545',
          text: 'RED',
          icon: <IoClose size={15} color={"#fff"}/>
        }
        break;
      case 4:
        _status = {
          color: '#DC3545',
          text: 'ONAYLANMADI',
          icon: <IoClose size={15} color={"#fff"}/>
        }
        break;

      case 2:
        _status = {
          color: '#28A745',
          text: 'ONAYLANDI',
          icon: <MdCheck size={15} color={"#fff"}/>

        }
        break;

      case 3:
        _status = {
          color: '#28A745',
          text: 'TAMAMLANDI',
          icon: <MdCheck size={15} color={"#fff"}/>

        }
        break;
    }

    return _status;
  }

  const [dataxa, setData] = useState<any>([
    /*{
      id: 1,
      name: 'Sefa Zor',
      code: '#AA202423001',
      status: 'waiting',
      phone: '+90 538 273 8631',
      created_at: '24.06.2024 - 14:50',
      address: 'Melikgazi mah. Kızılırmak cad. Kervansaray apt. No97',
      tc: '34939434'
    },*/

  ]);

  const [value, setValue] = useState<Date | null>(null);

  const rowsPass = passCitizens.isSuccess ?  passCitizens.data.items.map((element:any, index) => {

    let icon = statusType(element.status);

    return (
        <Table.Tr key={`table-item-${index}`}>
          <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
            <UnstyledButton w={40}>
              <IoEyeOutline size={19} color={"#475467"}/>
            </UnstyledButton>
          </Table.Td>
          <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
            <Badge tt={element.status === 1 ? 'initial' : 'uppercase'} leftSection={icon.icon} style={{border:'1px solid #ABEFC6'}} h={23} color={icon.color}>
              {icon.text}
            </Badge>
          </Table.Td>
          <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.refNo}</Table.Td>
          <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{moment(element.createdOn).format('DD.MM.YYYY HH:mm')}</Table.Td>
        </Table.Tr>
    )
  }) : [];

  const matches = useMediaQuery('(max-width: 1200px)');

  const rowsDelivery = [].map((element:any, index) => {


    return (
        <Table.Tr key={`table-item-${index}`}>
          <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
            <UnstyledButton w={40}>
              <IoEyeOutline size={19} color={"#475467"}/>
            </UnstyledButton>
          </Table.Td>
          <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
            {element?.name || ''}
          </Table.Td>
          <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element?.code || ''}</Table.Td>
          <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.created_at}</Table.Td>
        </Table.Tr>
    )
  });

  return (<>

    <HeaderTitle
        title={`${data?.fullName || ''} - #${data?.nationalityNumber || ''}`}
        description={`${data?.fullName || ''}  adlı ilçe sakininin bilgileri ve başvuru detayları`}
        buttonText={"Teslimat Ekle"}
        ml={-20}
        pr={matches ? 0 : 50}
        onClick={open}
        color={'green'}
        buttonColor={"#28A745"}
        extraComponent={<Button component={Link} to={'/citizens'} color={'#9F9F9F'} mr={10} h={40} leftSection={<IoArrowBack/>} fz={14} fw={600} radius={8}>Geri
          Git</Button>}
        className={"header-title"}
    />

    {isLoading &&
    <Center h={600}>
      <Loader size={"md"}/>
    </Center>
    }

    {isSuccess &&
    <>
      <ThemeModal
          opened={opened}
          onClose={close}
          icon={<PiHandCoinsLight size={25} color={"#7F56D9"}/>}
          title={"Yeni Teslimat Ekleyin"}
          description={"Vatandaşa yapılan teslimatın detaylarını girin."}>


        <Group className={"group-mb-disabled"} mb={16} align={'start'}>
          <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Teslimat Tarihi:</Text>
          <Box flex={1}>
            <DateInput
                value={value}
                onChange={setValue}
                leftSection={<IoCalendarOutline/>} rightSection={<IoChevronDown/>} placeholder={'Bir teslimat tarihi seçin'} radius={8} styles={{ input:{fontSize:15, height:44, color:'#667085', fontWeight:400 } }}/>
          </Box>
        </Group>
        <Group className={"group-mb-disabled"} mb={16} align={'start'}>
          <Text mt={4} fz={14} fw={500} lh={'20px'} c={'#344054'} w={190}>Not:</Text>
          <Box flex={1}>
            <NumberInput hideControls radius={8} styles={{ input:{ height:44, color:'#667085',fontSize:15, fontWeight:400 } }}/>
          </Box>
        </Group>
      </ThemeModal>


      <Box mt={30} pr={matches ? 0 : 50} ml={matches ? -20 : -46}>
        <Card withBorder  p={25} style={{borderLeftWidth:0}}>
          <Text mb={20} fw={700} fz={18}>Bilgileri</Text>
          <Stack>
            <Group>
              <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Ad Soyad:</Text>
              <Text c={'#000'} fw={500} fz={16} lh={'24px'}>{data?.fullName || ''} </Text>
            </Group>
            <Group>
              <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Telefon:</Text>
              <Text c={'#000'} fw={500} fz={16} lh={'24px'}>{data?.phoneNumber || ''}</Text>
            </Group>
            <Group>
              <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>TC Kimlik Numarası:</Text>
              <Text c={'#000'} fw={500} fz={16} lh={'24px'}>{data?.nationalityNumber || ''}</Text>
            </Group>
            <Group>
              <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Mahalle:</Text>
              <Text c={'#000'} fw={500} fz={16} lh={'24px'}>{data?.address?.neighborhood?.name || ''}</Text>
            </Group>
            <Group>
              <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Sokak:</Text>
              <Text c={'#000'} fw={500} fz={16} lh={'24px'}>{data?.address?.street?.name || ''}</Text>
            </Group>
            <Group>
              <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Adres:</Text>
              <Text c={'#000'} fw={500} fz={16} lh={'24px'}>{data?.address?.city?.name || ''} {data?.address?.neighborhood?.name || ''} {data?.address?.street?.name || ''}</Text>
            </Group>
          </Stack>
        </Card>
      </Box>


      <Box mt={30} pr={matches ? 0 : 50} ml={matches ? -20 : -46}>
        <Grid>
          <Grid.Col span={{lg:6}}>
            <Card py={27} px={23} withBorder>
              <Text fz={18} lh={'24px'} fw={700} c={'#000000'}>Önceki başvuruları</Text>

              <Table className={"responsive-table"} mt={20} style={{
                borderTop: '12px solid #F7F7F7'
              }}>
                <TableTop
                    styles={{
                      thead: {
                        backgroundColor: '#F9FAFB',
                      }
                    }}
                    fz={14}
                    titles={["", "Durum", "Başvuru Numarası", "Başvuru Tarihi"]}
                />
                  <LoadingOverlay visible={passCitizens.isLoading}/>
                <Table.Tbody>{rowsPass}</Table.Tbody>

              </Table>
            </Card>
          </Grid.Col>
          <Grid.Col span={{lg:6}}>
            <Card py={27} px={23} withBorder>
              <Text fz={18} lh={'24px'} fw={700} c={'#000000'}>Teslimatlar</Text>

              <Table className={"responsive-table"} mt={20} style={{
              }}>
                <TableTop
                    styles={{
                      thead: {
                        backgroundColor: '#F9FAFB',
                      }
                    }}
                    fz={14}
                    titles={["", "Kategori", "Konu", "Teslimat Tarihi"]}
                />
                <Table.Tbody>{rowsDelivery}</Table.Tbody>

              </Table>
            </Card>
          </Grid.Col>
        </Grid>
      </Box>
    </>
    }






  </>)
}

export default CitizensDetail;
