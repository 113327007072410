
class Token {

    PREFIX = 'Auth';
    NAME = '';

    constructor(NAME: string) {
        this.NAME = `${this.PREFIX}@${NAME}`;
    }

    set(token: string): void {
        localStorage.setItem(this.NAME, token);
    }

    get(): string | null {
        return localStorage.getItem(this.NAME) ?? null;
    }

    remove(): void {
        localStorage.removeItem(this.NAME);
    }

}

class JWT {

    token(): Token {
        return new Token('jwt.token');
    }

    refresh_token(): Token {
        return new Token('jwt.refresh_token');
    }

}


class Auth {

    static init() {
        return new Auth();
    }

    jwt(): JWT {
        return new JWT();
    }

    notification(): Token {
        return this.token('notification');
    }

    user(): Token {
        return this.token('user');
    }

    private token(name: string) {
        return new Token(name);
    }


}


export default Auth;
