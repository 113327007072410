import { Box, Button, Divider, Group, Modal, ModalProps, Text, ThemeIcon, UnstyledButton } from '@mantine/core';
import React from 'react';
import { IoClose } from 'react-icons/io5';

interface Props extends ModalProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  submitBtnText?:string;
}

export const ThemeModal = ({ icon, title, description,submitBtnText = 'Ekle', ...props }: Props) => {
  return (<Modal centered p={0} padding={0} withCloseButton={false} size={700} radius={12} {...props}>
    <Group pos={'relative'} p={24}>
      <ThemeIcon radius={10} variant={"outline"} color={"#EAECF0"} size={48}>
        {icon}
      </ThemeIcon>
      <Box flex={1}>
        <Text fz={18} c={'#101828'} lh={'28px'} fw={600}>{title}</Text>
        <Text fz={14} fw={400} lh={'20px'} c={'#475467'}>{description}</Text>
      </Box>
      <UnstyledButton onClick={() => props.onClose()} pos={'absolute'} right={12} top={12} px={10} py={0} h={44} lh={'55px'}>
        <IoClose color={"#667085"} size={25}/>
      </UnstyledButton>
    </Group>
    <Divider  c={'#EAECF0'}/>
    <Box px={24} py={20}>
      <Divider mb={16} color={'#EAECF0'}/>
      {props.children}
    </Box>


  </Modal>)
}
