import { AreaChart } from '@mantine/charts';
import { Badge, Box, Card, Collapse, Grid, Group, Table, Tabs, Text, UnstyledButton } from '@mantine/core';
import { useDisclosure, useDocumentTitle, useMediaQuery } from '@mantine/hooks';
import { useState } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { IoArrowUp, IoChevronDown, IoChevronUp, IoClose, IoEyeOutline } from 'react-icons/io5';
import { MdCheck } from 'react-icons/md';


export const Reports = () => {

  useDocumentTitle('Balçova Belediyesi - Raporlar');
  const matches = useMediaQuery('(max-width: 1200px)');

  const statusType = (status: string) => {
    let _status = { color: '', text: '', icon: <Box/> }

    switch (status) {
      case "waiting":
        _status = {
          color: '#FFA500',
          text: 'Bekliyor',
          icon: <AiOutlineLoading3Quarters color={"#fff"}/>
        }
        break;
      case "rejection":
        _status = {
          color: '#DC3545',
          text: 'RED',
          icon: <IoClose size={15} color={"#fff"}/>
        }
        break;
      case "approved":
        _status = {
          color: '#28A745',
          text: 'ONAYLANDI',
          icon: <MdCheck size={15} color={"#fff"}/>

        }
        break;
    }

    return _status;
  }

  const [data, setData] = useState([
    {
      id: 1,
      name: 'Sefa Zor',
      code: '#AA202423001',
      status: 'waiting',
      phone: '+90 538 273 8631',
      created_at: '24.06.2024 - 14:50',
      address: 'Melikgazi mah. Kızılırmak cad. Kervansaray apt. No97',
      tc: '34939434'
    },
    {
      id: 2,
      name: 'Sefa Zor',
      code: '#AA202423001',
      status: 'waiting',
      phone: '+90 538 273 8631',
      created_at: '24.06.2024 - 14:50',
      address: 'Melikgazi mah. Kızılırmak cad. Kervansaray apt. No97',
      tc: '34939434'
    },
    {
      id: 3,
      name: 'Sefa Zor',
      code: '#AA202423001',
      status: 'rejection',
      phone: '+90 538 273 8631',
      created_at: '24.06.2024 - 14:50',
      address: 'Melikgazi mah. Kızılırmak cad. Kervansaray apt. No97',
      tc: '34939434'
    },
    {
      id: 4,
      name: 'Sefa Zor',
      code: '#AA202423001',
      status: 'approved',
      phone: '+90 538 273 8631',
      created_at: '24.06.2024 - 14:50',
      address: 'Melikgazi mah. Kızılırmak cad. Kervansaray apt. No97',
      tc: '34939434'
    },
    {
      id: 5,
      name: 'Sefa Zor',
      code: '#AA202423001',
      status: 'approved',
      phone: '+90 538 273 8631',
      created_at: '24.06.2024 - 14:50',
      address: 'Melikgazi mah. Kızılırmak cad. Kervansaray apt. No97',
      tc: '34939434'
    },
    {
      id: 6,
      name: 'Sefa Zor',
      code: '#AA202423001',
      status: 'approved',
      phone: '+90 538 273 8631',
      created_at: '24.06.2024 - 14:50',
      address: 'Melikgazi mah. Kızılırmak cad. Kervansaray apt. No97',
      tc: '34939434'
    },
  ]);
  const [opened, { toggle }] = useDisclosure(false);

  const rows = data.map((element, index) => {

    let icon = statusType(element.status);

    return (
      <Table.Tr key={`table-item-${index}`}>
        <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={28}>
          <UnstyledButton w={40}>
            <IoEyeOutline size={19} color={"#475467"}/>
          </UnstyledButton>
        </Table.Td>
        <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={28}>{element.name}</Table.Td>
        <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={28}>
          <Badge tt={element.status === 'waiting' ? 'initial' : 'uppercase'} leftSection={icon.icon}
                 style={{ border: '1px solid #ABEFC6' }} h={23} color={icon.color}>
            {icon.text}
          </Badge>
        </Table.Td>
        <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={28}>{element.code}</Table.Td>
        <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={28}>{element.tc}</Table.Td>
        <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={28}>{element.phone}</Table.Td>
        <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={28}>{element.created_at}</Table.Td>
      </Table.Tr>
    )
  });

  const dataStatistic = [
    {
      date: 'Pzt',
      count: 100
    },
    {
      date: 'Salı',
      count: 40,
    },
    {
      date: 'Çarşamaba',
      count: 150,
    },
    {
      date: 'Perşembe',
      count: 30,
    },
    {
      date: 'Cuma',
      count: 250,
    },
    {
      date: 'Cumartesi',
      count: 70,
    },
    {
      date: 'Pazar',
      count: 100,
    },
  ];


  return (<>

    <Text  mb={10} ml={matches ? 0 : -20} fz={36} fw={700} lts={'-1px'} c={'#000000'}>Raporlar</Text>


    <Tabs defaultValue="7" color={"green"} styles={{
      tabLabel: {
        fontSize: 13,
        fontWeight: 500,
      },
      tab: {
        borderBottomWidth: 3
      }
    }}>
      <Tabs.List ml={-20} w={'calc(100% + 62px)'}>
        <Tabs.Tab value="7">
          Son 7 Gün
        </Tabs.Tab>
        <Tabs.Tab value="30">
          Son 30 Gün
        </Tabs.Tab>
        <Tabs.Tab value="45">
          Son 45 Gün
        </Tabs.Tab>
        <Tabs.Tab value="90">
          Son 90 Gün
        </Tabs.Tab>
        <Tabs.Tab value="180">
          Son 180 Gün
        </Tabs.Tab>
        <Tabs.Tab value="year">
          Son 1 Yıl
        </Tabs.Tab>
        <Tabs.Tab value="date">
          Tarihe Göre Filtrele
        </Tabs.Tab>
      </Tabs.List>

    </Tabs>

    <Box ml={matches ? -20 : -45} bg={'#F7F7F7'} h={12} w={matches ? 'calc(100% + 38px)': 'calc(100% + 60px)'}/>

    <Box mr={matches ? 0 : 30} pt={30}>
      <Card withBorder ml={-20} p={24}>
        <Text fw={600} fz={20} c={'#101828'}>Toplam başvuru sayısı</Text>
        <Group>
          <Text fz={30} c={'#101828'} fw={600}>4.342</Text>
          <Badge h={26} radius={6} color={'outline'} c={'#344054'} style={{ border: '1px solid  #D0D5DD' }}
                 tt={'initial'} leftSection={<IoArrowUp color={'#079455'}/>}>7.2% - Öncekine göre</Badge>
        </Group>
        <AreaChart
          h={300}
          data={dataStatistic}
          dataKey="date"
          type="stacked"
          series={[
            { name: 'count', label: 'date', color: 'indigo.6' },
          ]}
        />
      </Card>
    </Box>


    <Box ml={matches ? 0 : -20} mt={48}>
      <Text lh={'40px'} fz={24} fw={600} c={'#111'}>Başvuru Onay/Red</Text>
      <Text fz={18} fw={500} lts={'-0.11px'} c={'#111111'}>Başvurulara verilen karar istatistiği</Text>

      <Box pr={matches ? 0 : 30}>
        <Grid gutter={20} mt={26}>
          <Grid.Col span={{ lg: 3 }}>
            <Card h={100} radius={0} withBorder p={20} shadow={"xs"}>
              <Group>
                <Box flex={1}>
                  <Text fw={500} fz={15} c={'#475467'}>Onay</Text>
                  <Text fw={600} c={'#101828'} fz={29}>3432</Text>
                </Box>
                <UnstyledButton mt={-38}>
                  <HiOutlineDotsVertical size={20}/>
                </UnstyledButton>
              </Group>
            </Card>
          </Grid.Col>
          <Grid.Col span={{ lg: 3 }}>
            <Card h={100} radius={0} withBorder p={20} shadow={"xs"}>
              <Group>
                <Box flex={1}>
                  <Text fw={500} fz={15} c={'#475467'}>Red</Text>
                  <Text fw={600} c={'#101828'} fz={29}>910</Text>
                </Box>
                <UnstyledButton mt={-38}>
                  <HiOutlineDotsVertical size={20}/>
                </UnstyledButton>
              </Group>
            </Card>
          </Grid.Col>
          <Grid.Col span={{ lg: 3 }}>
            <Card h={100} radius={0} withBorder p={20} shadow={"xs"}>
              <Group>
                <Box flex={1}>
                  <Text fw={500} fz={15} c={'#475467'}>Toplam işlem sayısı</Text>
                  <Text fw={600} c={'#101828'} fz={29}>4342</Text>
                </Box>
                <UnstyledButton mt={-38}>
                  <HiOutlineDotsVertical size={20}/>
                </UnstyledButton>
              </Group>
            </Card>
          </Grid.Col>
          <Grid.Col span={{ lg: 3 }}>
            <Card h={100} radius={0} withBorder p={20} shadow={"xs"}>
              <Group>
                <Box flex={1}>
                  <Text fw={500} fz={15} c={'#475467'}>Tekil Başvuran Sayısı</Text>
                  <Text fw={600} c={'#101828'} fz={29}>4200</Text>
                </Box>
                <UnstyledButton mt={-38}>
                  <HiOutlineDotsVertical size={20}/>
                </UnstyledButton>
              </Group>
            </Card>
          </Grid.Col>
        </Grid>
      </Box>

      <Box mt={57} maw={'100%'} w={950}>
        <Text c={'#111111'} fz={24} fw={600}>Semtevine Göre Başvuru İstatistiği</Text>
        <Text mt={10} fz={16} mb={20} fw={500} lh={'24px'}>Semtevine göre başvuru istatistikleri</Text>

        <Tabs mb={12} defaultValue="7" color={"green"} styles={{
          tabLabel: {
            fontSize: 13,
            fontWeight: 500,
          },
          tab: {
            borderBottomWidth: 3
          }
        }}>
          <Tabs.List w={matches ? 'calc(100% + 38px)' : 'calc(100% + 62px)'}>
            <Tabs.Tab value="7">
              Son 7 Gün
            </Tabs.Tab>
            <Tabs.Tab value="30">
              Son 30 Gün
            </Tabs.Tab>
            <Tabs.Tab value="45">
              Son 45 Gün
            </Tabs.Tab>
            <Tabs.Tab value="90">
              Son 90 Gün
            </Tabs.Tab>
            <Tabs.Tab value="180">
              Son 180 Gün
            </Tabs.Tab>
            <Tabs.Tab value="year">
              Son 1 Yıl
            </Tabs.Tab>
            <Tabs.Tab value="date">
              Tarihe Göre Filtrele
            </Tabs.Tab>
          </Tabs.List>

        </Tabs>

        <Table  className={"responsive-table"} styles={{
          td: {
            padding: '24px 28px'
          }
        }}>
          <Table.Thead style={{
            backgroundColor: '#F9FAFB',
            height: 44
          }}>
            <Table.Tr>
              <Table.Th fw={500} c={'#475467'}>Semtevi</Table.Th>
              <Table.Th fw={500} c={'#475467'}>Başvuru Sayısı</Table.Th>
              <Table.Th fw={500} c={'#475467'}>Onay Sayısı</Table.Th>
              <Table.Th fw={500} c={'#475467'}>Red Sayısı</Table.Th>
              <Table.Th fw={500} c={'#475467'}>İşlemde</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td fz={14} fw={500} c={'#000'}>ÇETİN EMEÇ SEMTEVİ</Table.Td>
              <Table.Td fw={400} c={'#475467'} fz={14} bg={'#F9FAFB'}>160</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14}>100</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14} bg={'#F9FAFB'}>54</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14}>6</Table.Td>
              <Table.Td>
                <UnstyledButton w={40}>
                  <IoEyeOutline size={21} color={"#475467"}/>
                </UnstyledButton>
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td fz={14} fw={500} c={'#000'}>KORUTÜRK SEMTEVİ</Table.Td>
              <Table.Td fw={400} c={'#475467'} fz={14} bg={'#F9FAFB'}>160</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14}>100</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14} bg={'#F9FAFB'}>54</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14}>6</Table.Td>
              <Table.Td>
                <UnstyledButton w={40}>
                  <IoEyeOutline size={21} color={"#475467"}/>
                </UnstyledButton>
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </Box>

      <Box mt={57} maw={'100%'} w={950}>
        <Text c={'#111111'} fz={24} fw={600}>Yardım Tipine Göre Başvuru İstatistiği</Text>
        <Text mt={10} fz={16} mb={20} fw={500} lh={'24px'}>Yardım tipine göre başvuru istatistiği</Text>

        <Tabs mb={12} defaultValue="7" color={"green"} styles={{
          tabLabel: {
            fontSize: 13,
            fontWeight: 500,
          },
          tab: {
            borderBottomWidth: 3
          }
        }}>
          <Tabs.List w={matches ? 'calc(100% + 38px)' : 'calc(100% + 62px)'}>
            <Tabs.Tab value="7">
              Son 7 Gün
            </Tabs.Tab>
            <Tabs.Tab value="30">
              Son 30 Gün
            </Tabs.Tab>
            <Tabs.Tab value="45">
              Son 45 Gün
            </Tabs.Tab>
            <Tabs.Tab value="90">
              Son 90 Gün
            </Tabs.Tab>
            <Tabs.Tab value="180">
              Son 180 Gün
            </Tabs.Tab>
            <Tabs.Tab value="year">
              Son 1 Yıl
            </Tabs.Tab>
            <Tabs.Tab value="date">
              Tarihe Göre Filtrele
            </Tabs.Tab>
          </Tabs.List>

        </Tabs>

        <Table className={"responsive-table"} styles={{
          td: {
            padding: '24px 28px'
          }
        }}>
          <Table.Thead style={{
            backgroundColor: '#F9FAFB',
            height: 44
          }}>
            <Table.Tr>
              <Table.Th fw={500} c={'#475467'}>Yardım Tipi</Table.Th>
              <Table.Th fw={500} c={'#475467'}>Başvuru Sayısı</Table.Th>
              <Table.Th fw={500} c={'#475467'}>Onay Sayısı</Table.Th>
              <Table.Th fw={500} c={'#475467'}>Red Sayısı</Table.Th>
              <Table.Th fw={500} c={'#475467'}>İşlemde</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            <Table.Tr>
              <Table.Td fz={14} fw={500} c={'#000'}>TAŞIMA</Table.Td>
              <Table.Td fw={400} c={'#475467'} fz={14} bg={'#F9FAFB'}>160</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14}>100</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14} bg={'#F9FAFB'}>54</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14}>6</Table.Td>
              <Table.Td>
                <UnstyledButton w={40}>
                  <IoEyeOutline size={21} color={"#475467"}/>
                </UnstyledButton>
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td fz={14} fw={500} c={'#000'}>
                <UnstyledButton onClick={toggle} mt={7}>
                  <Group>
                    NAKDİ YARDIM
                    <IoChevronUp style={{
                      transition:'250ms',
                      transform:`rotate(${opened ? '180' : '0'}deg)`
                    }} size={20} color={"#AAB0BB"}/>
                  </Group>
                 </UnstyledButton>
              </Table.Td>
              <Table.Td fw={400} c={'#475467'} fz={14} bg={'#F9FAFB'}>160</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14}>100</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14} bg={'#F9FAFB'}>54</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14}>6</Table.Td>
              <Table.Td>
                <UnstyledButton w={40}>
                  <IoEyeOutline size={18} color={"#475467"}/>
                </UnstyledButton>
              </Table.Td>
            </Table.Tr>
            {opened &&
            <>
              <Table.Tr>
                <Table.Td py={10} px={28} bg={'#FFF9E3'} fz={14} fw={500} c={'#000'}>KİRA</Table.Td>
                <Table.Td py={10} px={28} fw={400} c={'#475467'} fz={14} bg={'#FFF9E3'}>160</Table.Td>
                <Table.Td py={10} px={28} fw={400} c={'#101828'} fz={14} bg={'#FFF9E3'}>100</Table.Td>
                <Table.Td py={10} px={28} fw={400} c={'#101828'} fz={14} bg={'#FFF9E3'}>54</Table.Td>
                <Table.Td py={10} px={28} fw={400} c={'#101828'} fz={14} bg={'#FFF9E3'}>6</Table.Td>
                <Table.Td py={10} px={28}>
                  <UnstyledButton w={40} ml={0}>
                    <IoEyeOutline size={21} color={"#475467"}/>
                  </UnstyledButton>
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td py={10} px={28} bg={'#FFF9E3'} fz={14} fw={500} c={'#000'}>ELEKTRİK</Table.Td>
                <Table.Td py={10} px={28} fw={400} c={'#475467'} fz={14} bg={'#FFF9E3'}>160</Table.Td>
                <Table.Td py={10} px={28} fw={400} c={'#101828'} fz={14} bg={'#FFF9E3'}>100</Table.Td>
                <Table.Td py={10} px={28} fw={400} c={'#101828'} fz={14} bg={'#FFF9E3'}>54</Table.Td>
                <Table.Td py={10} px={28} fw={400} c={'#101828'} fz={14} bg={'#FFF9E3'}>6</Table.Td>
                <Table.Td py={10} px={28}>
                  <UnstyledButton w={40} ml={0}>
                    <IoEyeOutline size={21} color={"#475467"}/>
                  </UnstyledButton>
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td py={10} px={28} bg={'#FFF9E3'} fz={14} fw={500} c={'#000'}>SU</Table.Td>
                <Table.Td py={10} px={28} fw={400} c={'#475467'} fz={14} bg={'#FFF9E3'}>160</Table.Td>
                <Table.Td py={10} px={28} fw={400} c={'#101828'} fz={14} bg={'#FFF9E3'}>100</Table.Td>
                <Table.Td py={10} px={28} fw={400} c={'#101828'} fz={14} bg={'#FFF9E3'}>54</Table.Td>
                <Table.Td py={10} px={28} fw={400} c={'#101828'} fz={14} bg={'#FFF9E3'}>6</Table.Td>
                <Table.Td py={10} px={28}>
                  <UnstyledButton w={40} ml={0}>
                    <IoEyeOutline size={21} color={"#475467"}/>
                  </UnstyledButton>
                </Table.Td>
              </Table.Tr>
              <Table.Tr>
                <Table.Td py={10} px={28} bg={'#FFF9E3'} fz={14} fw={500} c={'#000'}>DOĞALGAZ</Table.Td>
                <Table.Td py={10} px={28} fw={400} c={'#475467'} fz={14} bg={'#FFF9E3'}>160</Table.Td>
                <Table.Td py={10} px={28} fw={400} c={'#101828'} fz={14} bg={'#FFF9E3'}>100</Table.Td>
                <Table.Td py={10} px={28} fw={400} c={'#101828'} fz={14} bg={'#FFF9E3'}>54</Table.Td>
                <Table.Td py={10} px={28} fw={400} c={'#101828'} fz={14} bg={'#FFF9E3'}>6</Table.Td>
                <Table.Td py={10} px={28}>
                  <UnstyledButton w={40} ml={0}>
                    <IoEyeOutline size={21} color={"#475467"}/>
                  </UnstyledButton>
                </Table.Td>
              </Table.Tr>
            </>
            }
            <Table.Tr>
              <Table.Td px={28} fz={14} fw={500} c={'#000'}>MEDİKAL MALZEME YARDIMI</Table.Td>
              <Table.Td fw={400} c={'#475467'} fz={14} bg={'#F9FAFB'}>160</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14}>100</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14} bg={'#F9FAFB'}>54</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14}>6</Table.Td>
              <Table.Td>
                <UnstyledButton w={40}>
                  <IoEyeOutline size={21} color={"#475467"}/>
                </UnstyledButton>
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td px={28} fz={14} fw={500} c={'#000'}>EV TEMİZLİĞİ</Table.Td>
              <Table.Td fw={400} c={'#475467'} fz={14} bg={'#F9FAFB'}>160</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14}>100</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14} bg={'#F9FAFB'}>54</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14}>6</Table.Td>
              <Table.Td>
                <UnstyledButton w={40}>
                  <IoEyeOutline size={21} color={"#475467"}/>
                </UnstyledButton>
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td px={28} fz={14} fw={500} c={'#000'}>KIYAFET</Table.Td>
              <Table.Td fw={400} c={'#475467'} fz={14} bg={'#F9FAFB'}>160</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14}>100</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14} bg={'#F9FAFB'}>54</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14}>6</Table.Td>
              <Table.Td>
                <UnstyledButton w={40}>
                  <IoEyeOutline size={21} color={"#475467"}/>
                </UnstyledButton>
              </Table.Td>
            </Table.Tr>
            <Table.Tr>
              <Table.Td px={28} fz={14} fw={500} c={'#000'}>KÖMÜR</Table.Td>
              <Table.Td fw={400} c={'#475467'} fz={14} bg={'#F9FAFB'}>160</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14}>100</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14} bg={'#F9FAFB'}>54</Table.Td>
              <Table.Td fw={400} c={'#101828'} fz={14}>6</Table.Td>
              <Table.Td>
                <UnstyledButton w={40}>
                  <IoEyeOutline size={21} color={"#475467"}/>
                </UnstyledButton>
              </Table.Td>
            </Table.Tr>
          </Table.Tbody>
        </Table>
      </Box>

    </Box>
  </>)
}

export default Reports;
