import {
    Badge,
    Box,
    Button,
    Center,
    Chip,
    Divider,
    FileButton,
    Grid,
    Group,
    Image, LoadingOverlay,
    NumberInput,
    Select,
    Skeleton,
    Table,
    Text,
    TextInput,
    ThemeIcon,
    UnstyledButton
} from '@mantine/core';
import {useForm} from '@mantine/form';
import {useDocumentTitle, useListState, useMediaQuery} from '@mantine/hooks';
import React, {useEffect, useState} from 'react';
import {AiOutlineLoading3Quarters} from 'react-icons/ai';
import {IoAdd, IoArrowBack, IoClose, IoEyeOutline, IoRemove} from 'react-icons/io5';
import {MdCheck} from 'react-icons/md';
import {PiCheckCircleFill} from 'react-icons/pi';
import {Link, useNavigate} from 'react-router-dom';
import {
    useGetSocialCategoryByIdQuery,
    useGetSocialCategoryQuery,
    usePostSocialCategoryByIdMutation
} from "../../services/social.service";
import {useAddCitizensMutation, useGetCitizensSearchMutation} from "../../services/citizens.service";
import {notifications} from "@mantine/notifications";
import {
    useGetAddressCitiesQuery,
    useGetAddressCityNeighborhoodsQuery,
    useGetCityNeighborhoodIdQuery
} from "../../services/address.service";
import {neighbourhoodMap, streetMap} from "../../lib/helper";
import {axiosFetch} from "../../lib/axiosBaseQuery";
import _ from "lodash";


export const ApplicationsAdd = () => {

    useDocumentTitle('Balçova Belediyesi - Yeni Başvuru Kaydı');
    const [file, setFile] = useState<File | null>(null);
    const [tc, setTc] = useState<any>('');
    const [values, handlers] = useListState<any>([{type: '', helpIds: [], subHelpIds: []}]);
    const helpCategories = useGetSocialCategoryQuery({});
    const [helpCats, setHelpCats] = useState<any>([]);
    const [helpCatId, setHelpCatId] = useState<any>('');
    const getSubsHelp = useGetSocialCategoryByIdQuery({id: helpCatId});
    const [getCitizens, getCitizensProps] = useGetCitizensSearchMutation({});
    const [disabledForm, setDisabledForm] = useState<boolean>(false);
    const [helpIds, helpIdsProps] = usePostSocialCategoryByIdMutation({});
    const [activeIndex, setActiveIndex] = useState(0);
    const [addCitizens, addCitizensProps] = useAddCitizensMutation({});
    const statusType = (status: any) => {
        let _status = {color: '', text: '', icon: <Box/>}

        switch (status) {
            case 1:
                _status = {
                    color: '#FFA500',
                    text: 'Bekliyor',
                    icon: <AiOutlineLoading3Quarters color={"#fff"}/>
                }
                break;
            case 5:
                _status = {
                    color: '#DC3545',
                    text: 'RED',
                    icon: <IoClose size={15} color={"#fff"}/>
                }
                break;
            case 4:
                _status = {
                    color: '#DC3545',
                    text: 'ONAYLANMADI',
                    icon: <IoClose size={15} color={"#fff"}/>
                }
                break;

            case 2:
                _status = {
                    color: '#28A745',
                    text: 'ONAYLANDI',
                    icon: <MdCheck size={15} color={"#fff"}/>

                }
                break;

            case 3:
                _status = {
                    color: '#28A745',
                    text: 'TAMAMLANDI',
                    icon: <MdCheck size={15} color={"#fff"}/>

                }
                break;
        }

        return _status;
    }
    const [note, setNote] = useState('');
    const [data, setData] = useState([
        {
            id: 1,
            name: 'Sefa Zor',
            code: '#AA202423001',
            status: 'waiting',
            phone: '+90 538 273 8631',
            created_at: '24.06.2024 - 14:50',
            address: 'Melikgazi mah. Kızılırmak cad. Kervansaray apt. No97',
            tc: '34939434'
        },
        {
            id: 2,
            name: 'Sefa Zor',
            code: '#AA202423001',
            status: 'waiting',
            phone: '+90 538 273 8631',
            created_at: '24.06.2024 - 14:50',
            address: 'Melikgazi mah. Kızılırmak cad. Kervansaray apt. No97',
            tc: '34939434'
        },
        {
            id: 3,
            name: 'Sefa Zor',
            code: '#AA202423001',
            status: 'rejection',
            phone: '+90 538 273 8631',
            created_at: '24.06.2024 - 14:50',
            address: 'Melikgazi mah. Kızılırmak cad. Kervansaray apt. No97',
            tc: '34939434'
        },
        {
            id: 4,
            name: 'Sefa Zor',
            code: '#AA202423001',
            status: 'approved',
            phone: '+90 538 273 8631',
            created_at: '24.06.2024 - 14:50',
            address: 'Melikgazi mah. Kızılırmak cad. Kervansaray apt. No97',
            tc: '34939434'
        },
        {
            id: 5,
            name: 'Sefa Zor',
            code: '#AA202423001',
            status: 'approved',
            phone: '+90 538 273 8631',
            created_at: '24.06.2024 - 14:50',
            address: 'Melikgazi mah. Kızılırmak cad. Kervansaray apt. No97',
            tc: '34939434'
        },
        {
            id: 6,
            name: 'Sefa Zor',
            code: '#AA202423001',
            status: 'approved',
            phone: '+90 538 273 8631',
            created_at: '24.06.2024 - 14:50',
            address: 'Melikgazi mah. Kızılırmak cad. Kervansaray apt. No97',
            tc: '34939434'
        },
    ]);
    const matches = useMediaQuery('(max-width: 1200px)');
    const [findUser, setFindUser] = useState<any>({});
    const [documents, setDocuments] = useState<any>([]);
    const [loadingDocument, setLoadingDocument] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const rows = data.map((element, index) => {

        let icon = statusType(element.status);

        return (
            <Table.Tr key={`table-item-${index}`}>
                <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
                    <UnstyledButton w={40}>
                        <IoEyeOutline size={19} color={"#475467"}/>
                    </UnstyledButton>
                </Table.Td>
                <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>{element.name}</Table.Td>
                <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
                    <Badge tt={element.status === 'waiting' ? 'initial' : 'uppercase'} leftSection={icon.icon}
                           style={{border: '1px solid #ABEFC6'}} h={23} color={icon.color}>
                        {icon.text}
                    </Badge>
                </Table.Td>
                <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>{element.code}</Table.Td>
                <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.tc}</Table.Td>
                <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.phone}</Table.Td>
                <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.created_at}</Table.Td>
            </Table.Tr>
        )
    });
    const navigate = useNavigate();


    const form = useForm({
        initialValues: {
            cityId: '35',
            neighborhoodsId: '',
            email: '',
            fullName: '',
            phone: '',
            tc_no: '',
            streetId: '',
            no: '',
            dask_code: '',
            help_category: ''
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Geçersiz email adresi'),
            fullName: (value) => (value !== '' ? null : 'Ad soyad girmelisiniz'),
            phone: (value) => (value !== '' ? null : 'Telefon No girmelisiniz'),
            tc_no: (value) => (value.length === 11 ? null : 'Tc No doğru girmelisiniz'),
            cityId: (value) => (value !== '' ? null : 'Şehir seçmelisiniz'),
            neighborhoodsId: (value) => (value !== '' ? null : 'Mahalle seçmelisiniz'),
            streetId: (value) => (value !== '' ? null : 'Sokak seçmelisiniz'),
            dask_code: (value) => (value !== '' ? null : 'Adres Dask Kodu girmelisiniz'),
            no: (value) => (value !== '' ? null : 'Kapı no girmelisiniz'),
        },
    });

    const cities = useGetAddressCitiesQuery({});
    const neighbourhoods = useGetAddressCityNeighborhoodsQuery({cityId: form.values.cityId});
    const streets = useGetCityNeighborhoodIdQuery({
        neighborhoodsId: form.values.neighborhoodsId,
        cityId: form.values.cityId
    });

    useEffect(() => {

        if (helpIdsProps.isSuccess) {
            let _data = helpIdsProps.data;
            handlers.setItemProp(activeIndex, 'helpIds', _data)
        }
    }, [helpIdsProps.isSuccess]);


    useEffect(() => {
        if (helpIdsProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: helpIdsProps?.error?.data?.message ?? '',
                autoClose: 2000,
            })
        }
    }, [helpIdsProps.isError]);


    useEffect(() => {

        if (addCitizensProps.isSuccess) {
            notifications.show({
                color: 'teal',
                title: 'Başarılı',
                //@ts-ignore
                message: 'Vatandaş Kaydedildi',
                autoClose: 2000,
            });
            form.setValues({
                cityId: '35',
                neighborhoodsId: '',
                email: '',
                fullName: '',
                phone: '',
                tc_no: '',
                streetId: '',
                no: '',
                dask_code: '',
                help_category: ''
            });
        }

    }, [addCitizensProps.isSuccess]);


    useEffect(() => {
        if (addCitizensProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: addCitizensProps?.error?.data?.message ?? '',
                autoClose: 2000,
            })
        }
    }, [addCitizensProps.isError]);


    useEffect(() => {

        if (helpCategories.isSuccess) {
            let _data = [{label: 'Yardım Tipi Seç', value: ''}];
            helpCategories.data.map((item, index) => (
                _data.push({label: item.name, value: String(item.id)})
            ));
            setHelpCats(_data);
        }

    }, [helpCategories.isSuccess]);


    useEffect(() => {
        if (getCitizensProps.isError) {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: getCitizensProps?.error?.data?.message ?? '',
                autoClose: 2000,
            })
        }
    }, [getCitizensProps.isError]);

    useEffect(() => {
        if (getCitizensProps.isSuccess) {
            if (getCitizensProps.data.items.length > 0) {
                let _user = getCitizensProps.data.items[0];
                setDisabledForm(true);

                form.setValues({
                    email: _user.email,
                    fullName: _user.fullName,
                    phone: _user.phoneNumber,
                    tc_no: _user.nationalityNumber,
                    cityId: _user?.address?.city?.id || '',
                    neighborhoodsId: _user?.address?.neighborhood?.id || '',
                    streetId: _user?.address?.street?.id || '',
                    no: _user?.address?.doorNo || '',
                    dask_code: _user?.address?.daskCode || '',
                    help_category: ''
                });
                notifications.show({
                    color: 'teal',
                    title: 'Başarılı',
                    message: 'Vatandaş Bulundu',
                    autoClose: 2000,
                });
            } else {
                setDisabledForm(false);
                form.setValues({
                    cityId: '35',
                    neighborhoodsId: '',
                    email: '',
                    fullName: '',
                    phone: '',
                    tc_no: '',
                    streetId: '',
                    no: '',
                    dask_code: '',
                    help_category: ''
                })
                notifications.show({
                    color: 'red',
                    title: 'Hata',
                    //@ts-ignore
                    message: 'Vatandaş bulunamadı',
                    autoClose: 2000,
                })
            }

        }
    }, [getCitizensProps.isSuccess]);


    useEffect(() => {
        setLoadingDocument(true);
        if (values) {
            const documentsMap: any = {};

            // Her bir veri elemanı için döngü
            values.forEach(item => {
                // SubHelpIds'ler boşsa döngüyü atla
                if (item.subHelpIds.length === 0) return;

                // Her bir subHelpId için döngü
                item.subHelpIds.forEach((subHelpId: string) => {
                    // Bu subHelpId'ye sahip helpId'yi bul
                    const help = item.helpIds.find((help: any) => help.id === subHelpId);
                    if (help) {
                        // Belge ID'lerini toplama
                        help.documents.forEach((document: any) => {
                            if (!documentsMap[document.id]) {
                                documentsMap[document.id] = document;
                            } else {
                                // Belge ID'si zaten var, güncelleme gerektirmiyor
                                // Eğer güncelleme yapmak isterseniz, burada yapabilirsiniz
                            }
                        });
                    }
                });
            });


            setDocuments(Object.values(documentsMap));
            setLoadingDocument(false);
        }
    }, [values]);

    const addFileFieldById = (id:any,file:File|null) => {
        const updatedData = documents.map((item:any) =>
            item.id === id
                ? _.assign({}, item, { file: file })
                : item
        );
        setDocuments(updatedData);
    };

    useEffect(() => {
        console.log('documents',documents);
    }, [documents]);

    function mergeSubHelpIds(_data: any) {
        // `subHelpIds` dizilerini birleştir
        const allSubHelpIds = _.flatMap(_data, 'subHelpIds');

        // Aynı olan `subHelpIds`'leri birleştir
        const mergedSubHelpIds = _.uniq(allSubHelpIds);

        return mergedSubHelpIds;
    }


    const handleSubmit = async () => {
        setLoading(true);
        if (tc.toString().length === 11) {
            let helpIds = mergeSubHelpIds(values);

            if (helpIds.length > 0) {

                let status = true;

                documents.forEach((item:any) => {
                    if (item && item.file){
                    }  else{
                        status = false;
                    }
                })

                if (status){
                    const formData = new FormData();
                    formData.append('CitizenId', tc);
                    formData.append('YardimlarIds', JSON.stringify(helpIds));
                    formData.append('Note', note);

                    documents.forEach((file: any, index: number) => {
                        if (file) {
                            formData.append(`Files[${index}].File`, file.file);
                            formData.append(`Files[${index}].DocumentId`, file.id);
                        }
                    });

                    try {
                        const response = await axiosFetch({
                            url: '/applications',
                            method: 'POST',
                            data: formData,
                            includeToken: true, // Adjust based on whether you need the token
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });

                        console.log('response',response);

                        if (response.error?.status === 200){

                            notifications.show({
                                color: 'teal',
                                title: 'Başarılı',
                                //@ts-ignore
                                message: 'Başvurunuz alındı',
                                autoClose: 2000,
                            });
                            navigate('/applications');
                        } else{
                            notifications.show({
                                color: 'red',
                                title: 'Hata',
                                //@ts-ignore
                                message: response.error?.data?.message ?? '',
                                autoClose: 2000,
                            })
                            setLoading(false);
                        }

                    } catch (error) {
                        notifications.show({
                            color: 'red',
                            title: 'Hata',
                            //@ts-ignore
                            message: error?.data?.message ?? '',
                            autoClose: 2000,
                        })
                        setLoading(false);
                    }
                } else{
                    notifications.show({
                        color: 'red',
                        title: 'Hata',
                        //@ts-ignore
                        message: 'Evraklarınızı eksiksiz girmelisiniz',
                        autoClose: 2000,
                    });
                    setLoading(false);
                }

            } else {
                notifications.show({
                    color: 'red',
                    title: 'Hata',
                    //@ts-ignore
                    message: 'En az 1 tane yardım seçmelisiniz',
                    autoClose: 2000,
                });
                setLoading(false);
            }

        } else {
            notifications.show({
                color: 'red',
                title: 'Hata',
                //@ts-ignore
                message: 'Tc no eksik girilmiş',
                autoClose: 2000,
            });
            setLoading(false);
        }

    };

    return (<>
        <Group ml={matches ? 0 : -20} pr={matches ? 0 : 30}>
            <Text flex={1} mb={10} fz={36} fw={700} lts={'-1px'} c={'#000000'}>Yeni Başvuru Kaydı</Text>

            <Button component={Link} to={'/applications'} color={'#9F9F9F'} h={40} leftSection={<IoArrowBack/>} fz={14}
                    fw={600} radius={8}>Geri
                Git</Button>

        </Group>

        <LoadingOverlay visible={loading}/>

        <Divider mt={15} mb={25} color={'#ECECF0'} ml={-46} w={'calc(100% + 60px)'}/>

        <Group maw={'100%'} w={600}>
            <NumberInput
                onChange={(value) => setTc(Number(value))}
                hideControls flex={1} label={'TC KİMLİK Numarası'} radius={0} styles={{
                input: {
                    height: 42,
                },
                label: {
                    fontWeight: 600,
                    color: '#111111',
                    fontSize: 11,
                    marginBottom: 14,
                    textTransform: 'uppercase'
                }
            }}/>
            <Button loading={getCitizensProps.isLoading} mt={35} h={40} fz={14} fw={600} c={'#fff'} radius={8}
                    onClick={() => {
                        if (tc && tc.toString().length === 11) {
                            getCitizens({search: String(tc)});
                        } else {
                            notifications.show({
                                color: 'red',
                                title: 'Hata',
                                //@ts-ignore
                                message: 'Tc no eksik girilmiş',
                                autoClose: 2000,
                            })
                        }
                    }}>Vatandaş Kaydını Getir</Button>
        </Group>

        <form onSubmit={form.onSubmit((values) => {
            addCitizens({
                data: {
                    nationalityNumber: values.tc_no,
                    fullName: values.fullName,
                    phoneNumber: String(values.phone),
                    gender: 1,
                    address: {
                        neighborhoodsId: values.neighborhoodsId,
                        streetId: values.streetId,
                        daskCode: values.dask_code,
                        buildingNo: '',
                        doorNo: values.no

                    }
                }
            });
        })}>

            <Grid mt={27} gutter={26} pr={matches ? 0 : 60}>
                <Grid.Col span={{lg: 3}}>
                    <TextInput
                        disabled={disabledForm}
                        label={"ADI SOYADI"}
                        {...form.getInputProps('fullName')}
                        radius={0}
                        styles={{
                            input: {
                                height: 40,
                                paddingLeft: 13,
                                fontSize: 11,
                                fontWeight: 400,
                                color: '#A1A1A1'
                            },
                            label: {
                                fontWeight: 600,
                                color: '#111111',
                                fontSize: 11,
                                marginBottom: 14,
                                textTransform: 'uppercase'
                            }
                        }}
                    />
                </Grid.Col>

                <Grid.Col span={{lg: 3}}>
                    <TextInput
                        disabled={disabledForm}
                        label={"EMAİL ADRESİ"}
                        {...form.getInputProps('email')}
                        radius={0}
                        styles={{
                            input: {
                                height: 40,
                                paddingLeft: 13,
                                fontSize: 11,
                                fontWeight: 400,
                                color: '#A1A1A1'
                            },
                            label: {
                                fontWeight: 600,
                                color: '#111111',
                                fontSize: 11,
                                marginBottom: 14,
                                textTransform: 'uppercase'
                            }
                        }}
                    />
                </Grid.Col>

                <Grid.Col span={{lg: 3}}>
                    <NumberInput
                        disabled={disabledForm}
                        label={"Telefon Numarası"}
                        radius={0}
                        hideControls
                        {...form.getInputProps('phone')}
                        styles={{
                            input: {
                                height: 40,
                                paddingLeft: 13,
                                fontSize: 11,
                                fontWeight: 400,
                                color: '#A1A1A1'
                            },
                            label: {
                                fontWeight: 600,
                                color: '#111111',
                                fontSize: 11,
                                marginBottom: 14,
                                textTransform: 'uppercase'
                            }
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={{lg: 3}}>
                    <TextInput
                        disabled={disabledForm}
                        label={"TC KİMLİK Numarası"}
                        {...form.getInputProps('tc_no')}
                        radius={0}
                        styles={{
                            input: {
                                height: 40,
                                paddingLeft: 13,
                                fontSize: 11,
                                fontWeight: 400,
                                color: '#A1A1A1'
                            },
                            label: {
                                fontWeight: 600,
                                color: '#111111',
                                fontSize: 11,
                                marginBottom: 14,
                                textTransform: 'uppercase'
                            }
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={{lg: 3}}>
                    <Select
                        disabled={disabledForm}
                        data={cities.isSuccess ? neighbourhoodMap(neighbourhoods?.data || []) : []}
                        allowDeselect={false}
                        radius={0}
                        label={'MAHALLE'}
                        key={form.key('neighborhoodsId')}
                        {...form.getInputProps('neighborhoodsId')}
                        searchable
                        styles={{
                            input: {
                                height: 40,
                                paddingLeft: 13,
                                fontSize: 11,
                                fontWeight: 400,
                                color: '#A1A1A1'
                            },
                            label: {
                                fontWeight: 600,
                                color: '#111111',
                                fontSize: 11,
                                marginBottom: 14,
                                textTransform: 'uppercase'
                            }
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={{lg: 3}}>
                    <Select
                        disabled={disabledForm}
                        data={streets.isSuccess ? streetMap(streets?.data || []) : []}
                        radius={0}
                        label={"SOKAK"}
                        key={form.key('streetId')}
                        {...form.getInputProps('streetId')}
                        styles={{
                            input: {
                                height: 40,
                                paddingLeft: 13,
                                fontSize: 11,
                                fontWeight: 400,
                                color: '#A1A1A1'
                            },
                            label: {
                                fontWeight: 600,
                                color: '#111111',
                                fontSize: 11,
                                marginBottom: 14,
                                textTransform: 'uppercase'
                            }
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={{lg: 3}}>
                    <TextInput
                        {...form.getInputProps('no')}
                        disabled={disabledForm}
                        label={"KAPI NUMARASI"}
                        radius={0}
                        styles={{
                            input: {
                                height: 40,
                                paddingLeft: 13,
                                fontSize: 11,
                                fontWeight: 400,
                                color: '#A1A1A1'
                            },
                            label: {
                                fontWeight: 600,
                                color: '#111111',
                                fontSize: 11,
                                marginBottom: 14,
                                textTransform: 'uppercase'
                            }
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={{lg: 3}}>
                    <TextInput
                        label={"ADRES/DASK KODU"}
                        disabled={disabledForm}
                        {...form.getInputProps('dask_code')}
                        radius={0}
                        styles={{
                            input: {
                                height: 40,
                                paddingLeft: 13,
                                fontSize: 11,
                                fontWeight: 400,
                                color: '#A1A1A1'
                            },
                            label: {
                                fontWeight: 600,
                                color: '#111111',
                                fontSize: 11,
                                marginBottom: 14,
                                textTransform: 'uppercase'
                            }
                        }}
                    />
                </Grid.Col>
            </Grid>

            <Group justify={'flex-end'} mt={25} pr={60}>
                <Button
                    loading={addCitizensProps.isLoading}
                    type={'submit'}
                    disabled={disabledForm}
                    h={40} fz={14} fw={600} radius={8}>
                    Kaydet
                </Button>
            </Group>
        </form>

        <Group gap={24} px={72} mt={63} h={53} bg={'#50C878'} ml={-46} w={'calc(100%  + 62px)'}>
            <ThemeIcon radius={'xl'} size={29} color={'#fff'} c={'#000'} fz={16} fw={600}>2</ThemeIcon>
            <Text fz={20} fw={700} c={'#fff'}>YARDIM BİLGİLERİ</Text>
        </Group>

        <Box pl={30} pr={matches ? 0 : 54}>
            <Box mt={53} pos={'relative'}>
                <Text mb={20} fz={16} fw={600}>YARDIM TİPLERİ</Text>

                <Group pos={'relative'} pr={110}>
                    <Box flex={1}>
                        {values.map((item, index) => (
                            <Box mb={20}>
                                <Select
                                    mb={20}
                                    data={helpCats}
                                    styles={{
                                        input: {
                                            height: 54,
                                            borderColor: '#EAE9ED'
                                        }
                                    }}
                                    value={item.type}
                                    onChange={(value) => {
                                        setActiveIndex(index);
                                        setHelpCatId(value);
                                        handlers.setItemProp(index, 'type', String(value));
                                        handlers.setItemProp(index, 'subHelpIds', []);
                                        helpIds({id: String(value)});
                                    }}
                                />
                                {(helpIdsProps.isLoading && activeIndex === index) &&
                                  <Group>
                                    <Skeleton radius={0} width={150} height={40}/>
                                    <Skeleton radius={0} width={150} height={40}/>
                                    <Skeleton radius={0} width={150} height={40}/>
                                  </Group>
                                }

                                {(item.helpIds.length > 0) &&
                                  <>
                                    <Chip.Group multiple onChange={(values) => {
                                        handlers.setItemProp(index, 'subHelpIds', values)
                                    }}>
                                      <Group>
                                          {item.helpIds.map((helps: any, helpIndex: number) => (
                                              <Chip icon={<PiCheckCircleFill color={"#00629C"}/>} color={"#fff"}
                                                    radius={0}
                                                    fz={16} c={'#A1A1A1'} styles={{
                                                  input: {
                                                      color: '#A1A1A1',
                                                  },
                                                  label: {
                                                      height: 44,
                                                      backgroundColor: 'transparent',
                                                      border: '1px solid #EAE9ED'
                                                  }
                                              }} value={helps?.id || ''}>{helps?.name || ''}</Chip>
                                          ))}
                                      </Group>
                                    </Chip.Group>
                                  </>
                                }

                            </Box>)
                        )}

                    </Box>
                </Group>

                <Group right={-30} bottom={26} pos={'absolute'}>
                    <ThemeIcon style={{cursor: 'pointer'}} size={50} radius={"md"} color={'red'} variant={'light'}
                               onClick={() => {
                                   if (values.length > 1) {
                                       handlers.remove(values.length - 1)
                                   }
                               }}>
                        <IoRemove size={23}/>
                    </ThemeIcon>
                    <ThemeIcon style={{cursor: 'pointer'}} size={50} radius={"md"} color={'teal'} variant={'light'}
                               onClick={() => {
                                   handlers.append({type: '', helpIds: [], subHelpIds: []})
                               }}>
                        <IoAdd size={23}/>
                    </ThemeIcon>
                </Group>


            </Box>
        </Box>


        <Group gap={24} px={72} mt={63} h={53} bg={'#50C878'} ml={-46} w={'calc(100%  + 62px)'}>
            <ThemeIcon radius={'xl'} size={29} color={'#fff'} c={'#000'} fz={16} fw={600}>3</ThemeIcon>
            <Text fz={20} fw={700} c={'#fff'}>EVRAKLAR</Text>
        </Group>

        <Box pl={29} pr={65}>
            <Grid gutter={18} mt={50}>
                {documents.length === 0 &&
                  <Center>
                    <Text fz={14} fw={500}>Evrak bulunamadı</Text>
                  </Center>
                }

                {documents.map((item: any, index: number) => (
                    <Grid.Col key={`document-${index}`} span={{lg: 2}}>
                        <FileButton onChange={(file) => addFileFieldById(item.id,file)} accept="image/png,image/jpeg">
                            {(props) => <>
                                <Text fz={13} mb={10} c={file ? '#50C878' : '#111111'}
                                      fw={600}>{item?.name || ''}</Text>
                                <Box h={103} style={{
                                    border: `1px solid ${file ? '#50C878' : '#EAE9ED'}`,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer'
                                }} display={'flex'} {...props}>
                                    <Box ta={'center'} w={'100%'} display={'block'} style={{wordWrap: 'break-word'}}>
                                        <Image display={'inline-block'} w={file ? 30 : 27}
                                               src={`/images/${item.file ? 'file-check.svg' : 'file.svg'}`}/>
                                        <Box px={10}>
                                            <Text display={'block'}  fz={11.5}
                                                   c={'#000000'}>{item.file ? item?.file?.name || '' : 'Yüklemek için tıklayın'}</Text>
                                        </Box>
                                        {!item.file ? (
                                            <Text fz={10} c={'#A1A1A1'}>
                                                PDF, PNG ya da JPG
                                            </Text>
                                        ) : (
                                            <Text fz={10} c={'#A1A1A1'}>
                                                Evrak Yüklendi
                                            </Text>
                                        )}
                                    </Box>
                                </Box>
                            </>}
                        </FileButton>
                    </Grid.Col>
                ))}

            </Grid>
        </Box>


        <Box mb={30} pl={30} pr={65}>
            <TextInput
                mt={38}
                label={"Not"}
                placeholder={'NOT GİRİN'}
                value={note}
                onChange={(e) => setNote(e.currentTarget.value)}
                radius={0}
                styles={{
                    input: {
                        height: 40,
                        paddingLeft: 13,
                        fontSize: 12,
                        fontWeight: 400,
                        color: '#A1A1A1'
                    },
                    label: {
                        fontWeight: 600,
                        color: '#111111',
                        fontSize: 11,
                        marginBottom: 8,
                        textTransform: 'uppercase'
                    }
                }}
            />
        </Box>

        <Group justify={'flex-end'} pr={65} mt={10} mb={20}>
            <Button onClick={() => setNote('')}  variant={"outline"} style={{border: 'none', boxShadow: '2px 2px 2px 0px rgba(0,0,0,0.05)'}} h={40}
                    radius={8} fz={14} fw={600} c={'#000'}

            >Temizle</Button>
            <Button loading={loading} h={40} fz={14} fw={600} radius={8} onClick={handleSubmit}>
                Kaydet
            </Button>
        </Group>

    </>);
}

export default ApplicationsAdd;
