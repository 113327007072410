import {
  Badge,
  Box,
  Button,
  Card,
  Center,
  Divider,
  FileButton,
  Grid,
  Group, LoadingOverlay,
  Modal,
  Stack,
  Table,
  Text,
  Textarea,
  ThemeIcon,
  Tooltip
} from '@mantine/core';
import { useDisclosure, useDocumentTitle, useMediaQuery } from '@mantine/hooks';
import React, {useEffect, useState} from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { CiStickyNote } from 'react-icons/ci';
import { HiOutlineCheckCircle } from 'react-icons/hi';
import { IoArrowBack, IoArrowForward, IoClose } from 'react-icons/io5';
import { MdCheck } from 'react-icons/md';
import { TiCancel } from 'react-icons/ti';
import {Link, useParams} from 'react-router-dom';
import TableTop from '../../components/Table/top';
import {
  useGetApplicationDetailQuery,
  useUpdateApplicationMutation
} from "../../services/applications.service";
import {notifications} from "@mantine/notifications";
import moment from "moment";
import {set} from "lodash";


export const ApplicationDetail = () => {
  const { id } = useParams<{ id: string }>();

  useDocumentTitle('Balçova Belediyesi - Başvuru Detay');
  const [opened, { open, close }] = useDisclosure(false);
  const [reject, setReject] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [note,setNote] = useState('');
  const [rejectNote,setRejectNote] = useState('');
  const [approvedNote,setApprovedNote] = useState('');
  const {data,isSuccess,isFetching,isLoading} = useGetApplicationDetailQuery({id:String(id)});
  const [updateApplication,updateApplicationProps] = useUpdateApplicationMutation({});

  const statusType = (status: number) => {
    let _status = {color: '', text: '', icon: <Box/>}

    switch (status) {
      case 1:
        _status = {
          color: '#FFA500',
          text: 'Bekliyor',
          icon: <AiOutlineLoading3Quarters color={"#fff"}/>
        }
        break;
      case 5:
        _status = {
          color: '#DC3545',
          text: 'RED',
          icon: <IoClose size={15} color={"#fff"}/>
        }
        break;
      case 4:
        _status = {
          color: '#DC3545',
          text: 'ONAYLANMADI',
          icon: <IoClose size={15} color={"#fff"}/>
        }
        break;

      case 2:
        _status = {
          color: '#28A745',
          text: 'ONAYLANDI',
          icon: <MdCheck size={15} color={"#fff"}/>

        }
        break;

      case 3:
        _status = {
          color: '#28A745',
          text: 'TAMAMLANDI',
          icon: <MdCheck size={15} color={"#fff"}/>

        }
        break;
    }

    return _status;
  }

  const [value, setValue] = useState<Date | null>(null);

  const matches = useMediaQuery('(max-width: 1200px)');

  useEffect(() => {
    if (isSuccess){
      setNote(data?.note || '');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (updateApplicationProps.isError) {
      notifications.show({
        color: 'red',
        title: 'Hata',
        //@ts-ignore
        message: updateApplicationProps?.error?.data?.message ?? '',
        autoClose: 2000,
      })
    }
  }, [updateApplicationProps.isError]);

  useEffect(() => {
    if (updateApplicationProps.isSuccess) {
      notifications.show({
        color: 'teal',
        title: 'Başarılı',
        message: 'Güncelleme Tamamlandı',
        autoClose: 2000,
      });
    }
  }, [updateApplicationProps.isSuccess]);



  const [dataUser,setDataUser] = useState<any>([]);

  const rowsUser = dataUser.map((element:any,index:number) => {

    let icon = statusType(element.status);

    return(
      <Table.Tr  key={`table-item-${index}`}>

        <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>{element.name}</Table.Td>
        <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
          <Badge tt={element.status === 'waiting' ? 'initial' : 'uppercase'} leftSection={icon.icon} style={{border:'1px solid #ABEFC6'}} h={23} color={icon.color}>
            {icon.text}
          </Badge>
        </Table.Td>
        <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>{element.code}</Table.Td>
        <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.tc}</Table.Td>
        <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.phone}</Table.Td>
        <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{element.created_at}</Table.Td>
        <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
          <Button rightSection={ <IoArrowForward size={14} color={"#fff"}/>}  size={"xs"}  component={Link} to={`/application/detail/${element.id}`} >Detayı Gör</Button>
        </Table.Td>
      </Table.Tr>
    )
  });

  let status = statusType(data?.status);

  return (<>

    <Group pr={50} gap={12}>
      <Box flex={1}>
        <Group>
          <Text fz={36} fw={700} c={'#000'}>Başvuru Detay</Text>
          <Badge color={status.color} style={{ border: '1px solid #ABEFC6' }} leftSection={status.icon}>{status.text}</Badge>
        </Group>
      </Box>
      <Button component={Link} to={'/applications'} color={'#9F9F9F'} h={40} leftSection={<IoArrowBack/>} fz={14}
              fw={600} radius={8}>Geri Git</Button>
      <Button color={'orange'} h={40} fz={14} fw={600} radius={8}>Temsilci Raporu
        Ekle</Button>
      <Button onClick={() => setReject(true)} variant={"outline"} style={{ border: 'none', boxShadow: '2px 2px 2px 0px rgba(0,0,0,0.05)' }} h={40}
              radius={8} fz={14} fw={600} c={'#000'}
      >Reddet</Button>
      <Button onClick={open} h={40} fz={14} fw={600} radius={8}>Onayla</Button>

    </Group>


    <Modal size={360} centered radius={12} onClose={close} opened={opened} withCloseButton={false}>
      <Box>
        <Center>
          <ThemeIcon size={48} radius={'xl'} variant={'light'} color={'teal'}>
            <HiOutlineCheckCircle size={27}/>
          </ThemeIcon>
        </Center>
        <Text mt={20} c={'#101828'} ta={'center'} fw={600} fz={19}>Başvuruyu onaylamak istediğinize emin misiniz?</Text>
        <Text mt={8} fw={500} fz={14} c={'#344054'}>Onay açıklaması</Text>
        <Textarea placeholder={"Açıklama"} styles={{
          input: {
            height: 121,
            fontSize: 16.5,
            color: '#667085',
            padding: '10px 14px',

          }
        }}/>
        <Button onClick={close} mt={20} mb={12} c={'#fff'} fullWidth h={44} radius={8} color={'#7F56D9'}>Onayla</Button>
        <Button onClick={close} h={44} radius={8} fullWidth variant={"outline"} color={'#D0D5DD'} c={'#344054'}
                fw={600}>Vazgeç</Button>
      </Box>
    </Modal>


    <Modal size={360} centered radius={12} onClose={() => setReject(false)} opened={reject} withCloseButton={false}>
      <Box>
        <Center>
          <ThemeIcon size={48} radius={'xl'} variant={'light'} color={'red'}>
            <HiOutlineCheckCircle size={27}/>
          </ThemeIcon>
        </Center>
        <Text px={10} mt={20} c={'#101828'} ta={'center'} fw={600} fz={19}>Başvuruyu reddetmek istediğinize emin misiniz?</Text>
        <Text mt={8}  fw={500} fz={14} c={'#344054'}>Red sebebi</Text>
        <Textarea mt={5} placeholder={"Açıklama"} onChange={(e) => {
          setRejectNote(e.currentTarget.value);
        }} styles={{
          input: {
            height: 121,
            fontSize: 16.5,
            color: '#667085',
            padding:'10px 14px',
          }
        }}/>
        <Button onClick={() => {
          updateApplication({
            id:data?.id ||'',
            status:4,
            note:rejectNote
          })
        }} mt={20} mb={12} c={'#fff'} fullWidth h={44} radius={8} loading={updateApplicationProps.isLoading} color={'#7F56D9'}>Reddet</Button>
        <Button onClick={() => setReject(false)} h={44} radius={8} fullWidth variant={"outline"} color={'#D0D5DD'} c={'#344054'}
                fw={600}>Vazgeç</Button>
      </Box>
    </Modal>

   <Box pos={'relative'} mih={750}>
     <LoadingOverlay visible={isLoading}/>
     {isSuccess &&
         <Box mt={35} pr={matches ? 0 : 50} ml={matches ? 0 : -46}>
           <Grid>
             <Grid.Col p={0} span={{ lg: 4 }}>
               <Card className={"responsive-table"} radius={0} withBorder p={25} style={{ borderLeftWidth: 0 }}>
                 <Text mb={20} fw={700} fz={18}>Başvurucu Bilgileri:</Text>
                 <Stack>
                   <Group>
                     <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Ad Soyad:</Text>
                     <Text flex={1} c={'#000'} fw={500} fz={16} lh={'24px'}>{data?.citizen?.fullName || ''}</Text>
                   </Group>
                   <Group>
                     <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Telefon:</Text>
                     <Text flex={1} c={'#000'} fw={500} fz={16} lh={'24px'}>{data?.citizen?.phoneNumber || ''}</Text>
                   </Group>
                   <Group>
                     <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>TC Kimlik Numarası:</Text>
                     <Text flex={1} c={'#000'} fw={500} fz={16} lh={'24px'}>{data?.citizen?.nationalityNumber || ''}</Text>
                   </Group>
                   <Group>
                     <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Mahalle:</Text>
                     <Text flex={1} c={'#000'} fw={500} fz={16} lh={'24px'}>{data?.citizen?.address?.neighborhood?.name || ''}</Text>
                   </Group>
                   <Group>
                     <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Sokak:</Text>
                     <Text flex={1} c={'#000'} fw={500} fz={16} lh={'24px'}>{data?.citizen?.address?.street?.name || ''}</Text>
                   </Group>
                   <Group>
                     <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Adres:</Text>
                     <Text flex={1} c={'#000'} fw={500} fz={16} lh={'24px'}>
                       {data?.citizen?.address?.city?.name || ''}{' '}
                       {data?.citizen?.address?.neighborhood?.name || ''}{' '}
                       {data?.citizen?.address?.street?.name || ''}
                     </Text>
                   </Group>
                 </Stack>
                 <Divider mx={-25} mt={20} mb={20} color={'#ECECF0'}/>
                 <Text mb={20} fw={700} fz={18}>Yardım Bilgileri:</Text>
                 <Stack>
                   <Group>
                     <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Yardım Tipi:</Text>
                     <Text flex={1} c={'#000'} fw={500} fz={16} lh={'24px'}>TAŞIMA</Text>
                   </Group>
                   <Group>
                     <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Yardım Konusu:</Text>
                     <Text flex={1} c={'#000'} fw={500} fz={16} lh={'24px'}>CENAZE TAŞIMA</Text>
                   </Group>

                 </Stack>
                 <Divider mx={-25} mt={20} color={'#ECECF0'}/>

                 <Text mt={28} mb={20} fz={14} fw={500} c={'#344054'} lh={'20px'}>Not</Text>
                 <Textarea defaultValue={note} onChange={(e) => setNote(e.currentTarget.value)} styles={{
                   input: {
                     height: 92,
                     borderRadius: 0,
                     fontSize: 16,
                     fontWeight: 400,
                     color: '#101828',
                     padding: "13px 30px 13px 13px"
                   }
                 }}/>

                 <Group justify={'end'}>
                   <Button
                       onClick={() => updateApplication({id:data?.id || '',note,status:data?.status || 1})}
                       loading={updateApplicationProps.isLoading}
                       fz={14} fw={600} radius={8} mt={13}>Notu Kaydet</Button>
                 </Group>

               </Card>

               {/*   <Box pl={25} mt={20}  mb={70}>
                 <Text mb={20} fw={700} fz={18}>İşlem Bilgileri:</Text>
                 <Stack>
                   <Group>
                     <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>İşlem Tarihi:</Text>
                     <Text flex={1} c={'#000'} fw={500} fz={16} lh={'24px'}>03.06.2024 - 14:53</Text>
                   </Group>
                   <Group>
                     <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>İşlem yapan kullanıcı:</Text>
                     <Text flex={1} c={'#000'} fw={500} fz={16} lh={'24px'}>Sefa Z.</Text>
                   </Group>
                   <Group>
                     <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>Tarıyıcı:</Text>
                     <Text flex={1} c={'#000'} fw={500} fz={16} lh={'24px'}>Web/Chrome</Text>
                   </Group>
                   <Group>
                     <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>IP Adresi:</Text>
                     <Text flex={1} c={'#000'} fw={500} fz={16} lh={'24px'}>84.302.30.12</Text>
                   </Group>
                   <Group>
                     <Text c={'#5A5C65'} fw={500} fz={16} lh={'24px'} w={180}>İşlem ID:</Text>
                     <Text flex={1} c={'#000'} fw={500} fz={16} lh={'24px'}>234234234234</Text>
                   </Group>
                 </Stack>
               </Box>
*/}
             </Grid.Col>
             <Grid.Col p={0} span={{ lg: 8 }}>
               <Card className={"responsive-table"} radius={0} withBorder py={25} px={0} style={{ borderLeftWidth: 0 }}>
                 {/*
             <Text mb={20} fw={700} fz={18}>Evraklar</Text>
            <FileButton onChange={setFile} accept="image/png,image/jpeg">
              {(props) => <>
                <Text fw={600} fz={16} c={'#50C878'} mb={12}>SAĞLIK RAPORU</Text>
                <Group px={20} gap={13} h={52} style={{ border: '1.3px solid #50C878', cursor: 'pointer' }} {...props}>
                  <ThemeIcon radius={"xl"} size={24} color={'#28A745'}>
                    <PiCheckCircleFill color={'#fff'} size={17}/>
                  </ThemeIcon>
                  <Text fz={14} c={'#28A745'} flex={1}>saglik-raporu.pdf</Text>
                  <Button mr={20} h={30} px={10} color={'#28A745'} c={'#fff'} fz={10} radius={5.2}>Görüntüle</Button>
                </Group>
              </>}
            </FileButton>
            <Divider color={'#ECECF0'} mt={24} mb={24}/>
            <FileButton onChange={setFile} accept="image/png,image/jpeg">
              {(props) => <>
                <Text fw={600} fz={16} c={'#111111'} mb={12}>DİLEKÇE</Text>
                <Group px={20} gap={13} h={52} style={{ border: '1.3px solid #DC3545', cursor: 'pointer' }} {...props}>
                  <ThemeIcon radius={"xl"} size={24} color={'#DC3545'}>
                    <TiCancel color={'#fff'} size={17}/>
                  </ThemeIcon>
                  <Text fz={14} c={'#000000'} flex={1}>Evrak eksik - DİLEKÇE</Text>
                  <Button leftSection={<IoCloudUploadOutline size={16}/>} mr={40} h={37} fw={600} px={10}
                          color={'#CDCDCD'} c={'#fff'} fz={11.3} radius={5.2}>Evrak yüklemek için tıklayın</Button>
                </Group>
              </>}
            </FileButton>
            <Divider color={'#ECECF0'} mt={24} mb={24}/>
            <FileButton onChange={setFile} accept="image/png,image/jpeg">
              {(props) => <>
                <Text fw={600} fz={16} c={'#111111'} mb={12}>MAAŞ BORDROSU</Text>
                <Group px={20} gap={13} h={52} style={{ border: '1.3px solid #EAE9ED', cursor: 'pointer' }} {...props}>
                  <ThemeIcon radius={"xl"} size={24} color={'#DC3545'}>
                    <TiCancel color={'#fff'} size={17}/>
                  </ThemeIcon>
                  <Text fz={14} c={'#000000'} flex={1}>Evrak eksik - MAAŞ BORDOSU</Text>
                </Group>
              </>}
            </FileButton>
            <Divider color={'#ECECF0'} mt={24} mb={24}/>
            <FileButton onChange={setFile} accept="image/png,image/jpeg">
              {(props) => <>
                <Text fw={600} fz={16} c={'#111111'} mb={12}>MAAŞ BORDROSU</Text>
                <Group px={20} gap={13} h={52} style={{ border: '1.3px solid #EAE9ED', cursor: 'pointer' }} {...props}>
                  <ThemeIcon radius={"xl"} size={24} color={'#DC3545'}>
                    <TiCancel color={'#fff'} size={17}/>
                  </ThemeIcon>
                  <Text fz={14} c={'#000000'} flex={1}>Evrak eksik - MAAŞ BORDOSU</Text>
                </Group>
              </>}
            </FileButton>
            <Divider color={'#ECECF0'} mt={24} mb={24}/>

            */}

                 <Text pl={22} fw={700} fz={18}>Başvuru Güncellemeleri</Text>

                 <Table className={"responsive-table"} mt={20} style={{}}>
                   <TableTop
                       styles={{
                         thead: {
                           backgroundColor: '#F9FAFB',
                         }
                       }}
                       fz={14}
                       titles={["Personel", "Durum", "Başvuru Tarihi", "Teslimat Tarihi", "Temsilci Notu"]}
                   />
                   <Table.Tbody>
                     <Table.Tr>

                       <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>{data?.citizen?.fullName || ''}</Table.Td>
                       <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
                         <Box mb={5}> Onaylayan Personel: <b>{data?.citizen?.fullName || ''}</b> <br/></Box>
                         Onay Tarihi: {moment(data?.createdOn || '').format('DD.MM.YYYY HH:mm')} <br/>
                         Onay Notu: <Tooltip label={data?.temsilciNote || ''} withArrow color={'orange'} variant={'light'}>
                         <ThemeIcon variant={'light'} color={'orange'}>
                           <CiStickyNote/>
                         </ThemeIcon>
                       </Tooltip> <br/>


                       </Table.Td>
                       <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>{moment(data?.createdOn || '').format('DD.MM.YYYY HH:mm')}</Table.Td>
                       <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>{moment(data?.createdOn || '').format('DD.MM.YYYY HH:mm')}</Table.Td>
                       <Table.Td py={24} fz={14} fw={400} c={'#475467'} px={24}>
                         <Tooltip label={data?.temsilciNote || ''} withArrow color={'orange'} variant={'light'}>
                           <ThemeIcon variant={'light'} color={'orange'}>
                             <CiStickyNote/>
                           </ThemeIcon>
                         </Tooltip>
                       </Table.Td>
                     </Table.Tr></Table.Tbody>

                 </Table>

                 <Text pl={22} mt={20} fw={700} fz={18}>Evraklar</Text>

                 <Table className={"responsive-table"} mt={20} style={{}}>
                   <TableTop
                       styles={{
                         thead: {
                           backgroundColor: '#F9FAFB',
                         }
                       }}
                       fz={14}
                       titles={["Adı", "Durum", "Yüklenme Tarihi"]}
                   />
                   <Table.Tbody>
                     {/*  <Table.Tr>

                       <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>MAAŞ BORDROSU</Table.Td>
                       <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
                         <Group gap={10}>
                           <ThemeIcon radius={"xl"} size={24} color={'#DC3545'}>
                             <TiCancel color={'#fff'} size={17}/>
                           </ThemeIcon>
                           <Text fz={14} c={'#000000'} flex={1}>Evrak eksik - MAAŞ BORDOSU</Text>
                         </Group>
                       </Table.Td>
                       <Table.Td py={24} fz={14} fw={500} c={'#101828'} px={24}>
                         24.06.2024 - 14:50
                       </Table.Td>
                     </Table.Tr>*/}
                   </Table.Tbody>

                 </Table>

                 <Text mt={20} mb={20} pl={22} fw={700} fz={18}>Önceki Başvuruları</Text>

                 <Table  striped style={{
                   borderTop:'12px solid #F7F7F7'
                 }}>
                   <TableTop
                       styles={{
                         thead:{
                           backgroundColor:'#F9FAFB',


                         }
                       }}
                       titles={["Başvuran", "Durum", "Başvuru Numarası", "TC Kimlik No","Telefon", "Kayıt Tarihi",""]}
                   />
                   <Table.Tbody>{rowsUser}</Table.Tbody>

                 </Table>


               </Card>
             </Grid.Col>
           </Grid>
         </Box>
     }
   </Box>


  </>)
}

export default ApplicationDetail;
