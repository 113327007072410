import { TableProps, Table, TableTheadProps } from '@mantine/core';

interface Props extends TableTheadProps {
  titles:Array<string>;
  fz?:number
}

export const TableTop = ({titles,fz = 12,...props}:Props) => {
  return (
    <Table.Thead {...props}>
      <Table.Tr style={{border:'1px solid #EAECF0',borderLeftWidth:0}} h={44}>
        {titles.map((item,index) => (
          <Table.Th px={24}   fz={fz} fw={500} c={'#475467'} key={`table-top-${index}`}>{item}</Table.Th>
        ))}
      </Table.Tr>
    </Table.Thead>
  )
}
export default TableTop;
